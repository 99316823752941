import { z } from "zod";
import { ProductType } from "../settings/products/types";
import { coerceNumber } from "../process/types";

const optionalCoerceNumber = z.union([z.null(), z.undefined(), coerceNumber]);

export const newMaterialSchema = z.object({
  brand: z.string().optional(),
  productId: optionalCoerceNumber,
  name: z.string(),
  optionCode: z.string().optional(),
  code: z.string().optional().nullable(),
  color: z.string().optional(),
  size: optionalCoerceNumber,
  widthDeduction: optionalCoerceNumber,
  heightDeduction: optionalCoerceNumber,
  params: z.array(z.any()).nullable().optional(),
  optionCondition: z.array(z.any()).nullable().optional(),
  pending: z.boolean().optional().nullable(),
  selectable: z.boolean().optional().nullable(),
  prices: z.array(z.any()).nullable().optional(),
});

export const materialSchema = z.object({
  id: z.number(),
  brand: z.string().optional().nullable(),
  productId: optionalCoerceNumber,
  name: z.string(),
  code: z.string().optional().nullable(),
  optionCode: z.string().nullable(),
  color: z.string().optional().nullable(),
  size: optionalCoerceNumber,
  widthDeduction: optionalCoerceNumber,
  heightDeduction: optionalCoerceNumber,
  optionCondition: z.array(z.any()).nullable().optional(),
  params: z.array(z.any()).nullable().optional(),
  pending: z.boolean().optional().nullable(),
  selectable: z.boolean().optional().nullable(),
  prices: z.array(z.any()).nullable().optional(),
});

export interface MaterialsType {
  id: number;
  brand: string;
  name: string;
  code?: string;
  image?: string;
  widthDeduction?: number;
  heightDeduction?: number;
  product: ProductType;
  optionCode?: string;
  color?: string;
  optionCondition?: {
    option: number;
    value: number | string;
    displayValue?: number | string;
    group?: string;
    reverse?: boolean;
  }[];
  size?: number;
  params?: {
    name: string;
    value: string;
  }[];
  pending: boolean;
  selectable: boolean;
  // price?: number;
  prices: {
    unit: number;
    price: number;
  }[];
}

export interface InventoryArrayType {
  materials: MaterialsType[];
}
