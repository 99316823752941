import { useQuery } from "@apollo/client";
import isNullish from "../../../../../../../utils/isNullish";
import { DeductionType } from "../../../../types";
import LayoutImage from "./layoutImage";
import { GET_DEDUCTIONS_CONDITIONAL } from "../../../../gqls";
import { useState } from "react";
import { option } from "../../../types";

interface multiValue {
  name: string;
  checked: boolean;
  value: number;
}

interface props {
  Frames: any;
  FrameType: any;
  Uchannel?: option;
  layoutDeduction: DeductionType | undefined;
  TPosts: number[] | undefined;
  tiers: number[] | undefined;
  openingScopes: any;
  Sills?: multiValue[];
  textBG?: string;
  OW: any;
  noDark?: boolean;
}

export default function LayoutAndFrame({
  Frames,
  FrameType,
  layoutDeduction,
  TPosts,
  tiers,
  openingScopes,
  Sills,
  textBG,
  OW,
  Uchannel,
  noDark,
}: props) {
  const bg = textBG || "bg-gray-100";

  const { data } = useQuery(GET_DEDUCTIONS_CONDITIONAL, {
    variables: {
      where: {
        name: { _eq: FrameType },
      },
    },
  });

  const { data: uChannelData } = useQuery(GET_DEDUCTIONS_CONDITIONAL, {
    variables: {
      where: {
        name: { _eq: "U" },
      },
    },
  });

  const FrameImage =
    data?.deductions && data?.deductions[0] && data?.deductions[0].image;

  const UChannelImage =
    uChannelData?.deductions &&
    uChannelData?.deductions[0] &&
    uChannelData?.deductions[0].image;

  const showUChannelImage = Uchannel?.scope && Frames.length == 0;

  return (
    <div className="flex mx-auto flex-col items-center gap-10">
      {/* Frame */}
      {Frames && FrameType !== "None" && (
        <div className="relative self-stretch flex-1 flex flex-col">
          {/* top */}
          <div
            className={`h-5 flex flex-row px-10 relative ${
              !Frames.T && "hidden"
            }`}
          >
            {/* OutSize */}
            <div className="absolute w-full px-10 left-0 -top-4">
              <div
                className={`relative h-2 border-t-[1px] border-l-[1px] border-r-[1px] border-black ${
                  !noDark && "dark:border-white"
                }`}
              >
                <div
                  className={`-mt-3 ${bg} ${
                    !noDark && "dark:bg-dark"
                  } z-10 mx-auto w-fit px-1`}
                >
                  {Frames.T?.outSize}{" "}
                  {FrameType == "Z" &&
                    `(${
                      Frames.T?.outSize +
                      (Frames.T?.cutAngles?.left == 45 ? 19 : 0) +
                      (Frames.T?.cutAngles?.right == 45 ? 19 : 0)
                    })`}
                  {FrameType == "Z50" &&
                    `(${
                      Frames.T?.outSize +
                      (Frames.T?.cutAngles?.left == 45 ? 29 : 0) +
                      (Frames.T?.cutAngles?.right == 45 ? 29 : 0)
                    })`}
                </div>
              </div>
            </div>
            {/* left border */}
            {Frames.T?.cutAngles?.left == 45 ? (
              <div
                className={`border-l-2 w-5 ${
                  Sills?.find(sill => sill.name == "T" && sill.checked)
                    ? "bg-gray-300"
                    : "bg-white"
                } border-t-2 h-full border-black ${
                  !noDark && "dark:border-gray-500"
                } skew-x-[45deg] ml-2 -mr-[10px]`}
              />
            ) : (
              <div
                className={`border-l-2 h-full ${
                  Sills?.find(sill => sill.name == "T" && sill.checked)
                    ? "bg-gray-300"
                    : "bg-white"
                } border-black ${!noDark && "dark:border-gray-500"}`}
              />
            )}
            {/* middle */}
            <div
              className={`relative border-t-2 flex-1 ${
                Sills?.find(sill => sill.name == "T" && sill.checked)
                  ? "bg-gray-300"
                  : "bg-white"
              } h-full border-black ${
                !noDark && "dark:border-gray-500"
              } border-b-2 flex flex-row`}
            >
              {/* InSize */}
              <div className="absolute w-full left-0 -bottom-4">
                <div
                  className={`relative h-2 border-b-[1px] border-l-[1px] border-r-[1px] border-black ${
                    !noDark && "dark:border-white"
                  } `}
                >
                  <div
                    className={`-mb-3 ${bg} ${
                      !noDark && "dark:bg-dark"
                    } z-10 mx-auto w-fit px-1`}
                  >
                    {Frames.T?.inSize}
                  </div>
                  {!isNullish(
                    Sills?.find(s => s.checked && s.name == "T")?.value,
                    true
                  ) && (
                    <div className="absolute top-full italic text-xs pt-1 left-1/2 translate-x-full">
                      Sill:
                      {Sills?.find(s => s.checked && s.name == "T")?.value}
                    </div>
                  )}
                </div>
              </div>
              {/* tPostLocs */}
              {TPosts?.map(t => (
                <div
                  key={t}
                  className={`absolute h-full border-l-2 border-black ${
                    !noDark && "dark:border-gray-500"
                  } pl-2`}
                  style={{
                    left: `${((t / Frames.T?.inSize) * 100).toFixed(1)}%`,
                  }}
                >
                  <div className="relative">
                    <div
                      className={`absolute -left-4 -translate-x-[100%] ${
                        !noDark && "dark:text-black"
                      }  text-xs`}
                    >
                      {t.toFixed()}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* right border */}
            {Frames.T?.cutAngles?.right == 45 ? (
              <div
                className={`border-r-2 ${
                  Sills?.find(sill => sill.name == "T" && sill.checked)
                    ? "bg-gray-300"
                    : "bg-white"
                } w-5 border-t-2 border-b-2 h-full border-black ${
                  !noDark && "dark:border-gray-500"
                } skew-x-[-45deg] mr-2 -ml-[10px]`}
              />
            ) : (
              <div
                className={`border-r-2 ${
                  Sills?.find(sill => sill.name == "T" && sill.checked)
                    ? "bg-gray-300"
                    : "bg-white"
                } h-full border-black ${!noDark && "dark:border-gray-500"}`}
              />
            )}
          </div>
          {/* middle */}
          <div className="flex-1 flex flex-row items-center">
            {/* left */}
            <div
              className={`relative flex flex-col h-full w-5 mr-4 ${
                !Frames.L && "hidden"
              }`}
            >
              <div
                className={`absolute h-full border-l-[1px] border-t-[1px] w-2 border-b-[1px] border-black ${
                  !noDark && "dark:border-white"
                } -left-4`}
              >
                <div
                  className={`absolute top-[50%] -translate-y-1/2 ${bg} ${
                    !noDark && "dark:bg-dark"
                  } z-10 -left-8 text-center`}
                >
                  {Frames.L?.outSize}
                  {"\n"}
                  {FrameType == "Z" &&
                    `(${
                      Frames.L?.outSize +
                      (Frames.L?.cutAngles?.top == 45 ? 19 : 0) +
                      (Frames.L?.cutAngles?.bottom == 45 ? 19 : 0)
                    })`}
                  {FrameType == "Z50" &&
                    `(${
                      Frames.L?.outSize +
                      (Frames.L?.cutAngles?.top == 45 ? 29 : 0) +
                      (Frames.L?.cutAngles?.bottom == 45 ? 29 : 0)
                    })`}
                </div>
              </div>
              {/* top border*/}
              {Frames.L?.cutAngles?.top == 45 ? (
                <div
                  className={`border-l-2 h-[22px] border-t-2 w-full border-black ${
                    !noDark && "dark:border-gray-500"
                  } skew-y-[45deg] mt-2 -mb-[10px] ${
                    Sills?.find(sill => sill.name == "L" && sill.checked)
                      ? "bg-gray-300"
                      : "bg-white"
                  }`}
                />
              ) : (
                <div
                  className={`border-t-2 w-full border-black ${
                    !noDark && "dark:border-gray-500"
                  } ${
                    Sills?.find(sill => sill.name == "L" && sill.checked)
                      ? "bg-gray-300"
                      : "bg-white"
                  }`}
                />
              )}
              {/* center border*/}
              <div
                className={`relative border-l-2 border-r-2 border-black ${
                  !noDark && "dark:border-gray-500"
                } h-full ${
                  Sills?.find(sill => sill.name == "L" && sill.checked)
                    ? "bg-gray-300"
                    : "bg-white"
                }`}
              >
                {tiers?.map(t => {
                  if (isNullish(t, true)) {
                    return null;
                  }
                  return (
                    <div
                      key={t}
                      className={`absolute w-full border-t-2 border-black ${
                        !noDark && "dark:border-gray-500"
                      }`}
                      style={{
                        bottom: `${((t / Frames.L?.inSize) * 100).toFixed(1)}%`,
                      }}
                    >
                      <div className="relative">
                        <div
                          className={`absolute ${
                            !noDark && "dark:text-black"
                          } text-xs`}
                          style={{
                            textOrientation: "upright",
                            writingMode: "vertical-lr",
                          }}
                        >
                          {Number(t).toFixed()}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div
                  className={`h-full absolute w-2 -right-4 border-t-[1px] border-b-[1px] border-r-[1px] border-black ${
                    !noDark && "dark:border-white"
                  }`}
                >
                  <div
                    className={`absolute top-[50%] -translate-y-1/2 ${bg} ${
                      !noDark && "dark:bg-dark"
                    } z-10`}
                  >
                    {Frames.L?.inSize}
                  </div>

                  {!isNullish(
                    Sills?.find(s => s.checked && s.name == "L")?.value,
                    true
                  ) && (
                    <div className="absolute top-[50%] translate-y-1/2 italic text-xs mx-auto left-5">
                      Sill:
                      {Sills?.find(s => s.checked && s.name == "L")?.value}
                    </div>
                  )}
                </div>
              </div>
              {/* bottom border */}
              {Frames.L?.cutAngles?.bottom == 45 ? (
                <div
                  className={`border-l-2 border-r-2 h-[22px] border-b-2 w-full border-black ${
                    !noDark && "dark:border-gray-500"
                  } skew-y-[-45deg] mb-2 -mt-[10px] ${
                    Sills?.find(sill => sill.name == "L" && sill.checked)
                      ? "bg-gray-300"
                      : "bg-white"
                  }`}
                />
              ) : (
                <div
                  className={`border-b-2 w-full border-black ${
                    !noDark && "dark:border-gray-500"
                  } ${
                    Sills?.find(sill => sill.name == "L" && sill.checked)
                      ? "bg-gray-300"
                      : "bg-white"
                  }`}
                />
              )}
            </div>
            {/* Layout Container */}
            <div className="flex flex-col gap-10 h-full justify-center flex-1">
              {/* Layout */}
              {layoutDeduction?.image && (
                <LayoutImage
                  layoutDeduction={layoutDeduction}
                  openingScopes={openingScopes}
                />
              )}
              {!layoutDeduction?.image && (
                <div className="min-w-[300px] px-10 flex flex-col items-center gap-2">
                  <h3>no image</h3>
                  <h3>Panels size</h3>
                  <div>
                    {
                      // @ts-expect-error
                      OW?.PW?.map(p =>
                        Number(p).toFixed(1).replace(".0", "")
                      ).join(" / ")
                    }
                  </div>
                </div>
              )}
            </div>
            {/* Frame Image */}
            {Frames.length !== 0 && FrameImage && (
              <FrameImg image={FrameImage} />
            )}
            {showUChannelImage && <FrameImg image={UChannelImage as string} />}
            {/* right */}
            <div
              className={`relative flex flex-col h-full w-5 ml-4 ${
                !Frames.R && "hidden"
              }`}
            >
              <div
                className={`absolute h-full border-r-[1px] border-t-[1px] w-2 border-b-[1px] border-black ${
                  !noDark && "dark:border-white"
                } -right-4`}
              >
                <div
                  className={`absolute top-[50%] -translate-y-1/2 ${bg} ${
                    !noDark && "dark:bg-dark"
                  } z-10 -right-8 text-center`}
                >
                  {Frames.R?.outSize}
                  {"\n"}
                  {FrameType == "Z" &&
                    `(${
                      Frames.R?.outSize +
                      (Frames.R?.cutAngles?.top == 45 ? 19 : 0) +
                      (Frames.R?.cutAngles?.bottom == 45 ? 19 : 0)
                    })`}
                  {FrameType == "Z50" &&
                    `(${
                      Frames.R?.outSize +
                      (Frames.R?.cutAngles?.top == 45 ? 29 : 0) +
                      (Frames.R?.cutAngles?.bottom == 45 ? 29 : 0)
                    })`}
                </div>
              </div>
              {/* top border*/}
              {Frames.R?.cutAngles?.top == 45 ? (
                <div
                  className={`border-r-2 h-[22px] border-t-2 w-full border-black ${
                    !noDark && "dark:border-gray-500"
                  } skew-y-[-45deg] mt-2 -mb-[10px] ${
                    Sills?.find(sill => sill.name == "R" && sill.checked)
                      ? "bg-gray-300"
                      : "bg-white"
                  }`}
                />
              ) : (
                <div
                  className={`border-t-2 w-full border-black ${
                    !noDark && "dark:border-gray-500"
                  } ${
                    Sills?.find(sill => sill.name == "R" && sill.checked)
                      ? "bg-gray-300"
                      : "bg-white"
                  }`}
                />
              )}
              {/* center border*/}
              <div
                className={`relative border-l-2 border-r-2 border-black ${
                  !noDark && "dark:border-gray-500"
                } h-full ${
                  Sills?.find(sill => sill.name == "R" && sill.checked)
                    ? "bg-gray-300"
                    : "bg-white"
                }`}
              >
                {tiers?.map(t => {
                  if (isNullish(t, true)) {
                    return null;
                  }
                  return (
                    <div
                      key={t}
                      className={`absolute w-full border-t-2 border-black ${
                        !noDark && "dark:border-gray-500"
                      }`}
                      style={{
                        bottom: `${((t / Frames.L?.inSize) * 100).toFixed(1)}%`,
                      }}
                    >
                      <div className="relative">
                        <div
                          className={`absolute ${
                            !noDark && "dark:text-black"
                          } text-xs`}
                          style={{
                            textOrientation: "upright",
                            writingMode: "vertical-lr",
                          }}
                        >
                          {Number(t).toFixed()}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div
                  className={`h-full absolute w-2 -left-4 border-t-[1px] border-b-[1px] border-l-[1px] border-black ${
                    !noDark && "dark:border-white"
                  }`}
                >
                  <div
                    className={`absolute top-[50%] -translate-y-1/2 ${bg} ${
                      !noDark && "dark:bg-dark"
                    } z-10 -left-6`}
                  >
                    {Frames.R?.inSize}
                  </div>

                  {!isNullish(
                    Sills?.find(s => s.checked && s.name == "R")?.value,
                    true
                  ) && (
                    <div className="absolute top-[50%] translate-y-1/2 italic text-xs mx-auto right-5">
                      Sill:
                      {Sills?.find(s => s.checked && s.name == "R")?.value}
                    </div>
                  )}
                </div>
              </div>
              {/* bottom border */}
              {Frames.R?.cutAngles?.bottom == 45 ? (
                <div
                  className={`border-r-2 border-l-2 h-[22px] border-b-2 w-full border-black ${
                    !noDark && "dark:border-gray-500"
                  } skew-y-[45deg] mb-2 -mt-[10px] ${
                    Sills?.find(sill => sill.name == "R" && sill.checked)
                      ? "bg-gray-300"
                      : "bg-white"
                  }`}
                />
              ) : (
                <div
                  className={`border-b-2 w-full border-black ${
                    !noDark && "dark:border-gray-500"
                  } ${
                    Sills?.find(sill => sill.name == "R" && sill.checked)
                      ? "bg-gray-300"
                      : "bg-white"
                  }`}
                />
              )}
            </div>
          </div>
          {/* bottom */}
          <div
            className={`h-5 flex flex-row px-10 relative ${
              !Frames.B && "hidden"
            }`}
          >
            {/* left border */}
            {Frames.B?.cutAngles?.left == 45 ? (
              <div
                className={`border-l-2 w-5 border-b-2 h-full border-black ${
                  !noDark && "dark:border-gray-500"
                } skew-x-[-45deg] ml-2 -mr-[10px] ${
                  Sills?.find(sill => sill.name == "B" && sill.checked)
                    ? "bg-gray-300"
                    : "bg-white"
                }`}
              />
            ) : (
              <div
                className={`border-l-2 h-full border-black ${
                  !noDark && "dark:border-gray-500"
                } ${
                  Sills?.find(sill => sill.name == "B" && sill.checked)
                    ? "bg-gray-300"
                    : "bg-white"
                }`}
              />
            )}
            {/* middle */}
            <div
              className={`relative border-t-2 flex-1 h-full border-black ${
                !noDark && "dark:border-gray-500"
              } border-b-2 flex flex-row ${
                Sills?.find(sill => sill.name == "B" && sill.checked)
                  ? "bg-gray-300"
                  : "bg-white"
              }`}
            >
              {/* InSize */}
              <div className="absolute w-full left-0 -top-4">
                <div
                  className={`relative h-2 border-t-[1px] border-l-[1px] border-r-[1px] border-black ${
                    !noDark && "dark:border-white"
                  }`}
                >
                  <div
                    className={`-mt-3 ${bg} ${
                      !noDark && "dark:bg-dark"
                    } z-10 mx-auto w-fit px-1`}
                  >
                    {Frames.B?.inSize}
                  </div>
                  {!isNullish(
                    Sills?.find(s => s.checked && s.name == "B")?.value,
                    true
                  ) && (
                    <div className="absolute bottom-full italic text-xs pb-1 left-1/2 translate-x-full">
                      Sill:
                      {Sills?.find(s => s.checked && s.name == "B")?.value}
                    </div>
                  )}
                </div>
              </div>
              {/* tPostLocs */}
              {TPosts?.map(t => (
                <div
                  key={t}
                  className={`absolute h-full border-l-2 border-black ${
                    !noDark && "dark:border-gray-500"
                  } pl-2`}
                  style={{
                    left: `${((t / Frames.T?.inSize) * 100).toFixed(1)}%`,
                  }}
                >
                  <div className="relative">
                    <div
                      className={`absolute -left-4 -translate-x-[100%] ${
                        !noDark && "dark:text-black"
                      } text-xs`}
                    >
                      {t.toFixed()}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* right border */}
            {Frames.B?.cutAngles?.right == 45 ? (
              <div
                className={`border-r-2 w-5 border-t-2 border-b-2 h-full border-black ${
                  !noDark && "dark:border-gray-500"
                } skew-x-[45deg] mr-2 -ml-[10px] ${
                  Sills?.find(sill => sill.name == "B" && sill.checked)
                    ? "bg-gray-300"
                    : "bg-white"
                }`}
              />
            ) : (
              <div
                className={`border-r-2 h-full border-black ${
                  !noDark && "dark:border-gray-500"
                } ${
                  Sills?.find(sill => sill.name == "B" && sill.checked)
                    ? "bg-gray-300"
                    : "bg-white"
                }`}
              />
            )}
            {/* OutSize */}
            <div className="absolute w-full px-10 left-0 -bottom-4">
              <div
                className={`relative h-2 border-b-[1px] border-l-[1px] border-r-[1px] border-black ${
                  !noDark && "dark:border-white"
                }`}
              >
                <div
                  className={`-mt-3 ${bg} ${
                    !noDark && "dark:bg-dark"
                  } z-10 mx-auto w-fit px-1`}
                >
                  {Frames.B?.outSize}{" "}
                  {FrameType == "Z" &&
                    `(${
                      Frames.B?.outSize +
                      (Frames.B?.cutAngles?.left == 45 ? 19 : 0) +
                      (Frames.B?.cutAngles?.right == 45 ? 19 : 0)
                    })`}
                  {FrameType == "Z50" &&
                    `(${
                      Frames.B?.outSize +
                      (Frames.B?.cutAngles?.left == 45 ? 29 : 0) +
                      (Frames.B?.cutAngles?.right == 45 ? 29 : 0)
                    })`}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!Frames ||
        (FrameType == "None" && (
          <div className="flex flex-col gap-10 h-full justify-center flex-1">
            {/* Layout */}
            {layoutDeduction?.image && (
              <LayoutImage
                layoutDeduction={layoutDeduction}
                openingScopes={openingScopes}
              />
            )}
            {!layoutDeduction?.image && (
              <div className="min-w-[300px] px-10 flex flex-col items-center gap-2">
                <h3>no image</h3>
                <h3>Panels size</h3>
                <div>
                  {
                    // @ts-expect-error
                    OW?.PW?.map(p =>
                      Number(p).toFixed(1).replace(".0", "")
                    ).join(" / ")
                  }
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

interface frameImage {
  image: string;
}
const FrameImg = ({ image }: frameImage) => {
  const [zoom, setZoom] = useState(false);
  const toggleZoom = () => {
    setZoom(!zoom);
  };
  return (
    <img
      src={image}
      onClick={toggleZoom}
      className={`${
        zoom ? "w-96 max-w-[80%] p-5 bg-white rounded-md" : "w-20"
      } cursor-pointer transition-all absolute left-9 top-9 z-10`}
    />
  );
};
