import {
  FormProvider,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { ProductSetType } from "../../../workorder/types";
import { Button, Checkbox } from "flowbite-react";
import { TbMathFunction } from "react-icons/tb";
import { TrashIcon } from "@heroicons/react/24/solid";
import { useMutation } from "@apollo/client";
import { ADD_QUOTE_PARAM, UPDATE_QUOTE_PARAM_BY_PK } from "../gqls";
import { QuoteParams } from "../types";
import { QUOTE_PARAMS_FIELDS } from "../fragments";

interface props {
  productSet: ProductSetType;
  existingSalesParams?: QuoteParams;
}

export default function productSalesParams({
  productSet,
  existingSalesParams,
}: props) {
  const existingSalesParamsValues = existingSalesParams?.value || [];

  const defaultValues = {
    salesParams: existingSalesParamsValues || [],
  };
  const methods = useForm({ defaultValues });

  const { control } = methods;
  const { fields, append, remove } = useFieldArray({
    name: "salesParams",
    control,
  });

  return (
    <FormProvider {...methods}>
      {fields.map((field, index) => (
        <ProductSalesParam key={field.id} index={index} remove={remove} />
      ))}
      <UpdateControl
        append={append}
        existingSalesParams={existingSalesParams}
        productSet={productSet}
      />
    </FormProvider>
  );
}

interface productSalesParam {
  index: number;
  remove: UseFieldArrayRemove;
}

const ProductSalesParam = ({ index, remove }: productSalesParam) => {
  const { register } = useFormContext();
  const _delete = () => {
    remove(index);
  };
  return (
    <div className="p-1 border-b-[1px] flex flex-row items-center justify-between gap-2">
      <Checkbox
        className="checked:bg-plum dark:checked:bg-plum"
        {...register(`salesParams.${index}.default`)}
        title="default value"
      />
      <input
        className="p-1 text-lg font-semibold bg-transparent border-none outline-none"
        placeholder="Enter Param Name"
        {...register(`salesParams.${index}.name`)}
      />
      <div className="flex flex-row items-center gap-1 flex-1">
        <input
          className="p-1 text-grass font-semibold bg-transparent border-none flex-1 text-right outline-none"
          placeholder="Enter number or function"
          {...register(`salesParams.${index}.price`)}
        />
        <TbMathFunction className="w-5 h-5 ml-2" />
      </div>
      <TrashIcon
        className="w-5 text-red-500 cursor-pointer"
        onClick={_delete}
      />
    </div>
  );
};

interface addProps {
  append: UseFieldArrayAppend<
    {
      salesParams: any;
    },
    "salesParams"
  >;
  existingSalesParams?: QuoteParams;
  productSet: ProductSetType;
}
const UpdateControl = ({
  append,
  existingSalesParams,
  productSet,
}: addProps) => {
  const {
    getValues,
    reset,
    formState: { dirtyFields },
  } = useFormContext();
  const isDirty = dirtyFields.salesParams;

  const add = () => {
    append({
      name: "",
      type: "boolean",
      default: false,
      price: "",
    });
  };

  const [update] = useMutation(UPDATE_QUOTE_PARAM_BY_PK);
  const [insert] = useMutation(ADD_QUOTE_PARAM);

  const save = () => {
    const salesParams = getValues("salesParams");
    if (existingSalesParams) {
      update({
        variables: {
          id: existingSalesParams.id,
          set: {
            value: salesParams,
          },
        },
        onCompleted() {
          reset({
            salesParams,
          });
        },
      });
    } else {
      insert({
        variables: {
          object: {
            name: "salesParams",
            value: salesParams,
            productSetId: productSet.id,
          },
        },
        onCompleted() {
          reset({
            salesParams,
          });
        },
        update(cache, { data: { insert_quoteParams_one: newParam } }) {
          cache.modify({
            fields: {
              quoteParams(existing = []) {
                const newParamRef = cache.writeFragment({
                  data: newParam,
                  fragment: QUOTE_PARAMS_FIELDS,
                  fragmentName: "QuoteParamsFields",
                });
                return [...existing, newParamRef];
              },
            },
          });
        },
      });
    }
  };

  const cancel = () => {
    reset({
      salesParams: existingSalesParams || [],
    });
  };

  return (
    <div className="flex flex-row justify-between mt-2 items-center">
      <Button onClick={add} size="xs" outline color="purple">
        Add Sales Param
      </Button>
      {isDirty && (
        <div className="flex flex-row gap-2 items-center">
          <Button size="xs" color="purple" onClick={cancel}>
            cancel
          </Button>
          <Button
            onClick={save}
            size="xs"
            outline
            gradientDuoTone="purpleToBlue"
          >
            update
          </Button>
        </div>
      )}
    </div>
  );
};
