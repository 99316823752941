import { useFormContext } from "react-hook-form";
import { usePub } from "../../../../../../../../../utils/pubsub/pubsub";
import {
  OPTION_UPDATE_ARGS,
  orderEvent,
} from "../../../../../../../../../utils/pubsub/orderEventArgs";

export const useUpdateSelectOptionValue = (
  optionCoord: string,

  openingId: string,
  optionId: number,
  itemId?: string
) => {
  const publish = usePub<OPTION_UPDATE_ARGS>();

  const { setValue, getValues } = useFormContext();

  return (value?: string | number) => {
    const exValue = getValues(`${optionCoord}.value`);
    if (exValue == value) return;

    setValue(`${optionCoord}.value`, value);
    publish(orderEvent.OPTION_UPDATE, {
      orderEvent: orderEvent.OPTION_UPDATE,
      openingId,
      itemId,
      optionId,
    });
  };
};
