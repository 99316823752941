import { Checkbox, ListGroup } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useOnClickOutside } from "usehooks-ts";
import { option } from "../../../types";
import { usePub } from "../../../../../../../utils/pubsub/pubsub";
import {
  OPTION_UPDATE_ARGS,
  orderEvent,
} from "../../../../../../../utils/pubsub/orderEventArgs";

interface props {
  optionCoord: string;
  openingId: string;
  optionId: number;
}

export default function MultiSelect({
  optionCoord,
  openingId,
  optionId,
}: props) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const { setValue, control } = useFormContext();

  const option: option = useWatch({
    name: optionCoord,
    control,
  });

  const publish = usePub<OPTION_UPDATE_ARGS>();

  const updateArrVal = (val: number | string) => {
    let newValues = option.value || [];

    if (!Array.isArray(newValues)) {
      return;
    }
    if (newValues.includes(val)) {
      newValues = newValues.filter(v => v !== val);
    } else {
      newValues = newValues.concat(val);
    }

    setValue(`${optionCoord}.value`, newValues);
    publish(orderEvent.OPTION_UPDATE, {
      openingId,
      optionId,
      orderEvent: orderEvent.OPTION_UPDATE,
    });
  };

  useEffect(() => {
    if (!option.value || !Array.isArray(option.value)) {
      if (option.displayValue !== "None") {
        setValue(`${optionCoord}.displayValue`, "None");
      }
      return;
    }
    if (option.value.length == 0) {
      if (option.displayValue !== "None") {
        setValue(`${optionCoord}.displayValue`, "None");
      }
      return;
    }
    if (option.displayValue !== option.value.sort().join(", ")) {
      setValue(`${optionCoord}.displayValue`, option.value.sort().join(", "));
    }
  }, [JSON.stringify(option.value)]);

  return (
    <div className="relative" ref={ref}>
      <ListGroup onClick={toggleOpen}>
        <ListGroup.Item>
          <div className="text-xs">{option.displayValue}</div>
        </ListGroup.Item>
      </ListGroup>
      {open && (
        <div className="absolute z-20 mt-1 flex flex-col gap-1 bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600">
          {option.values?.map(val => {
            return (
              <div
                onClick={() => {
                  updateArrVal(val);
                }}
                key={val}
                className="group/preset relative flex flex-row gap-2 items-center px-2 py-2"
              >
                <Checkbox
                  readOnly
                  className="checked:bg-plum dark:checked:bg-plum"
                  checked={
                    Array.isArray(option.value) && option.value.includes(val)
                      ? true
                      : false
                  }
                />
                <div className="rounded-md cursor-pointer bg-white dark:bg-gray-700 hover:brightness-90 dark:hover:brightness-125 ring-gray-400 hover:ring-1 text-left min-w-max text-xs">
                  {val}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
