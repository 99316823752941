import { ListGroup, Select } from "flowbite-react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { option } from "../../../../../types";
import DeductionsSelect from "../../deductionsSelect";
import MaterialSelect from "./materialSelect";
import PresetSelect from "./PresetSelect";
import { useEffect, useState } from "react";
import { usePub, useSub } from "../../../../../../../../../utils/pubsub/pubsub";
import {
  orderEvent,
  OPTION_UPDATE_ARGS,
} from "../../../../../../../../../utils/pubsub/orderEventArgs";
import { useDebouncedCallback } from "../../../../../../../../../utils/useDebounceCallback";
import { useIsMounted } from "usehooks-ts";
import { useOrderStore } from "../../../../../../../../../store/orderStore";

interface props {
  optionCoord: string;
  optionsCoord: string;
  itemCoord?: string;
  optionName: string;
  disabled: boolean;
  productIndex: string;
  openingId: string;
  itemId?: string;
  optionId: number;
  option: option;
  locked?: boolean;
}

export default function SelectComp({
  optionCoord,
  optionsCoord,
  optionName,
  itemCoord,
  disabled,
  productIndex,
  openingId,
  itemId,
  optionId,
  locked,
}: props) {
  const { setValue, control, getValues } = useFormContext();

  const OptionValue: option["value"] = useWatch({
    name: `${optionCoord}.value`,
    control,
  });

  const optionValues: option["values"] = useWatch({
    name: `${optionCoord}.values`,
    control,
  });
  const optionConditions: option["conditions"] = useWatch({
    name: `${optionCoord}.conditions`,
    exact: true,
    control,
  });
  const optionSource: option["source"] = useWatch({
    name: `${optionCoord}.source`,
    exact: true,
    control,
  });
  const optionDefault: option["default"] = useWatch({
    name: `${optionCoord}.default`,
    exact: true,
    control,
  });

  const optionDisplayValue: option["displayValue"] | undefined = useWatch({
    name: `${optionCoord}.displayValue`,
    exact: true,
    control,
  });

  const { getProductSetOptionById } = useOrderStore();
  const defaultValues = getProductSetOptionById(optionId)?.values;

  const [formOptions, setFormOptions] = useState<option[]>(
    getValues(optionsCoord) || []
  );

  const getAvailableOptions = () => {
    return !optionConditions || !formOptions || !Array.isArray(formOptions)
      ? optionValues
      : optionValues?.filter(v => {
          const conditionsForThisValue = optionConditions?.filter(
            c => c.value?.toString() == v.toString()
          );

          if (!conditionsForThisValue || conditionsForThisValue.length < 1) {
            return true;
          }

          const check = conditionsForThisValue.every(condition => {
            return condition.conditions.every(condition => {
              const optionToCheck = formOptions.find(
                o => o.id == condition.option
              );
              if (!optionToCheck) {
                return false;
              }
              if (condition.param) {
                return (
                  optionToCheck.populatedValue?.params &&
                  optionToCheck.populatedValue.params.find(
                    // @ts-ignore
                    p =>
                      // @ts-ignore
                      p.name == condition.param.split("::")[0] &&
                      // @ts-ignore
                      p.value == condition.param.split("::")[1]
                  )
                );
              }

              return condition.values
                .map(v => v.toString())
                .includes(optionToCheck.value.toString());
            });
          });
          return check;
        });
  };

  useEffect(() => {
    if (locked) {
      return;
    }
    setAvailableOptions(getAvailableOptions());
  }, [optionValues]);

  const [availableOptions, setAvailableOptions] = useState(
    getAvailableOptions()
  );

  const debouncedUpdateOptions = useDebouncedCallback(() => {
    setFormOptions(getValues(optionsCoord));
    setAvailableOptions(getAvailableOptions());
  }, 300);

  useSub(
    orderEvent.OPTION_UPDATE,
    (args: OPTION_UPDATE_ARGS) => {
      if (args.optionId == optionId) return;
      if (args.openingId == openingId && args.itemId == itemId) {
        debouncedUpdateOptions();
      }
    },
    [openingId, itemId, locked]
  );

  const isMounted = useIsMounted();

  const publish = usePub<OPTION_UPDATE_ARGS>();
  const publishNow = () =>
    publish(orderEvent.OPTION_UPDATE, {
      itemId,
      openingId,
      optionId: Number(optionId),
      orderEvent: orderEvent.OPTION_UPDATE,
    });

  useEffect(() => {
    if (locked) {
      return;
    }
    if (!isMounted) {
      return;
    }

    if (optionSource) {
      return;
    }

    if (!availableOptions) {
      return;
    }

    if (availableOptions.length == 0) {
      if (OptionValue !== "") {
        setValue(`${optionCoord}.value`, "");
        publishNow();
      }
      return;
    }

    const currentValue = getValues(`${optionCoord}.value`);
    const isTouched = getValues(`${optionCoord}.isTouched`);

    if (
      !isTouched ||
      !availableOptions.find(o => o.toString() == OptionValue.toString())
    ) {
      const newVal =
        availableOptions.find(o => o == optionDefault) || availableOptions[0];

      if (newVal.toString() == currentValue?.toString()) {
        return;
      }
      setValue(`${optionCoord}.value`, newVal.toString() || "");
      publishNow();
      return;
    }
  }, [availableOptions, locked]);

  if (locked) {
    return (
      <ListGroup>
        <ListGroup.Item>
          <div className={`text-xs`}>{optionDisplayValue || OptionValue}</div>
        </ListGroup.Item>
      </ListGroup>
    );
  }

  return (
    <>
      {!optionSource && (
        <Controller
          control={control}
          name={`${optionCoord}.value`}
          render={({ field }) => (
            <Select
              {...field}
              onChange={e => {
                field.onChange(e.target.value);
                publishNow();
              }}
              sizing="sm"
              disabled={disabled}
              onClick={() => {
                setValue(`${optionCoord}.isTouched`, true);
              }}
            >
              {availableOptions?.map((v, i) => (
                <option key={v || i} value={v}>
                  {v}
                </option>
              ))}
            </Select>
          )}
        />
      )}
      {optionSource === "deductions" && (
        <DeductionsSelect
          disabled={disabled}
          optionCoord={optionCoord}
          formOptions={formOptions}
          optionValue={OptionValue}
          optionName={optionName}
          optionValues={optionValues}
          optionDefault={optionDefault}
          openingId={openingId}
          itemId={itemId}
          optionId={optionId}
          defaultValues={defaultValues}
        />
      )}
      {optionSource === "inventory" && (
        <MaterialSelect
          disabled={disabled}
          optionCoord={optionCoord}
          formOptions={formOptions}
          optionValue={OptionValue}
          optionName={optionName}
          optionValues={optionValues}
          optionDefault={optionDefault}
          openingId={openingId}
          itemId={itemId}
          optionId={optionId}
          defaultValues={defaultValues}
        />
      )}
      {optionSource === "deductionPreset" && (
        <PresetSelect
          disabled={disabled}
          optionCoord={optionCoord}
          itemCoord={itemCoord}
          productIndex={productIndex}
          formOptions={formOptions}
          optionName={optionName}
          optionValue={OptionValue}
          optionValues={optionValues}
          optionDefault={optionDefault}
          openingId={openingId}
          itemId={itemId}
          optionId={optionId}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
}
