import { useFormContext } from "react-hook-form";
import { additionalMat, inventory, item } from "../types";
import { useOrderStore } from "../../../../../store/orderStore";
import { materialWithInventory } from "./orderPrint/types";

export default function useGetMaterialsWithInventory() {
  const { materials: cachedMats } = useOrderStore();

  const methods = useFormContext();

  const getMaterialsWithInventory = (
    productId: number,
    isOpeningProduct: boolean,
    items?: item[],
    additionalMatArr?: additionalMat[]
  ) => {
    if (!items) {
      return [] as materialWithInventory[];
    }
    const additionalMats: inventory[] =
      (
        (methods?.getValues("additionalMats") as additionalMat[]) ||
        additionalMatArr ||
        []
      )?.filter(
        mat => cachedMats.find(m => m.id == mat.id)?.product.id == productId
      ) || [];

    const allItemMats = [
      ...(items?.map(i => i.materials || []) || []),
      additionalMats,
    ];

    const materials = isOpeningProduct
      ? []
      : allItemMats?.reduce<inventory[]>((prev, cur) => {
          let mats = prev;

          if (!cur) {
            return mats;
          }

          cur?.forEach(mat => {
            if (mats.find(m => m.id == mat.id)) {
              mats = mats.map(em => {
                if (em.id == mat.id) {
                  return { ...em, qty: Number(em.qty) + Number(mat.qty) };
                } else {
                  return em;
                }
              });
            } else {
              mats.push(mat);
            }
          });

          return mats;
        }, []);

    const materialsWithInventory: materialWithInventory[] = materials
      ?.map(m => ({
        ...m,
        inventory: cachedMats?.find(i => i.id == Number(m.id)),
      }))
      ?.filter(mat => mat.qty > 0)
      .filter(mat => {
        if (
          !mat ||
          !mat.inventory ||
          mat.inventory.name.toLowerCase() == "none"
        ) {
          return false;
        }

        return true;
      }) as materialWithInventory[];

    return materialsWithInventory;
  };

  return getMaterialsWithInventory;
}
