import { Checkbox, ListGroup } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useOnClickOutside } from "usehooks-ts";
import { option } from "../../../types";
import { useQuery } from "@apollo/client";
import { GET_DEDUCTIONS_CONDITIONAL_NO_IMG } from "../../../../gqls";
import { usePub } from "../../../../../../../utils/pubsub/pubsub";
import {
  OPTION_UPDATE_ARGS,
  orderEvent,
} from "../../../../../../../utils/pubsub/orderEventArgs";

interface props {
  optionCoord: string;
  optionsCoord: string;
  optionValue: option["value"];
  openingId: string;
  optionId: number;
}

export default function Division({
  optionsCoord,
  optionCoord,
  optionValue,
  openingId,
  optionId,
}: props) {
  const { data: data_deductions } = useQuery(
    GET_DEDUCTIONS_CONDITIONAL_NO_IMG,
    {
      variables: {
        where: {
          optionCode: { _eq: "ShutterLayout" },
        },
      },
    }
  );
  const deductions = data_deductions?.deductions;

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const { register, control, setValue } = useFormContext();

  const { fields, replace } = useFieldArray({
    name: `${optionCoord}.value`,
    control,
  });

  const formOptions: option[] = useWatch({
    name: optionsCoord,
    control,
  });

  const optionDisplayValue = useWatch({
    control,
    name: `${optionCoord}.displayValue`,
  });

  const type = formOptions?.find(o => o.name == "Type")?.value;
  const qty = formOptions?.find(o => o.name == "Qty")?.value;
  const layout = formOptions?.find(o => o.name == "Layout")?.value;
  const trackQty = formOptions?.find(o => o.name == "TrackQty")?.value;

  const divisionOptions = () => {
    if (trackQty == 3) {
      return [];
    }

    const layoutCode = deductions?.find(
      d => d.id.toString() == layout?.toString()
    )?.name;

    if (!type || !qty || !layoutCode) {
      return false;
    }

    const special = /[`D!@#$%^*\-_+\=\[\]{};':"\\|,.<>\/?~]/g;

    let tPostCounter = 0;
    const TPostConfig = layoutCode
      ?.toUpperCase()
      .replace(special, "")
      .split("")
      .map((t, i) => {
        if (t === "T") {
          const val = [i - tPostCounter, i + 1 - tPostCounter].join("|");
          tPostCounter++;
          return val;
        }
        return null;
      })
      .filter(i => i !== null);

    if (!qty) {
      return;
    }

    const slidingLayout = layoutCode
      ?.replace(special, "")
      .toUpperCase()
      .split("");

    const slidingSplits: string[] = [];

    if (slidingLayout) {
      for (let i = 0; i < slidingLayout.length; i++) {
        if (slidingLayout[i - 1] && slidingLayout[i - 1] !== slidingLayout[i]) {
          slidingSplits.push([i, i + 1].join("|"));
        }
      }
    }

    const options = [];

    for (let i = 1; i < Number(qty); i++) {
      const between = [i, i + 1].join("|");

      const existing =
        Array.isArray(optionValue) &&
        optionValue &&
        optionValue.find(v => v.between == between);

      const value = existing?.value || 0;

      const checked = existing?.checked || false;

      options.push({
        between,
        checked,
        value,
      });
    }

    return options.filter(o => {
      if (TPostConfig && TPostConfig.length > 0) {
        return TPostConfig && TPostConfig.find(t => t == o.between);
      }

      if (type == "Sliding") {
        return slidingSplits.find(s => s == o.between);
      }

      return true;
    });
  };

  useEffect(() => {
    const newOptions = divisionOptions();
    if (newOptions) {
      replace(divisionOptions());
    }
  }, [
    JSON.stringify(type),
    JSON.stringify(qty),
    JSON.stringify(layout),
    deductions,
    JSON.stringify(trackQty),
  ]);

  useEffect(() => {
    if (!optionValue) {
      if (optionDisplayValue !== "None") {
        setValue(`${optionCoord}.displayValue`, "None");
      }
      return;
    }
    const newDisplayValue =
      // @ts-ignore
      optionValue.filter(v => v.checked).length || "None";
    if (optionDisplayValue !== newDisplayValue) {
      setValue(`${optionCoord}.displayValue`, newDisplayValue);
    }
  }, [JSON.stringify(optionValue)]);

  const publish = usePub<OPTION_UPDATE_ARGS>();

  return (
    <div className={`relative`} ref={ref}>
      <ListGroup onClick={toggleOpen}>
        <ListGroup.Item>
          {optionValue &&
          Array.isArray(optionValue) &&
          optionValue.filter(v => v?.checked).length > 0 ? (
            <div className="text-xs">{optionDisplayValue}</div>
          ) : (
            <div className="text-xs">None</div>
          )}
        </ListGroup.Item>
      </ListGroup>
      {open && (
        <div className="absolute z-20 mt-1 flex flex-col gap-1 bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600">
          {fields?.map((val, i) => {
            // @ts-expect-error
            const name = val.between;
            return (
              <div
                key={val.id}
                className="group/preset relative flex flex-row gap-2 items-center px-2 py-1"
              >
                <Checkbox
                  className="checked:bg-plum dark:checked:bg-plum"
                  {...register(`${optionCoord}.value[${i}].checked`)}
                  onChange={e => {
                    setValue(
                      `${optionCoord}.value[${i}].checked`,
                      e.target.checked
                    );
                    publish(orderEvent.OPTION_UPDATE, {
                      openingId,
                      optionId,
                      orderEvent: orderEvent.OPTION_UPDATE,
                    });
                  }}
                />
                <div className="text-xs">{name}</div>
                <input
                  className="w-20 bg-transparent text-xs py-1"
                  type="number"
                  {...register(`${optionCoord}.value[${i}].value`)}
                  onChange={e => {
                    setValue(
                      `${optionCoord}.value[${i}].value`,
                      e.target.value
                    );
                    publish(orderEvent.OPTION_UPDATE, {
                      openingId,
                      optionId,
                      orderEvent: orderEvent.OPTION_UPDATE,
                    });
                  }}
                  onKeyDown={e => {
                    if (e.key == "ArrowUp" || e.key == "ArrowDown")
                      e.preventDefault();
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
