import { TextInput } from "flowbite-react";
import { useState } from "react";
import { breakdownData } from "../../summary/getPrice";
import BreakDown from "./BreakDown";
import checkAuth from "../../../../../../../utils/checkAuth";

export default function PriceBox({
  currentPrice,
  breakDownData,
  isRetail,
  customInstallPrice,
}: {
  currentPrice: number;

  breakDownData?: breakdownData;
  isRetail: boolean;
  customInstallPrice: number;
}) {
  const permission = checkAuth(["quote_see_breakdown"]);
  const [open, setOpen] = useState(false);

  return (
    <div className={`relative mr-1 group/option w-20 my-[5px] `}>
      <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
        Price
      </div>
      <TextInput
        addon="$"
        className="w-full"
        value={Math.ceil(currentPrice)}
        onChange={() => {}}
        onContextMenu={e => {
          if (!permission) return;
          e.preventDefault();
          setOpen(true);
        }}
        sizing="sm"
      />
      <BreakDown
        open={open}
        setOpen={setOpen}
        breakDownData={breakDownData}
        currentPrice={currentPrice}
        isRetail={isRetail}
        customInstallPrice={customInstallPrice}
      />
    </div>
  );
}
