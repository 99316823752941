import { Checkbox, ListGroup } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useOnClickOutside } from "usehooks-ts";
import { option } from "../../../types";
import { usePub } from "../../../../../../../utils/pubsub/pubsub";
import {
  OPTION_UPDATE_ARGS,
  orderEvent,
} from "../../../../../../../utils/pubsub/orderEventArgs";

interface props {
  optionCoord: string;
  optionValue: option["value"];
  optionsCoord: string;
  openingId: string;
  optionId: number;
}

export default function Midrail({
  optionValue,
  optionCoord,
  optionsCoord,
  openingId,
  optionId,
}: props) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const { register, control, setValue, getValues } = useFormContext();

  const { fields, replace } = useFieldArray({
    name: `${optionCoord}.value`,
    control,
  });

  const optionDisplayValue = useWatch({
    control,
    name: `${optionCoord}.displayValue`,
  });

  const midrailOptions = () => {
    const options = Array(3).fill({
      critical: false,
      value: 0,
    });
    return options;
  };

  useEffect(() => {
    const newOptions = midrailOptions();
    if (
      !Array.isArray(optionValue) ||
      newOptions?.length !== optionValue.length
    ) {
      replace(newOptions);
    }
  }, []);

  const nullishValues = [undefined, null, "", "0", 0];

  useEffect(() => {
    if (
      !optionValue ||
      !Array.isArray(optionValue) ||
      optionValue.filter(v => !nullishValues.includes(v.value)).length == 0
    ) {
      if (optionDisplayValue !== "None") {
        setValue(`${optionCoord}.displayValue`, "None");
      }
      return;
    }

    const newDisplayValue = optionValue
      // @ts-ignore
      .filter(
        // @ts-ignore
        v => !nullishValues.includes(v.value)
      )
      // @ts-ignore
      .map(val => `${val.value}${val.critical ? "(!)" : ""}`)
      .join(", ");

    if (optionDisplayValue !== newDisplayValue) {
      setValue(`${optionCoord}.displayValue`, newDisplayValue);
    }
  }, [JSON.stringify(optionValue)]);

  // @ts-ignore
  const heightIndex = getValues(optionsCoord)?.findIndex(o => o?.name == "H");

  const height = Number(
    useWatch({
      name: `${optionsCoord}.${heightIndex}.value`,
      control,
    })
  );

  const publish = usePub<OPTION_UPDATE_ARGS>();

  return (
    <div className="relative" ref={ref}>
      <ListGroup onClick={toggleOpen}>
        <ListGroup.Item>
          {optionValue &&
          Array.isArray(optionValue) &&
          optionValue.filter(v => !nullishValues.includes(v.value)).length >
            0 ? (
            <div className="text-xs">{optionDisplayValue}</div>
          ) : (
            <div
              className={`text-xs ${
                height > 1600 && "text-red-500 animate-pulse"
              }`}
            >
              {height > 1600 ? "Required" : "None"}
            </div>
          )}
        </ListGroup.Item>
      </ListGroup>
      {open && (
        <div className="absolute z-20 mt-1 flex flex-col gap-1 bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600">
          <div className="p-1 text-center">height | critical?</div>
          {fields?.map((val, i) => {
            return (
              <div
                key={val.id}
                className="group/preset relative flex flex-row gap-2 items-center px-2 py-1"
              >
                <input
                  className="w-20 bg-transparent text-xs py-1"
                  type="number"
                  {...register(`${optionCoord}.value[${i}].value`)}
                  onChange={e => {
                    setValue(
                      `${optionCoord}.value[${i}].value`,
                      e.target.value
                    );
                    publish(orderEvent.OPTION_UPDATE, {
                      openingId,
                      optionId,
                      orderEvent: orderEvent.OPTION_UPDATE,
                    });
                  }}
                  onKeyDown={e => {
                    if (e.key == "ArrowUp" || e.key == "ArrowDown")
                      e.preventDefault();
                  }}
                />
                <Checkbox
                  className="checked:bg-plum dark:checked:bg-plum"
                  {...register(`${optionCoord}.value[${i}].critical`)}
                  onChange={e => {
                    setValue(
                      `${optionCoord}.value[${i}].critical`,
                      e.target.checked
                    );
                    publish(orderEvent.OPTION_UPDATE, {
                      openingId,
                      optionId,
                      orderEvent: orderEvent.OPTION_UPDATE,
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
