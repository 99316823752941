import { Button, Tooltip } from "flowbite-react";
import {
  useWatch,
  useFormContext,
  useFieldArray,
  UseFieldArrayRemove,
  FieldValues,
  UseFieldArrayAppend,
  Controller,
} from "react-hook-form";
import Item from "./item";
import {
  DocumentDuplicateIcon,
  PlusIcon,
  RectangleGroupIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { LayoutType, ProductSetType } from "../../../../types";
import uuid from "react-uuid";
import { memo, useState } from "react";
import { item, layoutCode } from "../../../types";
import { createPortal } from "react-dom";
import { useScheme } from "../../../../../../../store/schemeStore";
import { useQuery } from "@apollo/client";
import { GET_LAYOUTS } from "../../../../gqls";
import ShowHideControl from "../showHideControl";
import {
  Active,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { useSub } from "../../../../../../../utils/pubsub/pubsub";
import {
  ITEM_SELECT_ARGS,
  orderEvent,
} from "../../../../../../../utils/pubsub/orderEventArgs";

interface props {
  locationIndex: number;
  openingIndex: number;
  product?: ProductSetType;
  openingId: string;
}

const Items = ({ locationIndex, openingIndex, product, openingId }: props) => {
  const { control } = useFormContext();

  const itemsIndex = `locations.${locationIndex}.openings.${openingIndex}.items`;

  const { fields, remove, append, insert, swap } = useFieldArray({
    control,
    name: itemsIndex,
  });

  const addRow = () =>
    append({
      id: uuid(),
      index: undefined,
      width: 0,
      height: 0,
      selected: false,
      options: [],
      calcs: [],
      note: "",
    });

  const hidden = useWatch({
    name: `locations.${locationIndex}.openings.${openingIndex}.hidden`,
    control,
  });

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = fields.findIndex(({ id }) => id === active.id);
      const overIndex = fields.findIndex(({ id }) => id === over.id);
      swap(activeIndex, overIndex);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  if (product?.hideItems) {
    return null;
  }

  return (
    <>
      {fields.length > 1 && (
        <Tooltip content={`${hidden ? "show" : "hide"} opening`}>
          <div className="ml-1 flex flex-row gap-2 items-center">
            <Controller
              name={`locations.${locationIndex}.openings.${openingIndex}.hidden`}
              control={control}
              defaultValue={false}
              render={({ field }) => <ShowHideControl field={field} />}
            />
            {hidden && <div>{fields.length}</div>}
          </div>
        </Tooltip>
      )}
      <div
        className={`flex flex-col ${fields.length === 1 && "pr-20"} ${
          fields.length > 1 && `w-full ${hidden && "hidden"}`
        }`}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToWindowEdges]}
        >
          <SortableContext
            items={fields.map(field => field.id)}
            strategy={verticalListSortingStrategy}
          >
            {fields.map((item, index) => {
              return (
                <Item
                  openingId={openingId}
                  key={item.id}
                  id={item.id}
                  locationIndex={locationIndex}
                  openingIndex={openingIndex}
                  index={index}
                  length={fields.length}
                  remove={remove}
                  addRow={addRow}
                  insert={insert}
                  product={product}
                />
              );
            })}
          </SortableContext>
        </DndContext>
        {fields.length > 1 && (
          <div className="flex flex-row relative justify-center my-4">
            <hr className="absolute w-full top-[50%] border-plum" />
            <div className="flex flex-row gap-2 px-2 dark:bg-dark z-10">
              <Button
                gradientDuoTone="purpleToBlue"
                size="xs"
                outline
                onClick={addRow}
              >
                <PlusIcon className="w-4 mr-1" /> Item
              </Button>
            </div>
          </div>
        )}

        <ItemsActions
          openingCoord={`locations.${locationIndex}.openings.${openingIndex}`}
          itemsIndex={itemsIndex}
          remove={remove}
          append={append}
          productId={product?.id}
          openingId={openingId}
        />
      </div>
    </>
  );
};

export default memo(Items);

interface itemsActions {
  itemsIndex: string;
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<FieldValues, string>;
  openingCoord: string;
  productId?: number;
  openingId: string;
}

const ItemsActions = ({
  itemsIndex,
  remove,
  append,
  openingCoord,
  productId,
  openingId,
}: itemsActions) => {
  const { scheme } = useScheme();

  const { setValue, getValues } = useFormContext();

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const { data: data_layouts } = useQuery(GET_LAYOUTS);
  const layouts = data_layouts?.layouts;

  const [selected, setSelected] = useState<item[]>([]);
  const [availableLayouts, setAvailableLayouts] = useState<
    LayoutType[] | undefined
  >([]);

  useSub(orderEvent.ITEM_SELECT, (args: ITEM_SELECT_ARGS) => {
    if (args.openingId !== openingId) {
      return;
    }
    const items: item[] = getValues(itemsIndex);
    const selected = items
      .map((l, i) => ({ ...l, index: i }))
      .filter(l => l.selected);

    const availableLayouts = layouts
      ?.filter(l => l.productSet.id == productId)
      .filter(l => {
        const layoutParentOptions = l.parent?.map(p => p.option);

        if (l.parent) {
          const parentOptions = selected
            .map(s => s.options)
            .flat()
            .filter(o => layoutParentOptions?.includes(o.id));

          const check = parentOptions.every(po => {
            const parentOption = l.parent?.find(p => p.option == po.id);
            const type = parentOption?.type;
            const requiredValues = parentOption?.values;

            if (type == "equal") {
              const currentOptionValue = po.value;
              const samePOs = parentOptions.filter(p => p.id == po.id);
              return samePOs.every(sp => sp.value == currentOptionValue);
            }

            if (!requiredValues) {
              return true;
            }
            return requiredValues.includes(po.value);
          });

          return check;
        } else {
          return true;
        }
      })
      .filter(l => l.config.length == selected.length);

    setSelected(selected);
    setAvailableLayouts(availableLayouts);
  });

  const _delete = () => {
    const selectedIndexes = selected
      .map(s => s.index)
      .filter(s => s != undefined);
    remove(selectedIndexes as number[]);
  };

  const uncheck = () => {
    for (const item of selected) {
      setValue(`${itemsIndex}.${item.index}.selected`, false);
    }
    setSelected([]);
  };

  const duplicate = () => {
    append(
      selected.map(l => ({
        ...l,
        index: undefined,
        selected: false,
        id: uuid(),
      }))
    );
    uncheck();
  };

  const applyLayout = (id: number) => {
    const layout = layouts?.find(l => l.id == id);
    if (!layout) {
      return;
    }

    const layoutCodes = getValues(`${openingCoord}.layoutCodes`);

    const newLayoutCodes: layoutCode[] = layoutCodes?.concat({
      scope: selected.map(i => i.id),
      layout,
    }) || [
      {
        scope: selected.map(i => i.id),
        layout,
      },
    ];

    setValue(`${openingCoord}.layoutCodes`, newLayoutCodes);
    uncheck();
  };

  if (selected.length < 1) {
    return null;
  }

  return (
    <>
      {createPortal(
        <div className={`${scheme}`}>
          <div className="absolute z-10 left-0 w-full bottom-0 px-4 py-2 flex flex-row gap-2 bg-dark text-white dark:text-black dark:bg-gray-50 border-t-2 border-plum">
            {/* delete */}
            <div
              className="flex flex-row gap-2 cursor-pointer items-center"
              onClick={_delete}
            >
              <TrashIcon className="w-5 text-red-500" />
              <div>Delete {selected.length} Items</div>
            </div>
            {/* duplicate */}
            <div
              className="flex flex-row gap-2 cursor-pointer items-center"
              onClick={duplicate}
            >
              <DocumentDuplicateIcon className="w-5 text-grass" />
              <div>Duplicate {selected.length} Items</div>
            </div>
            {/* layoutCodes */}
            {availableLayouts && availableLayouts.length > 0 && (
              <div className="relative">
                <div
                  className="flex flex-row gap-2 cursor-pointer items-center"
                  onClick={toggleOpen}
                >
                  <RectangleGroupIcon className="w-5 text-plum" />
                  <div>Group {selected.length} Items</div>
                </div>
                {open && (
                  <div className="absolute z-20 bottom-full mr-2 flex flex-col gap-1 bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600">
                    {availableLayouts.map(layout => (
                      <div
                        onClick={() => {
                          applyLayout(Number(layout.id));
                          setOpen(false);
                        }}
                        key={layout.id}
                        className="group/layoutselect"
                      >
                        <div className="text-black dark:text-white rounded-md px-4 py-2 cursor-pointer bg-white dark:bg-gray-700 hover:brightness-90 dark:hover:brightness-125 ring-gray-400 hover:ring-1 text-left min-w-max text-xs">
                          {layout.name}
                        </div>

                        <div className="hidden w-max absolute group-hover/layoutselect:block left-[calc(100%+8px)] bottom-0 dark:bg-gray-700 bg-white rounded-md border-[1px] dark:border-gray-600 p-2">
                          <div className="flex flex-row items-start justify-start overflow-x-auto ">
                            {layout.image && (
                              <img
                                className={`w-60 min-h-[200px] dark:invert`}
                                src={layout.image}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>,
        document.body
      )}
    </>
  );
};
