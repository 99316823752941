import { ListGroup } from "flowbite-react";
import { useFormContext, useWatch } from "react-hook-form";
import { calc, itemWarning, option } from "../../../../../types";
import { optionValues } from "../../../../../../types";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_IMAGES_BY_PID } from "../../../../../../gqls";
import { useDebounce, useIsMounted, useOnClickOutside } from "usehooks-ts";
import { create, all } from "mathjs";
import { useOrderStore } from "../../../../../../../../../store/orderStore";
import { sort } from "fast-sort";
import useScopeConcat from "../../scopeConcat";
import { useUpdateSelectOptionValue } from "./updateValue";
import {
  subscription,
  usePub,
  useSub,
  useSubs,
} from "../../../../../../../../../utils/pubsub/pubsub";
import {
  CALC_UPDATE_ARGS,
  OPTION_UPDATE_ARGS,
  orderEvent,
  SIZE_UPDATE_ARGS,
} from "../../../../../../../../../utils/pubsub/orderEventArgs";
import { useDebouncedCallback } from "../../../../../../../../../utils/useDebounceCallback";

interface props {
  formOptions: option[];
  itemCoord?: string;
  optionCoord: string;
  disabled?: boolean;
  productIndex: string;
  optionName: string;
  optionValue: any;
  optionValues: option["values"];
  optionDefault: option["default"];
  openingId: string;
  itemId?: string;
  optionId: number;
  defaultValues: (string | number)[] | undefined;
}

export default function PresetSelect({
  itemCoord,
  optionCoord,
  disabled,
  formOptions,
  productIndex,
  optionName,
  optionValue,
  optionValues,
  optionDefault,
  openingId,
  itemId,
  optionId,
  defaultValues,
}: props) {
  const math = create(all);

  math.SymbolNode.onUndefinedSymbol = () => {
    return 0;
  };

  const { setValue, control, getValues } = useFormContext();

  const optionCode = useWatch({
    name: `${optionCoord}.optionCode`,
    exact: true,
    control,
  });

  const { presets: cachedPresets, getDeductionsByIds: getDeductionById } =
    useOrderStore();

  const presets = cachedPresets.filter(cd => {
    if (optionCode) {
      return cd.optionCode == optionCode;
    } else {
      return defaultValues?.includes(cd.id);
    }
  });

  const productSetId: number = useWatch({
    name: productIndex,
    control,
    exact: true,
  });

  const { productSets } = useOrderStore();
  const warnings = productSets.find(p => p.id == productSetId)?.warnings || [];

  const { data: data_image } = useQuery(GET_PRODUCT_IMAGES_BY_PID, {
    variables: {
      id: Number(productSetId),
    },
    onError(error) {
      console.log(error);
    },
  });

  const images = data_image?.productImages;

  useEffect(() => {
    if (!optionValue) {
      return;
    }

    const dpValue =
      presets.find(d => d.id.toString() === optionValue.toString())?.name || "";

    const currentDisplayValue = getValues(`${optionCoord}.displayValue`);

    if (currentDisplayValue !== dpValue) {
      setValue(`${optionCoord}.displayValue`, dpValue);
    }
  }, [
    JSON.stringify(optionValue),
    sort(presets)
      .asc("id")
      .map(m => m.id)
      .join(", "),
  ]);

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    if (disabled) {
      return;
    }
    if (!open) {
      setFilteredOptions(getFilteredOptions());
    }
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const publishCalcs = usePub<CALC_UPDATE_ARGS>();
  const updateAndPublish = useUpdateSelectOptionValue(
    optionCoord,
    openingId,
    optionId,
    itemId
  );

  const selectedPreset = cachedPresets.find(
    p => p.id.toString() == optionValue.toString()
  );

  const getFilteredOptions = () => {
    const presetsIdOnly = presets.map(p => p.id);

    const res = presetsIdOnly
      ? presetsIdOnly.filter(o => {
          const preset = presets.find(p => p.id.toString() == o.toString());
          if (!preset) {
            return false;
          }
          const conditions = preset?.optionCondition;
          const reducedConditions = conditions?.reduce<optionValues[]>(
            (acc, cur) => {
              if (acc.find(option => option.option == cur.option)) {
                return acc.map(ov => {
                  if (ov.option == cur.option) {
                    return { ...ov, values: ov.values.concat(cur.value) };
                  } else {
                    return ov;
                  }
                });
              } else {
                return acc.concat({ option: cur.option, values: [cur.value] });
              }
            },
            []
          );

          if (reducedConditions) {
            return reducedConditions.every(c => {
              const myOption = formOptions.find(o => o.id == Number(c.option));
              const values = c.values.map(v => v.toString());
              return values.includes(myOption?.value.toString());
            });
          }

          return true;
        })
      : [];

    return res;
  };

  const [filteredOptions, setFilteredOptions] = useState<(number | string)[]>(
    optionValues || getFilteredOptions()
  );

  const debounedUpdateFinalOptions = useDebouncedCallback(
    (args: OPTION_UPDATE_ARGS) => {
      if (
        args.itemId == itemId &&
        args.openingId == openingId &&
        args.optionId !== optionId
      ) {
        const newOptions = getFilteredOptions();
        if (
          newOptions.length == filteredOptions.length &&
          newOptions.every(o => filteredOptions.includes(o))
        ) {
          return;
        }
        setFilteredOptions(newOptions);
      }
    },
    200
  );

  const scopeConcat = useScopeConcat();

  const isMounted = useIsMounted();

  const updateValue = () => {
    if (filteredOptions.length == 0) {
      if (optionValue !== "") {
        updateAndPublish("");
      }
      return;
    }
    if (!filteredOptions.find(o => o.toString() == optionValue.toString())) {
      let newVal: any =
        filteredOptions.find(o => o.toString() == optionDefault?.toString()) ||
        filteredOptions[0];

      newVal = newVal
        ? presets.find(d => d.id.toString() == newVal.toString())?.id
        : "";

      const disableAutoSelect = getValues(`${optionCoord}.disableAutoSelect`);

      if (disableAutoSelect) {
        newVal = "";
      }

      if (optionValue !== newVal?.toString()) {
        updateAndPublish(newVal?.toString());
      }
    }
  };

  useEffect(() => {
    if (!isMounted) {
      if (filteredOptions.includes(optionValue)) {
        return;
      } else {
        updateValue();
      }
    }
    updateValue();
    setValue(`${optionCoord}.values`, filteredOptions);
  }, [JSON.stringify(filteredOptions)]);

  const debouncedOptionsToCheck = sort(
    useDebounce(formOptions).filter(o => o.source !== "deductionPreset")
  )
    .asc("id")
    .map(m => `${m.id}:${m.value}`)
    .join(", ");

  const updateCalcs = () => {
    if (!selectedPreset) {
      return;
    }
    const currentCalcs: calc[] = getValues(`${itemCoord}.calcs`) || [];
    const deductions = getDeductionById(selectedPreset?.deductions || []);
    const widthDeduction =
      deductions?.reduce<number>((prv, cur) => {
        if (!cur) {
          return prv;
        }
        return (
          prv +
          (cur.width || 0) +
          (cur.widthAddition || 0) +
          (cur.parentDeduction?.width || 0) +
          (cur.parentDeduction?.widthAddition || 0)
        );
      }, 0) || 0;

    const heightDeduction =
      deductions?.reduce<number>((prv, cur) => {
        if (!cur) {
          return prv;
        }
        return (
          prv +
          (cur.height || 0) +
          (cur.heightAddition || 0) +
          (cur.parentDeduction?.height || 0) +
          (cur.parentDeduction?.heightAddition || 0)
        );
      }, 0) || 0;

    const itemWidth = getValues(`${itemCoord}.width`);
    const itemHeight = getValues(`${itemCoord}.height`);

    const finalWidth = Number(itemWidth) + widthDeduction;
    const finalHeight = Number(itemHeight) + heightDeduction;

    const scopes = formOptions
      ?.filter(fo => !fo.noCalc)
      .reduce<any>(
        (prv, cur) => {
          const scopeName = cur.name.replaceAll(" ", "");
          let appended = {
            ...prv,
          };
          appended = scopeConcat({
            prvScopes: appended,
            scopeName,
            option: cur,
          });
          if (scopeName.includes(optionName)) {
            appended = scopeConcat({
              prvScopes: appended,
              scopeName: scopeName.replace(optionName, ""),
              option: cur,
            });
          }
          return appended;
        },
        { W: finalWidth, H: finalHeight, optionName }
      );

    if (!selectedPreset.formulas) {
      return;
    }

    let calcs: (calc | null)[] = sort(selectedPreset.formulas)
      .asc("priority")
      .map(f => {
        try {
          const size = math.evaluate(f.formula, scopes);

          Object.assign(scopes, {
            [f.name.replaceAll(" ", "")]: Number(size),
          });

          return {
            name: f.name,
            displayName: f.displayName,
            optionId,
            size,
            show: f.show,
          };
        } catch (err) {
          console.log(err, scopes);

          // @ts-expect-error
          const scopesInvalid = err.toString().includes("Undefined symbol");

          return scopesInvalid
            ? null
            : {
                name: f.name,
                displayName: f.displayName,
                optionId,
                size: 0,
                show: f.show,
              };
        }
      })
      .filter(c => c !== null);

    calcs = sort(selectedPreset.formulas)
      .asc("priority")
      .map(f => {
        try {
          const size = math.evaluate(f.formula, scopes);

          Object.assign(scopes, {
            [f.name.replaceAll(" ", "")]: Number(size),
          });

          return {
            name: f.name,
            displayName: f.displayName,
            optionId,
            size,
            show: f.show,
          };
        } catch (err) {
          // console.log(scopes);

          // @ts-expect-error
          const scopesInvalid = err.toString().includes("Undefined symbol");

          return scopesInvalid
            ? null
            : {
                name: f.name,
                displayName: f.displayName,
                optionId,
                size: 0,
                show: f.show,
              };
        }
      })
      .filter(c => c !== null);

    const mergedCalcs = currentCalcs
      .filter(
        c =>
          !calcs?.some(
            calc => calc?.name == c.name && calc.optionId == c.optionId
          )
      )
      .concat(calcs as calc[]);

    const itemWarnings: itemWarning[] = [];

    for (const warning of warnings) {
      const bool = math.evaluate(warning.condition, scopes);
      if (bool) {
        itemWarnings.push({
          presetOption: optionName,
          option: warning.where,
          value: warning.message,
          condition: warning.condition,
        });
      }
    }

    const exWarnings: itemWarning[] = getValues(`${itemCoord}.warnings`) || [];
    const newWarnings = exWarnings
      .filter(ew => {
        const myOption = formOptions.find(fo => fo.name == ew.presetOption);
        const bool = math.evaluate(ew.condition, scopes);
        if (!bool) return false;
        if (!myOption || myOption.noCalc) return false;
        if (itemWarnings.some(iw => iw.presetOption == ew.presetOption))
          return false;
        return true;
      })
      .concat(itemWarnings);

    setValue(`${itemCoord}.warnings`, newWarnings);

    if (
      formOptions
        .filter(o => o.source == "deductionPreset")
        .filter(o => !o.noCalc).length > 1
    ) {
      if (mergedCalcs) {
        setValue(`${itemCoord}.calcs`, sort(mergedCalcs).asc("priority"));
        publishCalcs(orderEvent.CALC_UPDATE, {
          orderEvent: orderEvent.CALC_UPDATE,
          openingId,
          itemId,
        });
      }
    } else {
      setValue(`${itemCoord}.calcs`, sort(calcs as calc[]).asc("priority"));
      publishCalcs(orderEvent.CALC_UPDATE, {
        orderEvent: orderEvent.CALC_UPDATE,
        openingId,
        itemId,
      });
    }
  };

  const debouncedUpdateCalcs = useDebouncedCallback(updateCalcs, 300, [
    itemCoord,
    selectedPreset?.id,
  ]);

  useEffect(() => {
    debouncedUpdateCalcs();
  }, [JSON.stringify(debouncedOptionsToCheck), selectedPreset?.id]);

  const subscriptions: subscription[] = [
    {
      event: orderEvent.SIZE_UPDATE,
      callback: arg => {
        const args = arg as SIZE_UPDATE_ARGS;

        if (args.openingId !== openingId || args.itemId !== itemId) return;
        debouncedUpdateCalcs();
      },
    },
    {
      event: orderEvent.OPTION_UPDATE,
      callback: arg => {
        const args = arg as OPTION_UPDATE_ARGS;
        if (args.openingId !== openingId || args.itemId !== itemId) return;
        debounedUpdateFinalOptions(args);
        debouncedUpdateCalcs();
      },
    },
  ];

  useSubs(subscriptions, [openingId, itemId, itemCoord, selectedPreset?.id]);

  return (
    <div className="relative" ref={ref}>
      <ListGroup
        className={`${disabled ? "brightness-90 dark:brightness-75" : ""}`}
        onClick={toggleOpen}
      >
        <ListGroup.Item>
          <div className="text-xs">{selectedPreset?.name || "Required"}</div>
        </ListGroup.Item>
      </ListGroup>
      {open && (
        <div className="absolute z-20 mt-1 pb-10">
          <div className="flex flex-col gap-1 bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600">
            {filteredOptions?.map(o => {
              const preset = presets.find(p => p.id.toString() == o.toString());
              if (!preset) {
                return null;
              }

              const myImage = images?.find(image => {
                const optionTest = image.options.every(o => {
                  const where = o.option;
                  const values = o.values?.map(v => v.toString());
                  if (!where || !values) {
                    return false;
                  }

                  const options = formOptions.map(f => {
                    if (f.name == optionName) {
                      return { ...f, value: preset.id };
                    } else {
                      return f;
                    }
                  });

                  return values.includes(
                    options?.find(o => o.id == where)?.value?.toString()
                  );
                });

                return optionTest;
              });

              return (
                <div
                  onClick={() => {
                    updateAndPublish(preset.id);
                    setOpen(false);
                  }}
                  key={preset.id}
                  className="group/preset relative"
                >
                  <div className="rounded-md px-4 py-2 cursor-pointer bg-white dark:bg-gray-700 hover:brightness-90 dark:hover:brightness-125 ring-gray-400 hover:ring-1 text-left min-w-max text-xs">
                    {preset.name}
                  </div>
                  {myImage?.image && (
                    <div className="hidden w-max absolute group-hover/preset:block left-[calc(100%+8px)] top-[50%] -translate-y-[50%] dark:bg-gray-700 bg-white rounded-md border-[1px] dark:border-gray-600 p-2">
                      <img
                        src={myImage.image}
                        className={`${
                          myImage.invert && "dark:invert"
                        } w-[200px]`}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
