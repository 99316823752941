import { Checkbox } from "flowbite-react";
import { ChangeEventHandler } from "react";

interface props {
  name: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  readOnly?: boolean;
  className?: string;
  disabled?: boolean;
}

export default function CheckboxWithLabel({
  name,
  checked,
  onChange,
  readOnly,
  className,
  disabled,
}: props) {
  return (
    <div
      className={`flex flex-row items-center rounded-lg dark:border-gray-600 border-gray-300 border-[1px] my-auto h-fit ${className}`}
    >
      <div className="bg-gray-200 px-2 py-[6px] rounded-l-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600 border-gray-300 border-r-[1px]">
        {name}
      </div>
      <div className="p-[6px] bg-white dark:bg-gray-700 rounded-r-lg">
        <Checkbox
          className="checked:bg-plum dark:checked:bg-plum"
          checked={checked}
          onChange={onChange}
          readOnly={readOnly}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
