import { useEffect } from "react";
import { EventEmitter } from "eventemitter3";
import { orderEvent } from "./orderEventArgs";

const emitter = new EventEmitter();

export type subscription = {
  event: orderEvent;
  callback: (arg: unknown) => void;
};

export const useSub = <T>(
  event: orderEvent,
  callback: (arg: T) => void,
  dependencies?: any[]
) => {
  const unsubscribe = () => {
    emitter.off(event, callback);
  };

  useEffect(() => {
    emitter.on(event, callback);
    return unsubscribe;
  }, [event, callback, ...(dependencies || [])]);

  return unsubscribe;
};

export const useSubs = (
  subscriptions: subscription[],
  dependencies?: any[]
) => {
  const unsubscribe = () => {
    subscriptions.forEach(sub => emitter.off(sub.event, sub.callback));
  };

  useEffect(() => {
    subscriptions.forEach(sub => emitter.on(sub.event, sub.callback));
    return unsubscribe;
  }, [subscriptions.map(sub => sub.event).join(", "), ...(dependencies || [])]);

  return unsubscribe;
};

export const usePub = <T>() => {
  return (event: orderEvent, data: T) => {
    emitter.emit(event, data);
  };
};
