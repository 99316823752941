import { useMutation } from "@apollo/client";
import { MaterialsType, materialSchema } from "../../../materials/types";
import {
  ADD_MATERIALS,
  DELETE_MATERIALS,
  UPDATE_MATERIALS_BY_PK,
} from "../../../materials/gqls";
import { useScheme } from "../../../../../store/schemeStore";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { sort } from "fast-sort";
import { useState } from "react";
import { MATERIALS_CORE_FIELDS } from "../../../materials/fragments";
import {
  Badge,
  Button,
  Checkbox,
  Label,
  Select,
  Table,
  TextInput,
} from "flowbite-react";
import isNullish from "../../../../../utils/isNullish";
import {
  DocumentDuplicateIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { createPortal } from "react-dom";
import { useOrderStore } from "../../../../../store/orderStore";
import OptionCondition from "../optionCondition";
import Params from "./params";
import Prices from "./prices";

interface material {
  item: MaterialsType;
}

export default function Material({ item }: material) {
  const [update, { loading: updating }] = useMutation(UPDATE_MATERIALS_BY_PK);
  const { scheme } = useScheme();

  const methods = useForm({
    defaultValues: { ...item, productId: item.product.id },
    resolver: zodResolver(materialSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid, isDirty },
  } = methods;

  // if (!isValid && errors) {
  //   console.log(errors);
  // }

  const [remove, { loading: deleting }] = useMutation(DELETE_MATERIALS);

  const _delete = (id: number) => {
    const proceed = confirm("delete material?");
    if (!proceed) {
      return;
    }
    remove({
      variables: {
        id,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id,
          __typename: "materials",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const productId: number | null = useWatch({
    name: "productId",
    control,
  });

  const { productSets, productSetOptions, units } = useOrderStore();

  const productSet = productSets.find(p => p.product.id == productId);

  const productSetID = productSet?.id;

  const myProductSetOptions = productSetOptions.filter(
    o => o.productSetID == Number(productSetID)
  );

  const optionConditions =
    item.optionCondition &&
    sort(item.optionCondition)
      .asc("option")
      .map(option => {
        const populatedOption = myProductSetOptions?.find(
          o => o.id == Number(option.option)
        );
        let name = populatedOption?.name;
        if (option.group) name = option.group;

        return {
          id: option.option,
          name,
          value: option.displayValue || option.value,
          reverse: option.reverse,
        };
      });

  const optionCodes = myProductSetOptions
    ?.filter(option => option.optionCode && option.source == "inventory")
    .map(option => option.optionCode)
    .reduce<string[]>((prev, cur) => {
      if (!cur || prev.includes(cur)) {
        return prev;
      }
      return prev.concat(cur);
    }, []);

  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const [insert, { loading: inserting }] = useMutation(ADD_MATERIALS);

  const copy = handleSubmit(data => {
    const proceed = confirm(
      `copy current material${isDirty ? " with modified values" : ""}?`
    );
    if (!proceed) {
      return;
    }

    insert({
      variables: {
        object: {
          ...data,
          id: undefined,
          productId: data.productId == 0 ? null : data.productId,
          optionCode: data.optionCode == "" ? null : data.optionCode,
        },
      },
      onCompleted() {
        reset();
        setEditing(false);
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            materials(existing = []) {
              const newRef = cache.writeFragment({
                data: data?.insert_materials_one,
                fragment: MATERIALS_CORE_FIELDS,
                fragmentName: "MaterialsCoreFields",
              });
              return [...existing, newRef];
            },
          },
        });
      },
    });
  });

  const save = handleSubmit(data => {
    update({
      variables: {
        id: item.id,
        set: {
          ...data,
          productId: data.product,
          product: undefined,
          optionCode: data.optionCode == "" ? null : data.optionCode,
        },
      },
      onCompleted() {
        reset({
          ...data,
        });
        setEditing(false);
      },
    });
  });

  const cancelEdit = () => {
    reset();
    setEditing(!editing);
  };

  const prices = () => {
    if (!item.prices) {
      return null;
    }

    return item.prices
      .filter(p => p.price !== 0)
      .map(p => `${p.price}/${units.find(u => u.id == p.unit)?.name}`)
      .join(", ");
  };

  return (
    <FormProvider {...methods}>
      <Table.Row key={item.id}>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500 w-4">
          {item.id}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {item.product?.name}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {item.brand}
        </Table.Cell>
        <Table.Cell
          className={`py-[5px] px-1 border-r-[1px] dark:border-gray-500 ${
            item.pending && "text-red-500"
          }`}
        >
          {item.name}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {item.color}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {isNullish(item.size, true) ? "" : item.size}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {isNullish(item.widthDeduction, true) ? "" : item.widthDeduction}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {isNullish(item.heightDeduction, true) ? "" : item.heightDeduction}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {item.code || ""}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {prices()}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {item.optionCode}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          <div className="flex flex-row gap-1">
            {optionConditions?.map((option, i) => (
              <Badge
                color={option.reverse ? "failure" : "purple"}
                key={`${option.name}${i}`}
              >
                {option.name}:{option.value}
              </Badge>
            ))}
            {item.params?.map((param, i) => (
              <Badge color="purple" key={`${param.name}${i}`}>
                {param.name}:{param.value}
              </Badge>
            ))}
          </div>
        </Table.Cell>
        <Table.Cell className="py-[5px] w-10">
          <div className="flex flex-row gap-3 w-full">
            <PencilIcon
              onClick={toggleEditing}
              className="w-5 text-grass cursor-pointer"
            />
            <DocumentDuplicateIcon
              onClick={copy}
              className="w-5 text-plum cursor-pointer"
            />
          </div>
        </Table.Cell>
      </Table.Row>
      {/* Edit Deduction */}
      {editing && (
        <>
          {createPortal(
            <div
              className={`${scheme} absolute w-screen h-screen top-0 left-0 p-10 backdrop-blur-xl flex flex-col gap-2 overflow-x-hidden`}
            >
              <h3>Edit Material</h3>
              <div className="flex flex-row flex-wrap gap-2 items-center">
                <Select sizing="sm" disabled>
                  <option>{item.product.name}</option>
                </Select>
                <TextInput
                  {...register("brand")}
                  sizing="sm"
                  placeholder="Brand"
                  className="flex-1 min-w-[100px]"
                  addon="Brand"
                />
                <TextInput
                  {...register("name")}
                  sizing="sm"
                  placeholder="Name"
                  className="flex-1 min-w-[100px]"
                  addon="Name"
                />
                <TextInput
                  {...register("color")}
                  sizing="sm"
                  placeholder="color"
                  className="flex-1 min-w-[100px]"
                  addon="Color"
                />
                <TextInput
                  {...register("size")}
                  sizing="sm"
                  placeholder="size"
                  className="flex-1 min-w-[100px]"
                  addon="Size"
                />
                <TextInput
                  {...register("code")}
                  sizing="sm"
                  placeholder="Item Code"
                  className="flex-1 min-w-[100px]"
                  addon="Code"
                />
                <Select
                  {...register("optionCode")}
                  sizing="sm"
                  addon="OC"
                  placeholder="option code"
                  className="w-48"
                >
                  <option value="">None</option>
                  {optionCodes?.map(optionCode => (
                    <option key={optionCode}>{optionCode}</option>
                  ))}
                </Select>
                <TextInput
                  {...register("widthDeduction")}
                  sizing="sm"
                  type="number"
                  placeholder="Width"
                  className="flex-1 min-w-[100px]"
                  addon="W"
                />
                <TextInput
                  {...register("heightDeduction")}
                  sizing="sm"
                  type="number"
                  placeholder="Height"
                  className="flex-1 min-w-[100px]"
                  addon="H"
                />
                <Prices mat={item} />
              </div>
              <div className="flex flex-row justify-end flex-wrap gap-2 items-center">
                <Label value="Registration Pending" />
                <Checkbox
                  className="checked:bg-plum dark:checked:bg-plum"
                  {...register("pending")}
                />
                <Label value="Selectable" />
                <Checkbox
                  className="checked:bg-plum dark:checked:bg-plum"
                  {...register("selectable")}
                />
              </div>
              <hr />
              <h4 className="font-semibold dark:text-white">Params</h4>
              <Params />
              <hr />
              {productSetID && (
                <>
                  <h4 className="font-semibold dark:text-white">
                    Option Conditions
                  </h4>
                  <OptionCondition productSetID={productSetID} canReverse />
                  <hr />
                </>
              )}
              <div className="flex flex-row justify-between items-center gap-2">
                <Button
                  size="xs"
                  gradientDuoTone="purpleToBlue"
                  outline
                  onClick={copy}
                >
                  Copy
                </Button>
                <div className="flex flex-row items-center gap-2">
                  <TrashIcon
                    onClick={() => {
                      _delete(item.id);
                    }}
                    className="w-5 text-red-500 cursor-pointer"
                  />
                  <Button size="xs" color="purple" onClick={cancelEdit}>
                    cancel
                  </Button>
                  <Button
                    size="xs"
                    gradientDuoTone="purpleToBlue"
                    outline
                    onClick={save}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>,
            document.body
          )}
        </>
      )}
    </FormProvider>
  );
}
