import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import Option from "./option/option";
import { itemWarning, layoutCode, option, order } from "../../../types";
import { layoutConfig } from "../../../../types";
import { memo, useEffect } from "react";
import { sort } from "fast-sort";
import { useOrderStore } from "../../../../../../../store/orderStore";

interface props {
  locationIndex: number;
  openingIndex: number;
  itemIndex: number;
  layoutFull: layoutCode | undefined;
  layout: layoutConfig | undefined;
  warnings?: itemWarning[];
  openingId: string;
  locked?: boolean;
}

const Options = ({
  locationIndex,
  openingIndex,
  itemIndex,
  layout,
  layoutFull,
  warnings,
  openingId,
  locked,
}: props) => {
  const { control } = useFormContext<order>();
  const { fields, replace } = useFieldArray({
    control,
    name: `locations.${locationIndex}.openings.${openingIndex}.items.${itemIndex}.options`,
    keyName: "fid",
  });

  const productId = useWatch({
    control,
    name: `locations.${locationIndex}.openings.${openingIndex}.product`,
    exact: true,
  });

  const { productSetOptions } = useOrderStore();
  const productOptions = productSetOptions.filter(
    po => po.productSetID == productId
  );

  useEffect(() => {
    if (locked) {
      return;
    }

    const options = productOptions;
    const newOptions = options?.map(o => ({
      ...o,
      value: o.value || o.default || "",
    }));

    const formOptions: option[] = fields;

    const isProductChange =
      formOptions.length == 0 || productId != formOptions[0].productSetID;

    if (isProductChange) {
      return replace(sort(newOptions as option[]).asc(["rowNum", "position"]));
    }

    const replaceCheck = formOptions.map(opt =>
      newOptions?.find(o => o.id == opt.id)
    );

    if (replaceCheck.includes(undefined)) {
      return replace(
        sort(formOptions as option[])
          .asc(["rowNum", "position"])
          .filter(o => newOptions.find(n => n.id == o.id))
      );
    }

    const keysToCompare = [
      "allowCustomInput",
      "conditions",
      "displayName",
      "name",
      "group",
      "rowNum",
      "nameScope",
      "modifiers",
      "optionCode",
      "source",
    ];

    const updatedOptions = formOptions
      .map(option => {
        const newOption = newOptions.find(f => f.id == option.id);
        if (!newOption) {
          return null;
        }
        const isUpdated = keysToCompare.some(key => {
          // @ts-expect-error
          return JSON.stringify(newOption[key]) !== JSON.stringify(option[key]);
        });

        if (isUpdated) {
          const {
            allowCustomInput,
            conditions,
            displayName,
            name,
            group,
            rowNum,
            nameScope,
            modifiers,
            optionCode,
            source,
          } = newOption;

          return {
            ...option,
            allowCustomInput,
            conditions,
            displayName,
            name,
            group,
            rowNum,
            nameScope,
            modifiers,
            optionCode,
            source,
          };
        }

        return null;
      })
      .filter(uo => uo !== null);

    if (updatedOptions.length > 0) {
      console.log("updated option replace");
      return replace(
        formOptions.map(fo => {
          const updatedOption = updatedOptions.find(uo => uo?.id == fo.id);
          if (updatedOption) {
            return updatedOption;
          }
          return fo;
        })
      );
    }
  }, [productId, locked]);

  return (
    <>
      {fields.map((option, index) => {
        return (
          <Option
            key={option.fid}
            option={option}
            locationIndex={locationIndex}
            openingIndex={openingIndex}
            openingId={openingId}
            itemIndex={itemIndex}
            index={index}
            layout={layout}
            layoutFull={layoutFull}
            warnings={warnings?.filter(warning => warning.option == option.id)}
            locked={locked}
          />
        );
      })}
    </>
  );
};

export default memo(Options);
