import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/Root";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";
import "./index.css";
import axios from "axios";
import { CookiesProvider } from "react-cookie";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  split,
} from "@apollo/client";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "@apollo/client/link/context";
const hasuraAPI = import.meta.env.VITE_GRAPHQL_API;
const hasuraWS = import.meta.env.VITE_GRAPHQL_WS;
import {
  offsetLimitPagination,
  concatPagination,
} from "@apollo/client/utilities";
import { uniqueByRef } from "./utils/arrayObjMethods";
import * as Sentry from "@sentry/react";
const ISDEV = import.meta.env.DEV;

// !ISDEV &&
//   Sentry.init({
//     dsn: "https://e077e6723d4241c1a63b668f25595c3f@o1183690.ingest.sentry.io/4504743015350272",

//     // When using the plugin, either remove the `release`` property here entirely or
//     // make sure that the plugin's release option and the Sentry.init()'s release
//     // option match exactly.
//     // release: "my-example-release-1"
//   });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them

  const token = sessionStorage.getItem("accessToken");

  if (token) {
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  }
});

// HTTP Link
const httpLink = new HttpLink({
  uri: hasuraAPI,
});

// WebSocket Link
const wsLink = new WebSocketLink({
  uri: hasuraWS,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: async () => {
      const token = sessionStorage.getItem("accessToken");
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    },
  },
});

// Send query request based on the type definition
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  //@ts-expect-error
  wsLink,
  authLink.concat(httpLink)
);

// Apollo Client
export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          process: {
            ...offsetLimitPagination(["where", "order_by"]),
            read(existing = []) {
              return uniqueByRef(existing);
            },
          },
          process_stream: {
            ...offsetLimitPagination(["where", "order_by"]),
            read(existing = []) {
              return uniqueByRef(existing);
            },
          },
          contacts: {
            ...offsetLimitPagination(["where"]),
            read(existing = []) {
              return uniqueByRef(existing);
            },
          },
          contacts_stream: {
            ...offsetLimitPagination(["where"]),
            read(existing = []) {
              return uniqueByRef(existing);
            },
          },
          tasks: {
            ...concatPagination(["where"]),
            read(existing = []) {
              return uniqueByRef(existing);
            },
          },
          tasks_stream: {
            ...offsetLimitPagination(["where"]),
          },
          records: {
            ...offsetLimitPagination(["where"]),
            read(existing = []) {
              return uniqueByRef(existing);
            },
          },
          records_stream: {
            ...offsetLimitPagination(["where"]),
          },
          productions: {
            ...offsetLimitPagination(["where"]),
            read(existing = []) {
              return uniqueByRef(existing);
            },
          },
          productions_stream: {
            ...offsetLimitPagination(["where"]),
            read(existing = []) {
              return uniqueByRef(existing);
            },
          },
          workorder: {
            ...offsetLimitPagination(["where"]),
            read(existing = []) {
              return uniqueByRef(existing);
            },
          },
          workorder_stream: {
            ...offsetLimitPagination(["where"]),
            read(existing = []) {
              return uniqueByRef(existing);
            },
          },
        },
      },
    },
  }),
});

axios.defaults.withCredentials = true;
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.credentials = "include";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Root />,
  },
]);

root.render(
  <CookiesProvider>
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </CookiesProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
