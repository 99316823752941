export enum orderEvent {
  OPTION_UPDATE = "OPTION_UPDATE",
  MAT_UPDATE = "MAT_UPDATE",
  CALC_UPDATE = "CALC_UPDATE",
  NO_CALC_UPDATE = "NO_CALC_UPDATE",
  SIZE_UPDATE = "SIZE_UPDATE",
  INSTALL_PARAM_UPDATE = "INSTALL_PARAM_UPDATE",
  SALES_PARAM_UPDATE = "SALES_PARAM_UPDATE",
  QUOTE_OPTION_UPDATE = "QUOTE_OPTION_UPDATE",
  ITEM_SELECT = "ITEM_SELECT",
  OPENING_SELECT = "OPENING_SELECT",
  LOCATION_SELECT = "LOCATION_SELECT",
  PRICE_UPDATE = "PRICE_UPDATE",
}

export interface orderEventArg {
  orderEvent: orderEvent;
}

export interface OPTION_UPDATE_ARGS extends orderEventArg {
  orderEvent: orderEvent.OPTION_UPDATE;
  openingId: string;
  itemId?: string;
  optionId: number;
}

export interface NO_CALC_UPDATE_ARGS extends orderEventArg {
  orderEvent: orderEvent.NO_CALC_UPDATE;
  openingId: string;
  itemId?: string;
  optionId: number;
}

export interface MAT_UPDATE_ARGS extends orderEventArg {
  orderEvent: orderEvent.MAT_UPDATE;
  openingId: string;
  itemId?: string;
}

export interface CALC_UPDATE_ARGS extends orderEventArg {
  orderEvent: orderEvent.CALC_UPDATE;
  openingId: string;
  itemId?: string;
}

export interface SIZE_UPDATE_ARGS extends orderEventArg {
  orderEvent: orderEvent.SIZE_UPDATE;
  openingId: string;
  itemId?: string;
}

export interface QUOTE_OPTION_UPDATE_ARGS extends orderEventArg {
  orderEvent: orderEvent.QUOTE_OPTION_UPDATE;
  openingId: string;
  itemId?: string;
}

export interface INSTALL_PARAM_UPDATE_ARGS extends orderEventArg {
  orderEvent: orderEvent.INSTALL_PARAM_UPDATE;
  openingId: string;
  itemId?: string;
}

export interface SALES_PARAM_UPDATE_ARGS extends orderEventArg {
  orderEvent: orderEvent.SALES_PARAM_UPDATE;
  productSetId: number;
}

export interface ITEM_SELECT_ARGS extends orderEventArg {
  orderEvent: orderEvent.ITEM_SELECT;
  openingId: string;
}

export interface OPENING_SELECT_ARGS extends orderEventArg {
  orderEvent: orderEvent.OPENING_SELECT;
  locationId: string;
}

export interface LOCATION_SELECT_ARGS extends orderEventArg {
  orderEvent: orderEvent.LOCATION_SELECT;
}

export interface PRICE_UPDATE_ARGS extends orderEventArg {
  orderEvent: orderEvent.PRICE_UPDATE;
}
