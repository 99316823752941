import { Controller, useFormContext } from "react-hook-form";
import {
  OPTION_UPDATE_ARGS,
  orderEvent,
} from "../../../../../../../../utils/pubsub/orderEventArgs";
import { usePub } from "../../../../../../../../utils/pubsub/pubsub";
import { TextInput } from "flowbite-react";
import { useEffect, useState } from "react";

export default function TextComp({
  optionValueCoord,
  optionType,
  openingId,
  optionId,
}: {
  optionValueCoord: string;
  optionType: string;
  optionName: string;
  openingId: string;
  optionId: number;
}) {
  const { control, getValues } = useFormContext();

  const publish = usePub<OPTION_UPDATE_ARGS>();
  const [term, setTerm] = useState(getValues(optionValueCoord));
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) return;
    const handler = setTimeout(() => {
      publish(orderEvent.OPTION_UPDATE, {
        orderEvent: orderEvent.OPTION_UPDATE,
        openingId,
        optionId,
      });
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [term]);

  return (
    <Controller
      name={optionValueCoord}
      control={control}
      render={({ field }) => (
        <TextInput
          {...field}
          onChange={e => {
            field.onChange(e.target.value);
            setLoaded(true);
            setTerm(e.target.value);
          }}
          className={`${optionType == "number" ? "w-28" : "w-28"}`}
          type={optionType == "number" ? "number" : "text"}
          sizing="sm"
          onKeyDown={e => {
            if (e.key == "ArrowUp" || e.key == "ArrowDown") e.preventDefault();
          }}
        />
      )}
    />
  );
}
