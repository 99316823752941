import { useFormContext, useWatch } from "react-hook-form";
import { layoutConfig } from "../../../../../types";
import { Tooltip } from "flowbite-react";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/solid";
import {
  itemWarning,
  calc,
  layoutCode,
  location,
  option,
} from "../../../../types";
import { memo, useEffect, useState } from "react";
import SelectComp from "./selectComps/select";
import isNullish from "../../../../../../../../utils/isNullish";
import { sort } from "fast-sort";
import { myDeepEqual } from "../../../../../../../../utils/arrayObjMethods";
import OptionsSideEffects from "./optionSideEffects";
import TextComp from "./TextComp";

interface optProps {
  locationIndex: number;
  openingIndex: number;
  openingId: string;
  itemIndex: number;
  option: option;
  index: number;
  layoutFull: layoutCode | undefined;
  layout: layoutConfig | undefined;
  warnings?: itemWarning[];
  locked?: boolean;
}

const Option = ({
  locationIndex,
  openingIndex,
  openingId,
  itemIndex,
  index,
  layoutFull,
  layout,
  warnings,
  option,
  locked,
}: optProps) => {
  const { control, setValue, getValues } = useFormContext();

  const optionCoord = `locations.${locationIndex}.openings.${openingIndex}.items.${itemIndex}.options.${index}`;

  const optionValue = useWatch({
    control,
    name: `${optionCoord}.value`,
  });

  const itemCoord = `locations.${locationIndex}.openings.${openingIndex}.items.${itemIndex}`;

  const optionsCoord = `locations.${locationIndex}.openings.${openingIndex}.items.${itemIndex}.options`;

  const showOptionals = useWatch({
    name: "showOptionals",
    control,
    exact: true,
  });

  const product: number = useWatch({
    name: `locations.${locationIndex}.openings.${openingIndex}.product`,
    control,
    exact: true,
  });

  const applyAll = () => {
    const proceed = confirm("apply option to all items?");
    if (!proceed) {
      return;
    }

    const allLocation: location[] = getValues("locations");

    setValue(
      "locations",
      allLocation.map(l => ({
        ...l,
        openings: l.openings.map(opening => ({
          ...opening,
          items: opening.items.map(item => ({
            ...item,
            options: item.options.map(opt => {
              if (opt.name == option?.name && opening.product == product) {
                return { ...opt, value: optionValue };
              } else {
                return opt;
              }
            }),
          })),
        })),
      }))
    );
  };

  const disabled = () => {
    if (locked) {
      return true;
    }
    if (!layout) {
      return false;
    }

    for (const optionConfig of layout.config) {
      if (option.id === Number(optionConfig.option)) {
        return true;
      }
    }

    if (layoutFull?.layout?.parent) {
      for (const po of layoutFull.layout.parent) {
        if (option.id === Number(po.option)) {
          return true;
        }
      }
    }

    return false;
  };

  const [focus, setFocus] = useState(false);

  const parentCheck = !useWatch({
    name: `${optionCoord}.noCalc`,
  });

  const name = isNullish(option.displayName)
    ? option.name
    : (option.displayName as string);

  // const [lineBreak, setLineBreak] = useState(false);

  useEffect(() => {
    if (locked) {
      return;
    }
    if (option.source !== "deductionPreset") {
      return;
    }

    const currentCalcs: calc[] = getValues(`${itemCoord}.calcs`);

    const calcs = currentCalcs || [];

    const newCalcs = sort(
      calcs.filter(c => Number(c.optionId) !== Number(option.id))
    ).asc("name");

    // @ts-ignore
    if (!parentCheck && !myDeepEqual(calcs, newCalcs)) {
      setValue(`${itemCoord}.calcs`, newCalcs);
    }
  }, [parentCheck]);

  const isWarningPresent = warnings && warnings.length > 0;

  const myWarnings =
    warnings
      ?.map(
        warning =>
          `${warning?.presetOption ? `${warning?.presetOption}: ` : ""}${
            warning?.value
          }`
      )
      .join("\n") || "";

  const optionId: number = option.id;
  const itemId: string = getValues(
    `locations.${locationIndex}.openings.${openingIndex}.items.${itemIndex}.id`
  );

  return (
    <>
      {parentCheck && option.name.toLowerCase() !== "note" && (
        <>
          {/* {lineBreak && <div className="basis-full h-0" />} */}
          <Tooltip
            className={`${isWarningPresent ? "" : "hidden"}`}
            content={<pre>{myWarnings}</pre>}
          >
            <div
              className={`relative group/option my-[5px] mr-1 ${
                !showOptionals && option.optional && "hidden"
              } ${isWarningPresent && "ring-rose-300 ring-1 rounded-md"}`}
              style={{
                minWidth: `${name.length * 10}px`,
              }}
              onBlur={() => {
                setFocus(false);
              }}
              onFocus={() => {
                setFocus(true);
              }}
            >
              <div
                className={`text-grass w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none ${
                  (optionValue == undefined || optionValue == "") &&
                  !option.disableAutoSelect &&
                  !locked &&
                  !focus
                    ? "left-2 top-2"
                    : "left-[50%] -translate-x-[50%] -top-[8px]"
                }`}
              >
                {name}
              </div>
              {option.type !== "select" ? (
                <TextComp
                  optionValueCoord={`${optionCoord}.value`}
                  optionType={option.type}
                  optionName={name}
                  openingId={openingId}
                  itemId={itemId}
                  optionId={optionId}
                  locked={locked}
                />
              ) : (
                <>
                  <SelectComp
                    optionName={option.name}
                    disabled={disabled()}
                    productIndex={`locations.${locationIndex}.openings.${openingIndex}.product`}
                    itemCoord={itemCoord}
                    optionCoord={optionCoord}
                    optionsCoord={optionsCoord}
                    openingId={openingId}
                    itemId={itemId}
                    optionId={optionId}
                    option={option}
                    locked={locked}
                  />
                </>
              )}
              <div
                className="absolute right-1 top-1 hidden group-hover/option:block cursor-pointer hover:text-grass"
                onClick={applyAll}
              >
                <ClipboardDocumentListIcon className="w-5" />
              </div>
            </div>
          </Tooltip>
        </>
      )}
      <OptionsSideEffects
        optionCoord={optionCoord}
        optionsCoord={optionsCoord}
        // setLineBreak={setLineBreak}
        // lineBreak={lineBreak}
        // index={index}
        locked={locked}
        openingId={openingId}
        itemId={itemId}
        optionId={optionId}
      />
    </>
  );
};

export default memo(Option);
