import { useQuery } from "@apollo/client";
import { GET_PRODUCT_IMAGES_BY_PID } from "../../../../gqls";
import { useFormContext } from "react-hook-form";
import { option } from "../../../types";
import { useSub } from "../../../../../../../utils/pubsub/pubsub";
import {
  OPTION_UPDATE_ARGS,
  orderEvent,
} from "../../../../../../../utils/pubsub/orderEventArgs";
import { useDebouncedCallback } from "../../../../../../../utils/useDebounceCallback";

interface itemImage {
  itemCoord: string;
  productId?: number;
  itemId: string;
}

const ItemImage = ({ itemCoord, productId, itemId }: itemImage) => {
  const { data: data_image } = useQuery(GET_PRODUCT_IMAGES_BY_PID, {
    variables: {
      id: productId,
    },
  });

  const { setValue, getValues } = useFormContext();

  const updateImage = () => {
    const optionsCoord = `${itemCoord}.options`;
    const options: option[] = getValues(optionsCoord);

    const myImage = data_image?.productImages?.find(p => {
      const optionTest = p.options.every(o => {
        const where = o.option;
        const values = o.values?.map(v => v.toString());
        if (!where || !values) {
          return false;
        }

        const matchingOption = options?.find(o => o.id == where);

        if (!matchingOption) {
          return false;
        }
        if (matchingOption.noCalc) {
          return true;
        }
        return values.includes(matchingOption.value?.toString());
      });

      return optionTest;
    });

    setValue(`${itemCoord}.image`, myImage);
  };

  const debouncedUpdateImage = useDebouncedCallback(updateImage, 300);

  useSub(orderEvent.OPTION_UPDATE, (args: OPTION_UPDATE_ARGS) => {
    if (args.itemId == itemId) {
      debouncedUpdateImage();
    }
  });

  return null;
};

export default ItemImage;
