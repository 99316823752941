import { useFormContext, useWatch } from "react-hook-form";
import { useOrderStore } from "../../../../../../../../store/orderStore";
import { useEffect, useRef, useState } from "react";
import { openingScope } from "../../../../types";
import { myDeepEqual } from "../../../../../../../../utils/arrayObjMethods";

interface seProps {
  optionCoordChained: string;
  optionsCoord: string;
  optionValue: any;
  openingId: string;
  optionId: number;
}

const OptionSideEffects = ({ optionCoordChained, optionValue }: seProps) => {
  const { setValue, control, getValues } = useFormContext();

  const source = useWatch({
    control,
    name: `${optionCoordChained}.source`,
    exact: true,
  });

  const { deductions } = useOrderStore();

  const [current, setCurrent] = useState<any>();

  const isMounted = useRef(false);

  const [count, setCount] = useState(0);

  useEffect(() => {
    const openingCoord = optionCoordChained.split(".").slice(0, -2).join(".");
    const calcs = getValues(`${openingCoord}.calcs`);
    if (
      !isMounted.current &&
      calcs &&
      Array.isArray(calcs) &&
      calcs.length > 0
    ) {
      isMounted.current = true;
      return;
    }

    isMounted.current = true;

    if (JSON.stringify(current) == JSON.stringify(optionValue)) {
      return;
    }
    setCurrent(optionValue);

    const currentScope = getValues(`${optionCoordChained}.scope`);

    if (!optionValue) {
      if (count == 0) {
        return setCount(state => state + 1);
      }
      if (currentScope !== undefined) {
        setValue(`${optionCoordChained}.scope`, undefined);
        // console.log("hello1", currentScope, optionValue);
      }
      return;
    }
    let scope: openingScope | undefined = undefined;

    if (source == "deductions") {
      const deduction = deductions?.find(
        d => d.id.toString() == optionValue.toString()
      );
      if (deduction) {
        scope = {
          value: deduction.name,
          width: deduction.width,
          height: deduction.height,
          additionalValue: deduction.additionalValue,
        };
      }
    } else {
      scope = optionValue;
    }

    if (!myDeepEqual(currentScope, scope as object)) {
      setValue(`${optionCoordChained}.scope`, scope);
      // console.log("hello2", currentScope, scope);
    }
  }, [optionValue]);

  return null;
};

export default OptionSideEffects;
