import { useMutation, useQuery } from "@apollo/client";
import {
  DocumentDuplicateIcon,
  MagnifyingGlassIcon,
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Badge, Button, Select, Table, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { sort } from "fast-sort";
import { useScheme } from "../../../../store/schemeStore";
import {
  ADD_DEDUCTION_PRESET,
  DELETE_DEDUCTION_PRESET,
  GET_DEDUCTIONS_BY_IDS,
  GET_DEDUCTIONS_CONDITIONAL,
  GET_DEDUCTION_PRESETS,
  GET_PRODUCT_OPTIONS_CONDITIONAL,
  GET_PRODUCT_SETS,
  UPDATE_DEDUCTION_PRESET_BY_PK,
} from "../../workorder/gqls";
import { createPortal } from "react-dom";
import {
  DeductionPresetType,
  DeductionType,
  deductionPresetScheme,
} from "../../workorder/types";
import { DEDUCTION_PRESET_CORE_FIELDS } from "../../workorder/fragments";
import { useOrderStore } from "../../../../store/orderStore";
import Formulas from "./Formulas";
import isNullish from "../../../../utils/isNullish";

type newDeductionPresetType = Omit<DeductionPresetType, "id" | "productSet">;

export default function DeductionPresets() {
  const { data: data_presets } = useQuery(GET_DEDUCTION_PRESETS);
  const [filter, setFilter] = useState("");
  const presets = data_presets
    ? data_presets.deductionPreset.filter(
        preset =>
          filter.trim() == "" ||
          `${preset.name} ${preset.productSet.name} ${preset.optionCode} ${preset.desc}`
            .toLowerCase()
            .includes(filter.trim().toLocaleLowerCase())
      )
    : [];

  const { data: data_products } = useQuery(GET_PRODUCT_SETS);

  const [insert, { loading: inserting }] = useMutation(ADD_DEDUCTION_PRESET);

  const defaultValues: newDeductionPresetType = {
    deductions: [],
    name: "",
    for: 0,
    formulas: [],
    optionCode: "",
    optionCondition: [],
    labour: "",
    desc: "",
    position: undefined,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(deductionPresetScheme),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  const productSetID: number | null = useWatch({
    name: "for",
    control,
  });

  // console.log(errors);

  const { data: data_options } = useQuery(GET_PRODUCT_OPTIONS_CONDITIONAL, {
    variables: {
      where: {
        productSetID: { _eq: productSetID },
      },
    },
  });

  const productSetOptions = data_options?.productSetOptions;

  const optionCodes = productSetOptions
    ?.filter(option => option.optionCode && option.source == "deductionPreset")
    .map(option => option.optionCode)
    .reduce<string[]>((prev, cur) => {
      if (!cur || prev.includes(cur)) {
        return prev;
      }
      return prev.concat(cur);
    }, []);

  const optionCode = useWatch({
    name: "optionCode",
    control,
  });

  const onSubmit = handleSubmit(async data => {
    insert({
      variables: {
        object: {
          ...data,
          optionCode: data.optionCode == "" ? null : data.optionCode,
        },
      },
      onCompleted() {
        reset();
        setAdding(false);
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            deductionPreset(existing = []) {
              const newRef = cache.writeFragment({
                data: data?.insert_deductionPreset_one,
                fragment: DEDUCTION_PRESET_CORE_FIELDS,
                fragmentName: "DeductionPresetCoreFields",
              });
              return [...existing, newRef];
            },
          },
        });
      },
    });
  });

  const [adding, setAdding] = useState(false);
  const toggleAdding = () => {
    setAdding(!adding);
  };

  const cancel = () => {
    reset();
    setAdding(!adding);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-end items-center gap-2">
        <TextInput
          onChange={e => setFilter(e.target.value)}
          value={filter}
          placeholder="search"
          type="search"
          name="searchMaterial"
          autoComplete="off"
          icon={MagnifyingGlassIcon}
          sizing="sm"
        />
        {!adding && (
          <Button
            gradientDuoTone="purpleToBlue"
            outline
            size="xs"
            onClick={toggleAdding}
          >
            new
          </Button>
        )}
      </div>
      {adding && (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className="flex flex-col mb-2 gap-2">
            <div className="flex flex-row flex-wrap gap-2 items-center justify-between">
              <div className="flex flex-row gap-2 items-center">
                <Select {...register("for")} sizing="sm">
                  <option value={0}>Select product</option>
                  {data_products?.productSets.map(p => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </Select>
                <TextInput
                  {...register("name")}
                  sizing="sm"
                  placeholder="Name"
                  className="min-w-[100px]"
                />
              </div>
              <div className="flex flex-row gap-2 items-center">
                <Select
                  {...register("optionCode")}
                  sizing="sm"
                  addon="OC"
                  placeholder="option code"
                >
                  <option value="">None</option>
                  {optionCodes?.map(optionCode => (
                    <option key={optionCode}>{optionCode}</option>
                  ))}
                </Select>
                {!isNullish(optionCode) && (
                  <TextInput
                    addon="#"
                    {...register("position")}
                    sizing="sm"
                    className="w-[80px]"
                  />
                )}
              </div>
            </div>
            <TextInput
              addon="Labour"
              {...register("labour")}
              sizing="sm"
              placeholder="Labour cost formula"
              className="min-w-[100px]"
            />
            <TextInput
              addon="Desc"
              {...register("desc")}
              sizing="sm"
              placeholder="Description"
              className="min-w-[100px]"
            />
            {productSetID ? (
              <>
                <Deductions />
                <hr />
                <h4 className="font-semibold dark:text-white">
                  Option Conditions
                </h4>
                <OptionCondition productSetID={productSetID} />
                <hr />
                <h4 className="font-semibold dark:text-white">Formulas</h4>
                <Formulas />
                <hr />
              </>
            ) : null}
            <div className="flex flex-row justify-end items-center gap-2">
              <Button size="xs" color="purple" onClick={cancel}>
                cancel
              </Button>
              <Button
                size="xs"
                gradientDuoTone="purpleToBlue"
                outline
                type="submit"
              >
                Add
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      <Table striped className="select-text">
        <Table.Head>
          <Table.HeadCell className="px-1">id</Table.HeadCell>
          <Table.HeadCell className="px-1">product</Table.HeadCell>
          <Table.HeadCell className="px-1">name</Table.HeadCell>
          <Table.HeadCell className="px-1">labour</Table.HeadCell>
          <Table.HeadCell className="px-1">desc</Table.HeadCell>
          <Table.HeadCell className="px-1">optionCode</Table.HeadCell>
          {/* <Table.HeadCell className="px-1">Option Conditions</Table.HeadCell> */}
          <Table.HeadCell className="px-1">W</Table.HeadCell>
          <Table.HeadCell className="px-1">H</Table.HeadCell>
          <Table.HeadCell className="p-0" />
        </Table.Head>
        <Table.Body className="text-black dark:text-white">
          {presets &&
            sort(presets)
              .by([
                { asc: d => d.productSet.name },
                { asc: d => d.optionCode },
                { asc: d => d.name },
              ])
              .map(item => <Preset key={item.id} item={item} />)}
        </Table.Body>
      </Table>
    </div>
  );
}

interface preset {
  item: DeductionPresetType;
}

const Preset = ({ item }: preset) => {
  const { data: data_products } = useQuery(GET_PRODUCT_SETS);
  const products = data_products?.productSets;
  const [update, { loading: updating }] = useMutation(
    UPDATE_DEDUCTION_PRESET_BY_PK
  );
  const { scheme } = useScheme();

  const methods = useForm({
    defaultValues: { ...item, productSet: undefined },
    resolver: zodResolver(deductionPresetScheme),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid, isDirty },
  } = methods;

  // if (!isValid && errors) {
  //   console.log(errors);
  // }

  const { data, error } = useQuery(GET_DEDUCTIONS_BY_IDS, {
    variables: {
      ids: item.deductions,
    },
  });

  const deductions =
    data?.deductions &&
    item?.deductions.map(s => data?.deductions.find(d => d.id == s));

  const widthD = deductions?.reduce<number>((prv, cur) => {
    if (!cur) {
      return prv;
    }
    return (
      prv +
      (cur.width || cur.inventory?.widthDeduction || 0) +
      (cur.widthAddition || 0) +
      (cur.parentDeduction?.width ||
        cur.parentDeduction?.inventory?.widthDeduction ||
        0) +
      (cur.parentDeduction?.widthAddition || 0)
    );
  }, 0);

  const heightD = deductions?.reduce<number>((prv, cur) => {
    if (!cur) {
      return prv;
    }
    return (
      prv +
      (cur.height || cur.inventory?.heightDeduction || 0) +
      (cur.heightAddition || 0) +
      (cur.parentDeduction?.height ||
        cur.parentDeduction?.inventory?.heightDeduction ||
        0) +
      (cur.parentDeduction?.heightAddition || 0)
    );
  }, 0);

  const [remove, { loading: deleting }] = useMutation(DELETE_DEDUCTION_PRESET);

  const _delete = (id: number) => {
    const proceed = confirm("delete deduction Preset?");
    if (!proceed) {
      return;
    }
    remove({
      variables: {
        id,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id,
          __typename: "deductionPreset",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const productSetID: number = useWatch({
    name: "for",
    control,
  });

  const { data: data_options } = useQuery(GET_PRODUCT_OPTIONS_CONDITIONAL, {
    variables: {
      where: {
        productSetID: { _eq: Number(productSetID) },
      },
    },
    onError(error) {
      console.log(error);
    },
  });

  const productSetOptions = data_options?.productSetOptions;

  // const optionConditions =
  //   item.optionCondition &&
  //   sort(item.optionCondition)
  //     .asc("option")
  //     .map(option => {
  //       const name = productSetOptions?.find(
  //         o => o.id == Number(option.option)
  //       )?.name;
  //       return {
  //         id: option.option,
  //         name,
  //         value: option.displayValue || option.value,
  //       };
  //     });

  const optionCodes = productSetOptions
    ?.filter(option => option.optionCode && option.source == "deductionPreset")
    .map(option => option.optionCode)
    .reduce<string[]>((prev, cur) => {
      if (!cur || prev.includes(cur)) {
        return prev;
      }
      return prev.concat(cur);
    }, []);

  const [editing, setEditing] = useState(false);

  const save = handleSubmit(data => {
    update({
      variables: {
        id: item.id,
        set: {
          ...data,
          optionCode: data.optionCode == "" ? null : data.optionCode,
        },
      },
      onCompleted() {
        reset({
          ...data,
        });
        setEditing(false);
      },
    });
  });

  const [insert, { loading: inserting }] = useMutation(ADD_DEDUCTION_PRESET);

  const copy = handleSubmit(data => {
    const proceed = confirm(
      `copy current preset${isDirty ? " with modified values" : ""}?`
    );
    if (!proceed) {
      return;
    }

    insert({
      variables: {
        object: {
          ...data,
          id: undefined,
          optionCode: data.optionCode == "" ? null : data.optionCode,
        },
      },
      onCompleted() {
        reset();
        setEditing(false);
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            deductionPreset(existing = []) {
              const newRef = cache.writeFragment({
                data: data?.insert_deductionPreset_one,
                fragment: DEDUCTION_PRESET_CORE_FIELDS,
                fragmentName: "DeductionPresetCoreFields",
              });
              return [...existing, newRef];
            },
          },
        });
      },
    });
  });

  const cancelEdit = () => {
    reset();
    setEditing(!editing);
  };

  const optionCode = useWatch({
    name: "optionCode",
    control,
  });

  return (
    <FormProvider {...methods}>
      <Table.Row key={item.id}>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500 w-4">
          {item.id}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {item.productSet.name}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {item.name}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500 truncate max-w-[40px]">
          {isNaN(Number(item.labour)) ? "f(x)" : item.labour}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {item.desc}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {item.optionCode}
        </Table.Cell>
        {/* <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          <div className="flex flex-row gap-1">
            {optionConditions?.map((option, i) => (
              <Badge color="purple" key={`${option.name}${i}`}>
                {option.name}:{option.value}
              </Badge>
            ))}
          </div>
        </Table.Cell> */}
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {widthD}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-1 border-r-[1px] dark:border-gray-500">
          {heightD}
        </Table.Cell>

        <Table.Cell className="py-[5px] w-10">
          <div className="flex flex-row gap-3 w-full">
            <PencilIcon
              onClick={() => {
                setEditing(true);
              }}
              className="w-5 text-grass cursor-pointer"
            />
            <DocumentDuplicateIcon
              onClick={copy}
              className="w-5 text-plum cursor-pointer"
            />
          </div>
        </Table.Cell>
      </Table.Row>
      {/* Edit Deduction */}
      {editing && (
        <>
          {createPortal(
            <div
              className={`${scheme} absolute w-screen h-screen top-0 left-0 p-10 backdrop-blur-xl flex flex-col gap-2 overflow-x-hidden`}
            >
              <h3>Edit Preset</h3>
              <div className="flex flex-row flex-wrap gap-2 items-center justify-between">
                <div className="flex flex-row gap-2 items-center">
                  <Select {...register("for")} sizing="sm">
                    <option value={""}>Select product</option>
                    {products?.map(p => (
                      <option key={p.id} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </Select>
                  <TextInput
                    {...register("name")}
                    sizing="sm"
                    placeholder="Name"
                    className="min-w-[100px]"
                  />
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <Select
                    {...register("optionCode")}
                    sizing="sm"
                    addon="OC"
                    placeholder="option code"
                    className="w-40"
                  >
                    <option value="">None</option>
                    {optionCodes?.map(optionCode => (
                      <option key={optionCode}>{optionCode}</option>
                    ))}
                  </Select>
                  {!isNullish(optionCode) && (
                    <TextInput
                      addon="#"
                      {...register("position")}
                      sizing="sm"
                      className="w-[80px]"
                    />
                  )}
                </div>
              </div>
              <TextInput
                addon="Labour"
                {...register("labour")}
                sizing="sm"
                placeholder="Labour cost formula"
                className="min-w-[100px]"
              />
              <TextInput
                addon="Desc"
                {...register("desc")}
                sizing="sm"
                placeholder="Description"
                className="min-w-[100px]"
              />

              <hr />
              {productSetID ? (
                <>
                  <Deductions />
                  <hr />
                  <h4 className="font-semibold dark:text-white">
                    Option Conditions
                  </h4>
                  <OptionCondition productSetID={productSetID} />
                  <hr />
                  <h4 className="font-semibold dark:text-white">Formulas</h4>
                  <Formulas />
                  <hr />
                </>
              ) : null}
              <div className="flex flex-row justify-between items-center gap-2">
                <Button
                  size="xs"
                  gradientDuoTone="purpleToBlue"
                  outline
                  onClick={copy}
                >
                  Copy
                </Button>
                <div className="flex flex-row items-center gap-2">
                  <TrashIcon
                    onClick={() => {
                      _delete(item.id);
                    }}
                    className="w-5 text-red-500 cursor-pointer"
                  />
                  <Button size="xs" color="purple" onClick={cancelEdit}>
                    cancel
                  </Button>
                  <Button
                    size="xs"
                    gradientDuoTone="purpleToBlue"
                    outline
                    onClick={save}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>,
            document.body
          )}
        </>
      )}
    </FormProvider>
  );
};

interface ocProps {
  productSetID: number;
}

const Deductions = () => {
  const { scheme } = useScheme();
  const { register, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: "deductions",
    control,
  });

  const productID = useWatch({
    name: "for",
    control,
  });

  const { data } = useQuery(GET_DEDUCTIONS_CONDITIONAL, {
    variables: {
      where: {
        productSetID: { _eq: productID },
      },
    },
  });

  const availableDeductions = data?.deductions;

  const deductions: number[] = useWatch({
    name: "deductions",
    control,
  });
  const populatedDeductions: (DeductionType | undefined)[] = deductions.map(d =>
    availableDeductions?.find(a => a.id == Number(d))
  );

  return (
    <div className={`${scheme} flex flex-col gap-2 flex-wrap`}>
      {/* New Preset */}
      <div className="flex flex-row gap-2 items-center">
        <Select
          addon="Deduction"
          sizing="sm"
          value={0}
          onChange={e => {
            append(Number(e.target.value));
          }}
        >
          <option value="">add deduction</option>
          {availableDeductions?.map(p => (
            <option key={p.id} value={p.id}>
              {p.name}
            </option>
          ))}
        </Select>
      </div>
      {populatedDeductions.length > 0 && (
        <div className="flex flex-col dark:text-white">
          <div className="bg-gray-200 dark:bg-gray-700 rounded-t-md flex flex-row gap-2 items-center">
            <div className="px-2 py-[5px] w-72">Deduction</div>
            <div className="px-2 py-[5px] flex-1">Width</div>
            <div className="px-2 py-[5px] w-16">Height</div>
            <div className="px-2 py-[5px] w-10" />
          </div>
          {populatedDeductions.map((item, index) => {
            if (!item) {
              return;
            }
            return (
              <div
                key={item.id}
                className="rounded-md flex flex-row gap-2 items-center"
              >
                <div className="px-2 py-[5px] w-72 overflow-clip">
                  {item.name}
                </div>
                <div className="px-2 py-[5px] flex-1">{item.width}</div>
                <div className="px-2 py-[5px] w-16">{item.height}</div>
                <div className="px-2 py-[5px] w-10 flex flex-row gap-2">
                  <MinusCircleIcon
                    className="text-red-500 w-5 cursor-pointer"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const OptionCondition = ({ productSetID }: ocProps) => {
  const { scheme } = useScheme();
  const { control } = useFormContext();
  const { append, remove } = useFieldArray({
    name: "optionCondition",
    control,
  });

  const { presets, deductions, materials, productSetOptions } = useOrderStore();

  const optionTypes = ["select", "text", "number"];
  const options = productSetOptions
    ? sort(productSetOptions.filter(o => o.productSetID == productSetID))
        .asc("position")
        .filter(o => optionTypes.includes(o.type))
    : [];

  const defaultValues: {
    option: number;
    value: any;
    displayValue?: string | number;
  } = {
    option: 0,
    value: "",
    displayValue: undefined,
  };

  const nullishValues = ["", null, 0];
  const [newCondition, setNewCondition] = useState(defaultValues);

  const selectedOption = options?.find(o => o.id == newCondition.option);
  const [selectables, setSelectables] = useState<any[]>([]);

  useEffect(() => {
    if (selectedOption?.source == "deductionPreset") {
      const _presets = presets.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_presets);
    }
    if (selectedOption?.source == "deductions") {
      const _deductions = deductions.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_deductions);
    }
    if (selectedOption?.source == "inventory") {
      const _materials = materials.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_materials);
    }
    if (!selectedOption?.source && selectedOption?.type == "select") {
      // @ts-ignore
      setSelectables(selectedOption.values);
    }
  }, [selectedOption]);

  const add = () => {
    if (
      nullishValues.includes(newCondition.option) ||
      nullishValues.includes(newCondition.value)
    ) {
      return;
    }
    append(newCondition);
    setNewCondition(defaultValues);
  };

  const addedConditions: any[] = useWatch({
    name: "optionCondition",
    control,
  });

  return (
    <div className={`${scheme} flex flex-col gap-2 flex-wrap`}>
      {/* New Condition */}
      <div className="flex flex-row gap-2 items-center">
        <Select
          addon="Option"
          sizing="sm"
          value={newCondition.option}
          onChange={e => {
            setNewCondition({
              ...newCondition,
              option: Number(e.target.value),
            });
          }}
        >
          <option value="">select option</option>
          {options?.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </Select>
        {selectedOption && selectedOption.type == "select" ? (
          <Select
            addon="Value"
            sizing="sm"
            value={newCondition.value}
            onChange={e => {
              const displayValue = selectables.find(
                s => s.id?.toString() == e.target.value.toString()
              )?.name;
              setNewCondition({
                ...newCondition,
                value: e.target.value,
                displayValue,
              });
            }}
          >
            <option value="">select option</option>
            {selectables?.map((option, i) => (
              <option key={option.id || i} value={option.id || option}>
                {option.name || option}
              </option>
            ))}
          </Select>
        ) : (
          <TextInput
            sizing="sm"
            addon="Value"
            placeholder="Enter Value"
            value={newCondition.value}
            onChange={e => {
              setNewCondition({ ...newCondition, value: e.target.value });
            }}
          />
        )}
        <PlusCircleIcon
          className="w-6 text-grass cursor-pointer"
          onClick={add}
        />
      </div>
      {/* conditions */}
      {addedConditions && addedConditions.length > 0 && (
        <div className="flex flex-row gap-2">
          {addedConditions.map((item, index) => (
            <Badge
              key={index}
              color="purple"
              className="cursor-pointer"
              onClick={() => {
                remove(index);
              }}
            >
              <div className="flex flex-row items-center">
                {options.find(o => o.id == Number(item.option))?.name}:{" "}
                {item.displayValue || item.value} <XMarkIcon className="w-4" />
              </div>
            </Badge>
          ))}
        </div>
      )}
    </div>
  );
};
