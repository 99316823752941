import { useFormContext } from "react-hook-form";
import { calc, inventory, option } from "../../../types";
import { useOrderStore } from "../../../../../../../store/orderStore";
import { create, all } from "mathjs";
import isNullish from "../../../../../../../utils/isNullish";
import { modifier } from "../../../../types";
import { sort } from "fast-sort";
import useScopeConcat from "../options/scopeConcat";
import {
  subscription,
  usePub,
  useSubs,
} from "../../../../../../../utils/pubsub/pubsub";
import {
  CALC_UPDATE_ARGS,
  MAT_UPDATE_ARGS,
  OPTION_UPDATE_ARGS,
  orderEvent,
  SIZE_UPDATE_ARGS,
} from "../../../../../../../utils/pubsub/orderEventArgs";
import { useDebouncedCallback } from "../../../../../../../utils/useDebounceCallback";
import { useCallback } from "react";

interface materialCalc {
  itemCoord: string;
  itemId: string;
  openingId: string;
  locked?: boolean;
}

export default function MaterialCalcs({
  itemCoord,
  itemId,
  openingId,
  locked,
}: materialCalc) {
  const math = create(all);

  math.SymbolNode.onUndefinedSymbol = () => {
    return 0;
  };

  const { evaluate } = math;

  const { setValue, getValues } = useFormContext();

  const optionsCoord = `${itemCoord}.options`;

  const calcQty = (f: string, scopes: any, logError?: boolean) => {
    try {
      return evaluate(f, scopes);
    } catch (error) {
      if (logError) {
        console.log(error);
      }
      return 0;
    }
  };
  const scopeConcat = useScopeConcat();
  const publish = usePub<MAT_UPDATE_ARGS>();

  const { presets: _cachedPresets, deductions: _cachedDeductions } =
    useOrderStore();

  const update = useCallback(() => {
    if (locked) return;
    const calcs: calc[] = getValues(`${itemCoord}.calcs`);
    const options: option[] = getValues(optionsCoord);

    const width = getValues(`${itemCoord}.width`);
    const height = getValues(`${itemCoord}.height`);

    const optionNamePopulatedCalcs = calcs.map(c => ({
      ...c,
      optionName: options.find(o => o.id == c.optionId)?.name || "",
    }));

    const presetIds =
      (options &&
        options
          .filter(o => o.source == "deductionPreset" && !o.noCalc)
          .reduce<number[]>((prev, cur) => {
            if (!cur.value) {
              return prev;
            } else {
              return prev.concat(Number(cur.value));
            }
          }, [])) ||
      [];

    const deductionPresets = _cachedPresets.filter(p =>
      presetIds.includes(p.id)
    );

    const deductionIds = deductionPresets.reduce<number[]>((prev, cur) => {
      return prev.concat(cur.deductions);
    }, []);

    const deductions = _cachedDeductions.filter(d =>
      deductionIds?.includes(d.id)
    );

    if (!deductions || !options) {
      return;
    }

    const calcScopes = optionNamePopulatedCalcs.reduce<any>(
      (prv, cur) => {
        return {
          ...prv,
          [cur.name.replaceAll(" ", "")]: cur.size,
          [`${cur.optionName}${cur.name}`.replaceAll(" ", "")]: cur.size,
        };
      },
      {
        W: width,
        H: height,
      }
    );

    const scopes = options?.reduce<any>((prv, cur) => {
      const scopeName = cur.name.replaceAll(" ", "");
      let appended = {
        ...prv,
      };
      appended = scopeConcat({
        prvScopes: appended,
        scopeName,
        option: cur,
      });
      return appended;
    }, calcScopes);

    // console.log(scopes);

    const modifiers = options
      .filter(m => !m.noCalc)
      ?.reduce<modifier[]>((prv, cur) => {
        const _modifiers: modifier[] = [];
        if (cur.modifiers) {
          cur.modifiers.map(m => {
            if (
              m.value == options.find(o => o.name == cur.name)?.value ||
              isNullish(m.value)
            ) {
              _modifiers.push({ ...m, optionValue: cur.value });
            }
          });
        }
        return prv.concat(_modifiers);
      }, []);

    let materials = deductions?.reduce<inventory[]>((prv, deduction) => {
      if (!deduction?.usedItems) {
        return prv;
      }

      let mats = prv;

      for (const invItem of deduction.usedItems) {
        if (invItem.optionConditions) {
          if (
            !options.every(o => {
              const myCondition = invItem.optionConditions?.find(
                oc => oc.option == o.id
              );
              if (!myCondition) {
                return true;
              }
              const currentValue = o.value.toString();
              const conditions = myCondition.values.map(v => v.toString());
              return conditions.includes(currentValue);
            })
          ) {
            continue;
          }
        }
        let qty = 0;
        try {
          qty = evaluate(invItem.qty, scopes);
        } catch (error) {
          // console.log(error);
          // console.log(scopes);
        }
        const mat: inventory = {
          id: invItem.id,
          qty,
          unit: invItem.unit,
        };
        if (prv.find(p => p.id == mat.id)) {
          mats = mats.map(p => {
            if (p.id == mat.id) {
              return {
                ...p,
                qty: p.qty + mat.qty,
              };
            } else {
              return p;
            }
          });
        } else {
          mats.push(mat);
        }
      }

      return mats;
    }, []);

    if (modifiers) {
      const sortedModifiers = sort(modifiers).asc("type");
      for (const modifier of sortedModifiers) {
        switch (modifier.type) {
          case "replace":
            materials = materials.map(m => {
              if (m.id == Number(modifier?.new)) {
                return {
                  ...m,
                  id: Number(modifier.new),
                };
              } else {
                return m;
              }
            });

            break;
          case "add":
            const id =
              modifier.id == "selected"
                ? Number(modifier.optionValue)
                : Number(modifier.id);

            const qty =
              typeof modifier.qty == "string"
                ? calcQty(modifier.qty, scopes)
                : (modifier.qty as Number);

            const unit = modifier.unit || 1;

            if (qty !== 0 && qty !== Infinity) {
              materials = materials.concat({
                id,
                qty,
                unit,
              });
            }
            break;
          case "multiply":
            materials = materials.map(m => {
              if (m.id == modifier.id) {
                return {
                  ...m,
                  qty: m.qty * Number(modifier.qty),
                };
              } else {
                return m;
              }
            });
            break;
        }
      }
    }

    const currentMats: inventory[] = getValues(`${itemCoord}.materials`);

    let unChanged = false;

    if (currentMats && currentMats.length == materials.length) {
      const newMatString = sort(materials)
        .asc("id")
        .map(m => `${m.id}:${m.qty}`)
        .join(", ");
      const curMatString = sort(currentMats)
        .asc("id")
        .map(m => `${m.id}:${m.qty}`)
        .join(", ");

      unChanged = newMatString == curMatString;
    }

    if (unChanged) {
      return;
    }

    setValue(`${itemCoord}.materials`, materials);
    publish(orderEvent.MAT_UPDATE, {
      itemId,
      openingId,
      orderEvent: orderEvent.MAT_UPDATE,
    });
  }, [optionsCoord, locked]);

  const debouncedUpdate = useDebouncedCallback(update, 300);

  const subscriptions: subscription[] = [
    {
      event: orderEvent.OPTION_UPDATE,
      callback: arg => {
        const args = arg as OPTION_UPDATE_ARGS;
        if (args.openingId == openingId && args.itemId == itemId) {
          debouncedUpdate();
        }
      },
    },
    {
      event: orderEvent.SIZE_UPDATE,
      callback: arg => {
        const args = arg as SIZE_UPDATE_ARGS;
        if (args.openingId == openingId && args.itemId == itemId) {
          debouncedUpdate();
        }
      },
    },
    {
      event: orderEvent.CALC_UPDATE,
      callback: arg => {
        const args = arg as CALC_UPDATE_ARGS;
        if (args.openingId == openingId && args.itemId == itemId) {
          debouncedUpdate();
        }
      },
    },
  ];

  useSubs(subscriptions, [itemCoord, itemId, locked]);

  return null;
}
