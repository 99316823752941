import { Card } from "flowbite-react";
import { useFormContext, useWatch } from "react-hook-form";
import { TbCurrencyDollar, TbMathFunction, TbPercentage } from "react-icons/tb";
import { useOrderStore } from "../../../../../../store/orderStore";
import { usePub } from "../../../../../../utils/pubsub/pubsub";
import {
  orderEvent,
  SALES_PARAM_UPDATE_ARGS,
} from "../../../../../../utils/pubsub/orderEventArgs";
import { ChangeEventHandler } from "react";

interface props {
  index: number;
}

export default function ProductQuoteParams({ index }: props) {
  const { register, getValues, setValue, control } = useFormContext();
  const myCoord = `salesParams.productParams.${index}`;
  const productSetId = getValues(`${myCoord}.productSetId`);
  const { productSets } = useOrderStore();
  const productSet = productSets.find(ps => ps.id == productSetId);

  const discountType = useWatch({ name: `${myCoord}.discount.type`, control });

  const pub = usePub<SALES_PARAM_UPDATE_ARGS>();
  const publish = () => {
    pub(orderEvent.SALES_PARAM_UPDATE, {
      orderEvent: orderEvent.SALES_PARAM_UPDATE,
      productSetId,
    });
  };

  const myRegister = (coord: string) => {
    const onChange: ChangeEventHandler<
      HTMLInputElement | HTMLSelectElement
    > = e => {
      setValue(coord, e.target.value);
      publish();
    };

    return { ...register(coord), onChange };
  };
  return (
    <Card className="col-span-1">
      <h3>{productSet?.name}</h3>
      <div className="px-2 border-b-[1px] flex flex-row items-center justify-between">
        <h3>Base Install Cost</h3>
        <div className="flex flex-1 flex-row items-center gap-1">
          <input
            placeholder="Enter number or function"
            className="p-1 text-plum text-lg font-semibold text-right bg-transparent border-none flex-1 w-0 outline-none"
            {...myRegister(`${myCoord}.baseInstall`)}
          />
          <TbMathFunction className="w-5 h-5 ml-2" />
        </div>
      </div>
      <div className="px-2 border-b-[1px] flex flex-row items-center justify-between">
        <h3>Margin</h3>
        <div className="flex flex-1 flex-row items-center gap-1">
          <input
            placeholder="Enter number"
            className="p-1 text-plum text-lg font-semibold text-right bg-transparent border-none flex-1 w-0 outline-none"
            {...myRegister(`${myCoord}.margin`)}
          />
          <TbPercentage className="w-5 h-5 ml-2" />
        </div>
      </div>
      <div className="px-2 border-b-[1px] flex flex-row items-center justify-between">
        <h3>Discount</h3>
        <div className="flex flex-row items-center justify-end">
          <input
            className="p-1 text-plum text-2xl font-semibold bg-transparent border-none outline-none w-14 text-center"
            {...myRegister(`${myCoord}.discount.value`)}
            placeholder="0"
            type="number"
          />
          <div className="flex flex-row gap-2 items-end">
            <div
              onClick={() => {
                setValue(`${myCoord}.discount.type`, "rate");
                publish();
              }}
              className={`${
                discountType == "rate" && "text-plum text-xl font-semibold"
              } cursor-pointer`}
            >
              %
            </div>
            /
            <div
              onClick={() => {
                setValue(`${myCoord}.discount.type`, "amount");
                publish();
              }}
              className={`${
                discountType == "amount" && "text-plum text-xl font-semibold"
              } cursor-pointer`}
            >
              AUD
            </div>
          </div>
        </div>
      </div>

      <div className="px-2 border-b-[1px] flex flex-row items-center justify-between">
        <h3>Sqm</h3>
        <div className="flex flex-1 flex-row items-center gap-1">
          <input
            placeholder="Enter number"
            className="p-1 text-plum text-lg font-semibold text-right bg-transparent border-none flex-1 w-0 outline-none"
            {...myRegister(`${myCoord}.sqm`)}
          />
          <TbCurrencyDollar className="w-5 h-5 ml-2" />
        </div>
      </div>
    </Card>
  );
}
