import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useScheme } from "../../../../store/schemeStore";
import { useOrderStore } from "../../../../store/orderStore";
import { sort } from "fast-sort";
import { useEffect, useState } from "react";
import { Badge, Checkbox, Select, TextInput } from "flowbite-react";
import {
  NoSymbolIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { optionProps } from "../../workorder/types";

interface ocProps {
  productSetID: number;
  canReverse?: boolean;
}

export default function OptionCondition({ productSetID, canReverse }: ocProps) {
  const { scheme } = useScheme();
  const { control } = useFormContext();
  const { append, remove } = useFieldArray({
    name: "optionCondition",
    control,
  });

  const { materials, deductions, presets, productSetOptions } = useOrderStore();

  const optionTypes = ["select", "text", "number"];
  const options = productSetOptions
    ? sort(productSetOptions.filter(o => o.productSetID == productSetID))
        .asc("position")
        .filter(o => optionTypes.includes(o.type))
    : [];

  const groupOptions: optionProps[] = [];

  options
    .filter(o => o.group && o.optionCode)
    .forEach(o => {
      if (groupOptions.find(go => go.group == o.group)) {
        return;
      }
      if (
        options.find(oj => oj.optionCode == o.optionCode && oj.group == o.group)
      ) {
        groupOptions.push(o);
      }
    });

  const defaultValues: {
    option: number;
    value: any;
    displayValue?: string | number;
    group?: string;
    reverse: boolean;
  } = {
    option: 0,
    value: "",
    displayValue: undefined,
    reverse: false,
  };

  const nullishValues = ["", null, 0];
  const [newCondition, setNewCondition] = useState(defaultValues);

  const selectedOption = options?.find(o => o.id == newCondition.option);
  const [selectables, setSelectables] = useState<any[]>([]);

  useEffect(() => {
    if (selectedOption?.source == "deductionPreset") {
      const _presets = presets.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_presets);
    }
    if (selectedOption?.source == "deductions") {
      const _deductions = deductions.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_deductions);
    }
    if (selectedOption?.source == "inventory") {
      const _materials = materials.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_materials);
    }
    if (!selectedOption?.source && selectedOption?.type == "select") {
      // @ts-ignore
      setSelectables(selectedOption.values);
    }
  }, [selectedOption]);

  const add = () => {
    if (
      nullishValues.includes(newCondition.option) ||
      nullishValues.includes(newCondition.value)
    ) {
      return;
    }
    append(newCondition);
    setNewCondition(defaultValues);
  };

  const addedConditions: any[] = useWatch({
    name: "optionCondition",
    control,
  });

  return (
    <div className={`${scheme} flex flex-col gap-2 flex-wrap`}>
      {/* New Condition */}
      <div className="flex flex-row gap-2 items-center">
        <Select
          addon="Option"
          sizing="sm"
          value={newCondition.option}
          onChange={e => {
            if (isNaN(Number(e.target.value))) {
              const [group, id] = e.target.value.split("_");
              setNewCondition({
                ...newCondition,
                option: Number(id),
                group: group,
              });
              return;
            }
            setNewCondition({
              ...newCondition,
              option: Number(e.target.value),
            });
          }}
        >
          <option value="">select option</option>
          {options?.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
          {groupOptions.map(option => (
            <option key={option.id} value={option.group + "_" + option.id}>
              {option.group}
            </option>
          ))}
        </Select>
        {selectedOption && selectedOption.type == "select" ? (
          <Select
            addon="Value"
            sizing="sm"
            value={newCondition.value}
            onChange={e => {
              const displayValue = selectables.find(
                s => s.id?.toString() == e.target.value
              )?.name;
              setNewCondition({
                ...newCondition,
                value: e.target.value,
                displayValue,
              });
            }}
          >
            <option value="">select option</option>
            {selectables?.map((option, i) => (
              <option key={option.id || i} value={option.id || option}>
                {option.name
                  ? `${option.name} ${option.color ? " " + option.color : ""}`
                  : option}
              </option>
            ))}
          </Select>
        ) : (
          <TextInput
            sizing="sm"
            addon="Value"
            placeholder="Enter Value"
            value={newCondition.value}
            onChange={e => {
              setNewCondition({ ...newCondition, value: e.target.value });
            }}
          />
        )}
        {canReverse && (
          <div className="flex flex-row items-center gap-2">
            <Checkbox
              checked={newCondition.reverse}
              onChange={e => {
                setNewCondition({ ...newCondition, reverse: e.target.checked });
              }}
            />
            <span className="dark:text-white">Reverse</span>
          </div>
        )}
        <PlusCircleIcon
          className="w-6 text-grass cursor-pointer"
          onClick={add}
        />
      </div>
      {/* conditions */}
      {addedConditions && addedConditions.length > 0 && (
        <div className="flex flex-row gap-2">
          {addedConditions.map((item, index) => {
            const option = options.find(o => o.id == Number(item.option));

            const mat =
              option?.source == "inventory" &&
              materials.find(mat => mat.id == Number(item.value));

            const displayValue = mat
              ? `${mat.brand} ${mat.name} ${mat.color}${
                  mat.size ? " " + mat.size : ""
                }`
              : item.displayValue;
            return (
              <Badge
                key={index}
                color={item.reverse ? "failure" : "purple"}
                className="cursor-pointer"
                onClick={() => {
                  remove(index);
                }}
              >
                <div className="flex flex-row items-center">
                  {item.reverse && <NoSymbolIcon className="w-4 mr-1" />}
                  {option?.group ? option.group : option?.name}:{" "}
                  {displayValue || item.value} <XMarkIcon className="w-4" />
                </div>
              </Badge>
            );
          })}
        </div>
      )}
    </div>
  );
}
