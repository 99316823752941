import { useFormContext, useWatch } from "react-hook-form";
import { optionValues } from "../../../../../types";
import { useEffectOnce, useIsMounted } from "usehooks-ts";
import { usePub, useSub } from "../../../../../../../../utils/pubsub/pubsub";
import {
  OPTION_UPDATE_ARGS,
  orderEvent,
} from "../../../../../../../../utils/pubsub/orderEventArgs";
import { option } from "../../../../types";
// import { memo } from "react";

interface seProps {
  optionCoordChained: string;
  optionsCoord: string;
  optionValue: any;
  openingId: string;
  optionId: number;
}

const OptionsSideEffects = ({
  optionsCoord,
  optionCoordChained,
  optionId,
  openingId,
}: seProps) => {
  const { control, getValues, setValue } = useFormContext();
  const optionParents: optionValues[] = useWatch({
    control,
    name: `${optionCoordChained}.parent`,
    exact: true,
  });

  const isMounted = useIsMounted();

  const publish = usePub<OPTION_UPDATE_ARGS>();

  const update = () => {
    const options: option[] = getValues(optionsCoord) || [];

    const openingCoord = optionsCoord.split(".").slice(0, -1).join(".");
    const calcs = getValues(`${openingCoord}.calcs`);
    if (!isMounted() && calcs && Array.isArray(calcs) && calcs.length > 0) {
      return;
    }

    const poCheck =
      options &&
      options
        .filter(o => optionParents?.some(p => p.option == o.id))
        .every(
          p =>
            optionParents?.find(po =>
              po.values.map(v => v.toString()).includes(p.value.toString())
            ) && !p.noCalc
        );

    if (poCheck == !getValues(`${optionCoordChained}.noCalc`)) {
      return;
    }

    if (isMounted() && poCheck == !getValues(`${optionCoordChained}.noCalc`)) {
      return;
    }

    setValue(`${optionCoordChained}.noCalc`, !poCheck);
    publish(orderEvent.OPTION_UPDATE, {
      orderEvent: orderEvent.OPTION_UPDATE,
      openingId,
      optionId,
    });
  };

  useSub(
    orderEvent.OPTION_UPDATE,
    (args: OPTION_UPDATE_ARGS) => {
      if (args.optionId == optionId) return;
      if (args.openingId == openingId) {
        update();
      }
    },
    [openingId]
  );

  useEffectOnce(() => {
    update();
  });

  return null;
};

export default OptionsSideEffects;
