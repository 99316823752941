import { useFormContext, useWatch } from "react-hook-form";
import useGetPrice, { breakdownData } from "../../summary/getPrice";
import { TextInput } from "flowbite-react";
import { useIsMounted } from "usehooks-ts";
import { useState } from "react";
import { opening, option } from "../../../types";
import isNullish from "../../../../../../../utils/isNullish";
import PriceBox from "../PriceBox/PriceBox";
import { useDebouncedCallback } from "../../../../../../../utils/useDebounceCallback";
import {
  subscription,
  usePub,
  useSubs,
} from "../../../../../../../utils/pubsub/pubsub";
import {
  CALC_UPDATE_ARGS,
  INSTALL_PARAM_UPDATE_ARGS,
  OPTION_UPDATE_ARGS,
  orderEvent,
  PRICE_UPDATE_ARGS,
  QUOTE_OPTION_UPDATE_ARGS,
  SALES_PARAM_UPDATE_ARGS,
} from "../../../../../../../utils/pubsub/orderEventArgs";

interface props {
  openingCoord: string;
  productSetId: number;
  showPrice: boolean;
  openingId: string;
}

export default function Price({
  openingCoord,
  productSetId,
  showPrice,
  openingId,
}: props) {
  const { getValues, setValue } = useFormContext();

  const orderType = getValues("type");
  const orderedUserId = getValues("user") as number;

  const isMounted = useIsMounted();

  const isRetail = orderType?.id !== 4;
  const workorderType = 1;

  const [breakDownData, setBreakDownData] = useState(
    undefined as undefined | breakdownData
  );

  const getPrice = useGetPrice();

  const [currentPrice, setCurrentPrice] = useState(
    getValues(`${openingCoord}.price`) || 0
  );

  const [customInstall, setCustomInstall] = useState(
    getValues(`${openingCoord}.customInstall`)
  );

  const publish = usePub<PRICE_UPDATE_ARGS>();

  const update = () => {
    if (orderType?.id == workorderType) {
      return;
    }
    if (!isMounted) {
      return;
    }

    const options: option[] = getValues(`${openingCoord}.options`);

    const width = options.find(o => o.name == "W")?.value || 0;
    const height = options.find(o => o.name == "H")?.value || 0;

    let sqm = Number(((width * height) / 1000000).toFixed(2));
    if (sqm < 0.5) {
      sqm = 0.5;
    }
    const currentPrice = getValues(`${openingCoord}.price`) || 0;
    const customInstall = getValues(`${openingCoord}.customInstall`);
    const openingSalesParams = getValues(`${openingCoord}.salesParams`);
    const salesParams = getValues("salesParams");

    const opening: opening = getValues(`${openingCoord}`);

    const sum = getPrice({
      isOpening: true,
      opening,
      sqm,
      productSetId: Number(productSetId),
      isRetail,
      userId: orderedUserId,
      itemSalesParams: openingSalesParams,
      salesParams,
    });

    if (sum?.breakdownData) {
      setBreakDownData(sum.breakdownData);
    }

    const currentInstall = opening.install;

    const install = isNullish(customInstall)
      ? sum?.install || 0
      : Number(customInstall);

    let total = (sum?.price || 0) + install;

    if (currentInstall !== sum?.install) {
      setValue(`${openingCoord}.install`, sum?.install);
      setValue(`${openingCoord}.customInstall`, undefined);
      setCustomInstall(undefined);

      total = (sum?.price || 0) + Number(sum?.install);
    } else {
      setCustomInstall(install);
    }

    if (currentPrice !== total) {
      setValue(`${openingCoord}.price`, total);
      setCurrentPrice(total);
      publish(orderEvent.PRICE_UPDATE, {
        orderEvent: orderEvent.PRICE_UPDATE,
      });
    }
  };

  const debouncedUpdate = useDebouncedCallback(update, 300);

  const subscriptions: subscription[] = [
    {
      event: orderEvent.CALC_UPDATE,
      callback: args => {
        if ((args as CALC_UPDATE_ARGS).openingId === openingId) {
          debouncedUpdate();
        }
      },
    },
    {
      event: orderEvent.OPTION_UPDATE,
      callback: args => {
        if ((args as OPTION_UPDATE_ARGS).openingId === openingId) {
          debouncedUpdate();
        }
      },
    },
    {
      event: orderEvent.INSTALL_PARAM_UPDATE,
      callback: args => {
        if ((args as INSTALL_PARAM_UPDATE_ARGS).openingId === openingId) {
          debouncedUpdate();
        }
      },
    },
    {
      event: orderEvent.QUOTE_OPTION_UPDATE,
      callback: args => {
        if ((args as QUOTE_OPTION_UPDATE_ARGS).openingId === openingId) {
          debouncedUpdate();
        }
      },
    },
    {
      event: orderEvent.SALES_PARAM_UPDATE,
      callback: args => {
        const productSetId: opening["product"] = getValues(
          `${openingCoord}.product`
        );
        (args as SALES_PARAM_UPDATE_ARGS).productSetId ==
          Number(productSetId) && debouncedUpdate();
      },
    },
  ];

  useSubs(subscriptions, [openingCoord, openingId]);

  return (
    <>
      {isRetail && (
        <div
          className={`relative group/option w-20 my-[5px] ${
            !showPrice && "hidden"
          }`}
        >
          <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
            Install
          </div>
          <Install coord={openingCoord} openingId={openingId} />
        </div>
      )}
      {showPrice && (
        <PriceBox
          currentPrice={currentPrice}
          breakDownData={breakDownData}
          isRetail={isRetail}
          customInstallPrice={customInstall}
        />
      )}
    </>
  );
}

interface installProps {
  coord: string;
  openingId: string;
}
const Install = ({ coord, openingId }: installProps) => {
  const { setValue, control } = useFormContext();

  const publish = usePub<INSTALL_PARAM_UPDATE_ARGS>();

  const updateInstall = (price: number) => {
    setValue(`${coord}.customInstall`, price);
    publish(orderEvent.INSTALL_PARAM_UPDATE, {
      orderEvent: orderEvent.INSTALL_PARAM_UPDATE,
      openingId,
    });
  };

  const installValue = useWatch({
    name: `${coord}.install`,
    control,
    defaultValue: 0,
  });

  const customInstallValue = useWatch({
    name: `${coord}.customInstall`,
    control,
    defaultValue: undefined,
  });

  const value = isNullish(customInstallValue)
    ? installValue
    : customInstallValue;

  return (
    <TextInput
      addon="$"
      className="w-full"
      value={value}
      onChange={e => {
        updateInstall(Number(e.target.value));
      }}
      sizing="sm"
    />
  );
};
