import {
  DocumentDuplicateIcon,
  EyeIcon,
  EyeSlashIcon,
  TagIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { Checkbox, Select, TextInput, Tooltip } from "flowbite-react";
import { useEffect, memo, useRef } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import Items from "../item/items";
import { item, opening, option } from "../../../types";
import { useDebounce } from "usehooks-ts";
import OpeningOptions from "../openingOptions/openingOptions";
import ShutterPreview from "../ShutterDrawings/ShutterPreview";
import uuid from "react-uuid";
import { useOrderStore } from "../../../../../../../store/orderStore";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Price from "./price";
import CustomItem from "./customItem";
import QuoteOptions from "../quoteOptions";
import Note from "../note";
import { sort } from "fast-sort";
import OpeningCalcs from "./openingCalcs";
import { usePub } from "../../../../../../../utils/pubsub/pubsub";
import {
  OPENING_SELECT_ARGS,
  orderEvent,
} from "../../../../../../../utils/pubsub/orderEventArgs";

interface props {
  id: string;
  index: number;
  locationIndex: number;
  locationId: string;
  remove: () => void;
  addRow: (opening?: opening) => void;
  isOverlay?: boolean;
}

const Opening = ({
  locationIndex,
  index,
  remove,
  addRow,
  id,
  isOverlay,
  locationId,
}: props) => {
  const { productSets: product_sets } = useOrderStore();

  const { register, control, setValue, getValues } = useFormContext();

  const openingCoord: `locations.${number}.openings.${number}` = `locations.${locationIndex}.openings.${index}`;

  const productId = useWatch({
    name: `${openingCoord}.product`,
    control,
  });

  const showPrice = useWatch({
    name: `showPrice`,
    control,
    exact: true,
  });

  const isMounted = useRef(false);

  useEffect(() => {
    if (isOverlay) {
      return;
    }
    if (!productId) {
      return;
    }

    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    setValue(`${openingCoord}.layoutCodes`, []);
  }, [productId]);

  const product = product_sets?.find(p => p.id == Number(productId));
  const isOpeningProduct = product?.hideItems;

  const isCustom = productId == "custom";

  const copyOpening = () => {
    const opening: opening = getValues(
      `locations.${locationIndex}.openings.${index}`
    );
    addRow({
      ...opening,
      index: undefined,
      // @ts-expect-error
      panel: undefined,
      items: opening.items.map(item => ({ ...item, id: uuid() })),
    });
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data: {
      index,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const publish = usePub<OPENING_SELECT_ARGS>();

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex flex-col gap-2 group/opening relative"
    >
      <div className="flex flex-row gap-2">
        {!isOverlay && (
          <Controller
            name={`${openingCoord}.checked`}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={field.value || false}
                onChange={e => {
                  field.onChange(e);
                  publish(orderEvent.OPENING_SELECT, {
                    orderEvent: orderEvent.OPENING_SELECT,
                    locationId,
                  });
                }}
                className={`checked:bg-grass dark:checked:bg-grass bg-gray-50 ${
                  isOpeningProduct ? "mt-4" : "mt-3"
                }`}
              />
            )}
          />
        )}
        {isOverlay && (
          <Checkbox
            className={`checked:bg-grass dark:checked:bg-grass bg-gray-50 ${
              isOpeningProduct ? "mt-4" : "mt-3"
            }`}
          />
        )}
        <div
          className={`flex-1 flex flex-row flex-wrap gap-1 space-y-2 ${
            isCustom && "pr-20"
          } ${isOpeningProduct && "pr-20 items-end"}`}
        >
          <div
            className={`flex flex-row flex-wrap gap-1 gap-y-1 items-center relative ${
              isCustom && "flex-1"
            }`}
          >
            {/* Opening Name */}
            <TextInput
              sizing="sm"
              {...register(`${openingCoord}.name`)}
              className={`w-32 ${isOpeningProduct ? "" : "py-[5px]"}`}
              placeholder="Location"
              autoComplete="off"
              addon={index + 1}
            />
            <div
              className="absolute w-[30px] left-0 top-0 h-[40px]"
              {...attributes}
              {...listeners}
            />
            {/* Product Select */}
            {product_sets && product_sets?.length > 0 && (
              <Select
                sizing="sm"
                {...register(`${openingCoord}.product`)}
                placeholder="product"
                icon={TagIcon}
              >
                <option value={undefined}>product</option>
                {product_sets &&
                  sort(product_sets)
                    .asc("position")
                    .map(product => (
                      <option key={product.id} value={product.id}>
                        {product.name}
                      </option>
                    ))}
                <option value="custom">Custom</option>
              </Select>
            )}
            {isCustom && <CustomItem openingCoord={openingCoord} />}
            {!isOpeningProduct && !isOverlay && !isCustom && (
              <Items
                locationIndex={locationIndex}
                openingIndex={index}
                openingId={id}
                product={product}
              />
            )}
          </div>

          {isOpeningProduct && (
            <>
              <OpeningOptions
                locationIndex={locationIndex}
                openingIndex={index}
                openingId={id}
              />
              <QuoteOptions
                coord={openingCoord}
                showPrice={showPrice}
                productSetId={productId}
                openingId={id}
              />
              <Note coord={openingCoord} />
              <Price
                openingCoord={openingCoord}
                showPrice={showPrice}
                productSetId={productId}
                openingId={id}
              />
              <OpeningCalcs
                openingCoord={openingCoord}
                product={product}
                openingId={id}
              />
            </>
          )}
          <OpeningActions
            copyOpening={copyOpening}
            openingCoord={openingCoord}
            remove={remove}
            isCustom={isCustom}
          />
        </div>
      </div>
      <ItemsImages productName={product?.name} openingCoord={openingCoord} />
      {product?.name == "Shutter" && <ShutterPreview coord={openingCoord} />}
    </div>
  );
};

export default memo(Opening);

interface openingActions {
  copyOpening: any;
  remove: any;
  openingCoord: string;
  isCustom: boolean;
}

const OpeningActions = ({
  openingCoord,
  copyOpening,
  remove,
  isCustom,
}: openingActions) => {
  const { control, setValue } = useFormContext();

  const show = useWatch({ name: `${openingCoord}.preview`, control });
  const toggleShow = () => {
    setValue(`${openingCoord}.preview`, !show);
  };

  return (
    <div className="absolute top-0 right-0 self-center flex-row gap-2 items-center hidden group-hover/opening:flex">
      {!isCustom && (
        <div
          className="w-5 cursor-pointer relative group/openingPreview"
          onClick={toggleShow}
        >
          {show ? <EyeIcon /> : <EyeSlashIcon />}
          <div className="absolute top-0 -translate-y-full hidden group-hover/openingPreview:block">
            {show ? "hide" : "show"} preview
          </div>
        </div>
      )}

      <Tooltip content="copy opening">
        <DocumentDuplicateIcon
          className="w-5 cursor-pointer text-plum"
          onClick={copyOpening}
        />
      </Tooltip>
      <Tooltip content="remove opening">
        <TrashIcon
          className="w-5 text-red-500 cursor-pointer"
          onClick={remove}
        />
      </Tooltip>
    </div>
  );
};

interface itemImages {
  openingCoord: string;
  productName?: string;
}

const ItemsImages = ({ openingCoord, productName }: itemImages) => {
  const { control } = useFormContext();
  const show = useWatch({ name: `${openingCoord}.preview`, control });

  const items: item[] = useWatch({ name: `${openingCoord}.items`, control });
  const debouncedItems = useDebounce(items, 100);
  const images = debouncedItems?.map(i => i.image).filter(i => i);

  return (
    <>
      {show && images && productName !== "Shutter" && (
        <div className="flex flex-row items-start justify-start overflow-x-auto ml-4">
          {images.map((image, i) => (
            <div key={i} className="w-60 relative min-h-[200px]">
              <img
                key={i}
                className={`absolute left-0 right-0 w-60 ${
                  image.invert && "dark:invert"
                }`}
                src={image.image}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};
