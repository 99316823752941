import { useFormContext, useWatch } from "react-hook-form";
import useGetPrice, { breakdownData } from "../../summary/getPrice";
import { item, opening } from "../../../types";
import { TextInput } from "flowbite-react";
import { useIsMounted } from "usehooks-ts";
import { memo, useState } from "react";
import isNullish from "../../../../../../../utils/isNullish";
import PriceBox from "../PriceBox/PriceBox";
import {
  subscription,
  usePub,
  useSubs,
} from "../../../../../../../utils/pubsub/pubsub";
import {
  CALC_UPDATE_ARGS,
  INSTALL_PARAM_UPDATE_ARGS,
  MAT_UPDATE_ARGS,
  OPTION_UPDATE_ARGS,
  orderEvent,
  PRICE_UPDATE_ARGS,
  QUOTE_OPTION_UPDATE_ARGS,
  SALES_PARAM_UPDATE_ARGS,
  SIZE_UPDATE_ARGS,
} from "../../../../../../../utils/pubsub/orderEventArgs";
import { useDebouncedCallback } from "../../../../../../../utils/useDebounceCallback";

interface props {
  openingCoord: string;
  itemCoord?: string;
  showPrice: boolean;
  openingId: string;
  itemId: string;
  locked?: boolean;
}

function Price({
  openingCoord,
  itemCoord,
  showPrice,
  openingId,
  itemId,
  locked,
}: props) {
  const { getValues, setValue } = useFormContext();

  const isMounted = useIsMounted();

  const [currentPrice, setCurrentPrice] = useState(
    getValues(`${itemCoord}.price`) || 0
  );

  const [customInstall, setCustomInstall] = useState(
    getValues(`${itemCoord}.customInstall`)
  );

  const orderType = getValues("type");
  const orderedUserId = getValues("user") as number;

  const getPrice = useGetPrice();

  const isRetail = orderType?.id !== 4;

  const [breakDownData, setBreakDownData] = useState(
    undefined as undefined | breakdownData
  );

  const publish = usePub<PRICE_UPDATE_ARGS>();

  const update = () => {
    if (locked) return;
    if (!isMounted) {
      return;
    }

    const item: item = getValues(`${itemCoord}`);
    const currentPrice = item.price || 0;
    const width = Number(item.width || 0);
    const height = Number(item.height || 0);

    const customInstall = item.customInstall;
    const itemSalesParams = item.salesParams;

    const opening: opening = getValues(`${openingCoord}`);

    let sqm = Number(((width * height) / 1000000).toFixed(1));
    if (sqm < 0.5) {
      sqm = 0.5;
    }

    const salesParams = getValues("salesParams");

    const sum = getPrice({
      isOpening: false,
      item,
      opening,
      sqm,
      productSetId: Number(opening.product),
      isRetail,
      userId: orderedUserId,
      itemSalesParams,
      salesParams,
    });

    if (sum?.breakdownData) {
      setBreakDownData(sum.breakdownData);
    }

    const currentInstall = item.install;

    const install = isNullish(customInstall)
      ? sum?.install || 0
      : Number(customInstall);

    let total = (sum?.price || 0) + install;

    if (currentInstall !== sum?.install) {
      setValue(`${itemCoord}.install`, sum?.install);
      setValue(`${itemCoord}.customInstall`, undefined);
      setCustomInstall(undefined);

      total = (sum?.price || 0) + Number(sum?.install);
    } else {
      setCustomInstall(install);
    }

    if (currentPrice !== total) {
      setValue(`${itemCoord}.price`, total);
      setCurrentPrice(total);

      publish(orderEvent.PRICE_UPDATE, {
        orderEvent: orderEvent.PRICE_UPDATE,
      });
    }
  };

  const debouncedUpdate = useDebouncedCallback(update, 300);

  const updatedBySubs = (
    args:
      | MAT_UPDATE_ARGS
      | CALC_UPDATE_ARGS
      | OPTION_UPDATE_ARGS
      | INSTALL_PARAM_UPDATE_ARGS
      | SIZE_UPDATE_ARGS
      | QUOTE_OPTION_UPDATE_ARGS
  ) => {
    if (args.openingId === openingId && args.itemId === itemId) {
      debouncedUpdate();
    }
  };

  const subscriptions: subscription[] = [
    {
      event: orderEvent.MAT_UPDATE,
      callback: args => {
        updatedBySubs(args as MAT_UPDATE_ARGS);
      },
    },
    {
      event: orderEvent.CALC_UPDATE,
      callback: args => {
        updatedBySubs(args as CALC_UPDATE_ARGS);
      },
    },
    {
      event: orderEvent.OPTION_UPDATE,
      callback: args => {
        updatedBySubs(args as OPTION_UPDATE_ARGS);
      },
    },
    {
      event: orderEvent.QUOTE_OPTION_UPDATE,
      callback: args => {
        updatedBySubs(args as QUOTE_OPTION_UPDATE_ARGS);
      },
    },
    {
      event: orderEvent.SIZE_UPDATE,
      callback: args => {
        updatedBySubs(args as SIZE_UPDATE_ARGS);
      },
    },
    {
      event: orderEvent.INSTALL_PARAM_UPDATE,
      callback: args => {
        updatedBySubs(args as INSTALL_PARAM_UPDATE_ARGS);
      },
    },
    {
      event: orderEvent.SALES_PARAM_UPDATE,
      callback: args => {
        const productSetId: opening["product"] = getValues(
          `${openingCoord}.product`
        );
        (args as SALES_PARAM_UPDATE_ARGS).productSetId ==
          Number(productSetId) && debouncedUpdate();
      },
    },
  ];

  useSubs(subscriptions, [itemCoord, itemId, locked]);

  return (
    <>
      {isRetail && (
        <div
          className={`relative mr-1 group/option w-20 my-[5px] ${
            !showPrice && "hidden"
          }`}
        >
          <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
            Install
          </div>
          <Install
            coord={itemCoord as string}
            openingId={openingId}
            itemId={itemId}
          />
        </div>
      )}
      {showPrice && (
        <PriceBox
          currentPrice={currentPrice}
          breakDownData={breakDownData}
          isRetail={isRetail}
          customInstallPrice={customInstall}
        />
      )}
    </>
  );
}

interface installProps {
  coord: string;
  openingId: string;
  itemId: string;
}
const Install = ({ coord, openingId, itemId }: installProps) => {
  const { setValue, control } = useFormContext();

  const publish = usePub<INSTALL_PARAM_UPDATE_ARGS>();

  const updateInstall = (price: number) => {
    setValue(`${coord}.customInstall`, price);
    publish(orderEvent.INSTALL_PARAM_UPDATE, {
      orderEvent: orderEvent.INSTALL_PARAM_UPDATE,
      openingId,
      itemId,
    });
  };

  const installValue = useWatch({
    name: `${coord}.install`,
    control,
    defaultValue: 0,
  });

  const customInstallValue = useWatch({
    name: `${coord}.customInstall`,
    control,
    defaultValue: undefined,
  });

  const value = isNullish(customInstallValue)
    ? installValue
    : customInstallValue;

  return (
    <TextInput
      addon="$"
      className="w-full"
      value={Math.floor(value)}
      onChange={e => {
        updateInstall(Number(e.target.value));
      }}
      sizing="sm"
    />
  );
};

export default memo(Price);
