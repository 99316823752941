import {
  DocumentDuplicateIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/solid";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import Openings from "./opening/openings";
import { location } from "../../types";
import { Checkbox, TextInput, Tooltip } from "flowbite-react";
import { memo } from "react";
import ShowHideControl from "./showHideControl";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { usePub } from "../../../../../../utils/pubsub/pubsub";
import {
  LOCATION_SELECT_ARGS,
  orderEvent,
} from "../../../../../../utils/pubsub/orderEventArgs";

interface props {
  id: string;
  index: number;
  remove: () => void;
  addRow: (location?: location) => void;
  isOverlay?: boolean;
}

const Location = ({ index, remove, addRow, id, isOverlay }: props) => {
  const { register, getValues, control } = useFormContext();

  const copyLocation = () => {
    const location = getValues(`locations.${index}`);
    addRow(location);
  };

  const hidden = useWatch({
    control,
    name: `locations.${index}.hidden`,
  });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data: {
      index,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const publish = usePub<LOCATION_SELECT_ARGS>();

  return (
    <div ref={setNodeRef} style={style}>
      <div className="flex flex-row gap-2 items-center group/location">
        <div className="flex flex-row gap-2 items-center relative">
          {!isOverlay && (
            <Controller
              name={`locations.${index}.checked`}
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value || false}
                  onChange={e => {
                    field.onChange(e);
                    publish(orderEvent.LOCATION_SELECT, {
                      orderEvent: orderEvent.LOCATION_SELECT,
                    });
                  }}
                  className="checked:bg-grass dark:checked:bg-grass bg-gray-50"
                />
              )}
            />
          )}
          {isOverlay && (
            <Checkbox className="checked:bg-grass dark:checked:bg-grass bg-gray-50" />
          )}
          <TextInput
            {...register(`locations.${index}.name`)}
            addon={`Unit_${index + 1}`}
            className="bg-transparent text-grass font-semibold w-40"
            placeholder="Unit / House"
            autoComplete="off"
            sizing="sm"
            autoFocus
          />
          {hidden && (
            <div
              className="absolute w-[60px] left-[22px] h-full"
              {...attributes}
              {...listeners}
            />
          )}
        </div>
        <Tooltip content={`${hidden ? "show" : "hide"} location`}>
          <Controller
            name={`locations.${index}.hidden`}
            control={control}
            defaultValue={false}
            render={({ field }) => <ShowHideControl field={field} />}
          />
        </Tooltip>
        {hidden && <OpeningLength index={`locations.${index}`} />}
        <div className="hidden group-hover/location:flex flex-row items-center gap-2">
          <Tooltip content="copy location">
            <DocumentDuplicateIcon
              className="text-plum w-5 cursor-pointer"
              onClick={copyLocation}
            />
          </Tooltip>
          <Tooltip content="delete location">
            <MinusCircleIcon
              onClick={remove}
              className="cursor-pointer text-red-500 w-5"
            />
          </Tooltip>
        </div>
      </div>
      <div className={`${hidden && "hidden"}`}>
        {!isOverlay && <Openings locationId={id} locationIndex={index} />}
      </div>
    </div>
  );
};

const OpeningLength = ({ index }: { index: string }) => {
  const { getValues } = useFormContext();
  const openingsIndex = `${index}.openings`;
  const length = getValues(openingsIndex)?.length;

  return <div>{length}</div>;
};

export default memo(Location);
