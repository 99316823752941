import { useQuery } from "@apollo/client";
import { inventory, opening, openingCalc, option } from "../../types";
import { GET_DEDUCTIONS_BY_IDS, GET_DEDUCTIONS_NO_IMG } from "../../../gqls";
import { useEffect, useRef, useState } from "react";
import { DeductionType } from "../../../types";
import { evaluate } from "mathjs";
import { sort } from "fast-sort";
import { GET_MATERIALS_CONDITIONAL } from "../../../../materials/gqls";
import isNullish from "../../../../../../utils/isNullish";
import { roundUp } from "../../../../../../utils/numberMethods";
import LayoutAndFrame from "../ordering/ShutterDrawings/layoutAndFrame";
import { useOrderStore } from "../../../../../../store/orderStore";

interface multiValue {
  name: string;
  checked: boolean;
  value: number;
}

interface openingDetail {
  opening: opening;
  locationIndex: number;
  index?: number;
  name: string;
  jobNum: string;
  multipleLoc: boolean;
}

const nullishValues = [undefined, null, "", "0", 0];
// const special = /[`D!@#$%^*\-_+\=\[\]{};':"\\|,.<>\/?~]/g;

const pageHeight = 190;

const OpeningDetail = ({
  opening,
  index,
  name,
  jobNum,
  multipleLoc,
  locationIndex,
}: openingDetail) => {
  const calcs: openingCalc[] | undefined = opening.calcs;
  // @ts-ignore
  const sectionPanels = opening.panel as any;

  const options = opening.options;

  const layout = options?.find(o => o.name == "Layout")?.value;
  const type = options?.find(o => o.name == "Type")?.value;
  const colour = options?.find(o => o.name == "Colour")?.value;

  const { data: inv_data } = useQuery(GET_MATERIALS_CONDITIONAL, {
    variables: {
      where: { productId: { _eq: 4 } },
    },
  });

  const inventory = inv_data?.materials;

  const { data: all_deduction_data } = useQuery(GET_DEDUCTIONS_NO_IMG);

  const deductions = all_deduction_data?.deductions;

  const { data } = useQuery(GET_DEDUCTIONS_BY_IDS, {
    variables: { ids: [layout] },
  });

  // const { units } = useOrderStore();

  const mUnitId = 2;

  const layoutDeduction = data?.deductions && data?.deductions[0];

  const openingScopes = calcs?.reduce<any>((prv, cur) => {
    return {
      ...prv,
      [cur.name]: cur.value,
    };
  }, {});

  const OH = openingScopes?.OH;
  const OW = openingScopes?.OW;

  // @ts-expect-error
  const panels = OH?.panels.filter(p => p.Panel) as any[];

  const tiers: number[] | undefined = OH?.tiers;

  const PW = OW?.PW as number[];
  // const Qty = Number(options?.find(o => o.name == "Qty")?.value);

  const Frames = OW?.Frames;
  const Uchannel = options?.find(o => o.name == "UChannel");
  // @ts-expect-error
  const FrameType = options?.find(o => o.name == "Frame")?.scope?.value;
  const FrameId = options?.find(o => o.name == "Frame")?.value;
  const TPosts: undefined | number[] = OW?.tPostLocs;
  const Headboard = options?.find(o => o.name == "Headboard")?.value == "Yes";
  const Pelmet = OW?.Pelmet;
  const Facia = options?.find(o => o.name == "Facia")?.value;
  const Returns = options?.find(o => o.name == "Returns")?.value;
  const SideBoard = options?.find(o => o.name == "Returns")?.value;
  const Sills = options?.find(o => o.name == "Sill")?.scope as multiValue[];
  const SideBoardH = OH?.sideBoardH;
  const TrackSizes = OW?.TrackSizes;
  const layoutCode: string = opening.options?.find(o => o.name == "Layout")
    ?.displayValue as string;
  const TopTrack = opening.options?.find(o => o.name == "TopTrack")?.value;
  const BottomTrack = opening.options?.find(
    o => o.name == "BottomTrack"
  )?.value;
  const BottomOverrides = opening.options?.find(
    o => o.name == "BottomOverride"
  )?.value;

  const Width = options?.find(o => o.name == "W")?.value;
  const Height = options?.find(o => o.name == "H")?.value;

  const railSizes = [
    { size: 100, natural: 47, pure: 48 },
    { size: 117, natural: 50, pure: 49 },
    { size: 150, pure: 52, natural: 51 },
  ];

  const [allPanels, setAllPanels] = useState<any[]>([]);
  // const [materials, setMaterials] = useState<inventory[]>([]);

  const calcQty = (f: string, scopes: any) => {
    try {
      return evaluate(f, scopes);
    } catch (error) {
      return 0;
    }
  };

  useEffect(() => {
    if (isNullish(Width) || isNullish(Height)) {
      return;
    }
    let index = 0;
    const panelsReduced = PW.reduce<any[]>((acc, cur) => {
      const width = cur;
      const _panels: any[] = [];

      let deMouldQty = 0;
      let lightBlockQty = 0;
      // const cleanedLC = layoutCode?.replace(special, "");
      let myCode = "";
      const splitByDash = layoutCode?.split("-");
      const decodedSlidingLayout = splitByDash
        .map(group => {
          return group.split("").map((code, i) => ({
            name: code,
            demouldQty:
              splitByDash.length > 1 && i > 0 && code == group[i - 1] ? 1 : 0,
          }));
        })
        .flat();

      if (type == "Hinged") {
        const splitByT = layoutCode?.split("T");
        const splitByDash = splitByT
          ?.map(t => t.split("-"))
          .flat()
          .filter(t => t !== "");
        myCode = splitByDash[index];
        deMouldQty = myCode?.split("").filter(code => code == "D").length;
      }
      if (type == "Sliding") {
        myCode = decodedSlidingLayout[index].name;
        deMouldQty = decodedSlidingLayout[index].demouldQty;
        if (
          decodedSlidingLayout[index + 1]?.name &&
          decodedSlidingLayout[index + 1]?.name !== myCode
        ) {
          lightBlockQty++;
        }
        if (
          decodedSlidingLayout[index - 1]?.name &&
          decodedSlidingLayout[index - 1]?.name !== myCode
        ) {
          lightBlockQty++;
        }
      }

      if (type == "Track Bi-fold") {
        deMouldQty = index == 0 ? 0 : 1;
      }

      let height = 0;
      let position = "";
      let panel: any;

      const pushPanel = (panel: any) => {
        height = panel?.Panel?.PH;

        const bladeWidth = width - 103;
        const RailWidth = width - 70;
        const tubeLength = height - 40;
        const stileLength = height;
        const Blades = panel.Blades || [];

        const sortedMidRails = panel.MidRailLocs
          ? sort(panel.MidRailLocs).asc()
          : [];
        const holestrips: number[] = [];

        for (let i = 0; i < sortedMidRails.length + 1; i++) {
          const topMR = sortedMidRails[i];
          const botMR = sortedMidRails[i - 1];
          if (topMR && botMR) {
            holestrips.push(
              // @ts-ignore
              Blades?.filter(b => b < topMR && b > botMR).length || 0
            );
            continue;
          }
          if (topMR) {
            // @ts-ignore
            holestrips.push(Blades?.filter(b => b < topMR).length || 0);
            continue;
          }
          if (botMR) {
            // @ts-ignore
            holestrips.push(Blades?.filter(b => b > botMR).length || 0);
            continue;
          }
          holestrips.push(Blades?.length);
        }

        const myMaterials: inventory[] = [];

        const trID =
          railSizes.find(r => r.size >= panel?.TR) &&
          // @ts-ignore
          railSizes.find(r => r.size >= panel?.TR)[colour.toLowerCase()];
        // TopRail Mat Calc
        myMaterials.push({
          id: trID,
          qty: RailWidth / 1000,
          unit: mUnitId,
        });

        // @ts-ignore
        const brID =
          railSizes.find(r => r.size >= panel?.BR) &&
          // @ts-ignore
          railSizes.find(r => r.size >= panel?.BR)[colour.toLowerCase()];
        // BottomRail Mat Calc
        myMaterials.push({
          id: brID,
          qty: RailWidth / 1000,
          unit: mUnitId,
        });
        // MidRails Mat Calc
        sortedMidRails.map(mr => {
          myMaterials.push({
            id: colour.toLowerCase() == "pure" ? 53 : 54,
            qty: RailWidth / 1000,
            unit: mUnitId,
          });
        });
        // Blades Mat Calc
        myMaterials.push({
          id: colour.toLowerCase() == "pure" ? 61 : 62,
          qty: (Blades?.length || 0 * bladeWidth) / 1000,
          unit: mUnitId,
        });
        // Demould Mat Calc
        myMaterials.push({
          id: colour.toLowerCase() == "pure" ? 79 : 80,
          qty: (deMouldQty * stileLength) / 1000,
          unit: mUnitId,
        });
        // Stile Mat Calc
        myMaterials.push({
          id: colour.toLowerCase() == "pure" ? 78 : 77,
          qty: ((2 - deMouldQty) * stileLength) / 1000,
          unit: mUnitId,
        });
        // Holestrip Mat Calc
        holestrips.map(hs => {
          myMaterials.push({
            id: colour.toLowerCase() == "pure" ? 75 : 76,
            qty: (hs * (76 + 74)) / 1000,
            unit: mUnitId,
          });
        });
        // Tube Mat Calc
        myMaterials.push({
          id: 81,
          qty: (2 * tubeLength) / 1000,
          unit: mUnitId,
        });

        // TODO Tilt Rods Mat Calc
        // Hinge Calc

        const reducedMats = myMaterials.reduce<inventory[]>((acc, cur) => {
          let inv = acc;
          if (inv.find(i => i.id == cur.id)) {
            inv = inv.map(i => {
              if (i.id == cur.id) {
                return { ...i, qty: i.qty + cur.qty };
              } else {
                return i;
              }
            });
          } else {
            inv.push(cur);
          }
          return inv;
        }, []);

        _panels.push({
          width,
          ...panel,
          height,
          colour,
          myCode,
          deMouldQty,
          position,
          bladeWidth,
          RailWidth,
          tubeLength,
          stileLength,
          holestrips,
          lightBlockQty,
          materials: reducedMats,
        });
      };

      if (tiers && tiers?.length > 0) {
        for (let i = 0; i < panels.length; i++) {
          if (panels.find(p => p.top > panels[i].top)) {
            position = "bottom";
          } else {
            position = "top";
          }
          panel = panels[i];
          pushPanel(panel);
        }
      } else if (panels.length > 1) {
        panel = panels.find(
          p => p.Panel?.position && p.Panel?.position[0] == myCode
        );
        pushPanel(panel);
      } else {
        panel = panels[0];
        pushPanel(panel);
      }

      index++;

      return acc.concat(_panels);
    }, []);
    setAllPanels(panelsReduced);

    const openingMats = [];

    // Tracks Mats Calc
    if (TrackSizes) {
      for (let i = 0; i < TrackSizes.length; i++) {
        const track = TrackSizes[i];
        const includedPanelCode = i == 0 ? "F" : i == 1 ? "B" : "M";
        const includedPanelQty = panelsReduced.filter(
          p => p.myCode == includedPanelCode
        ).length;

        const topTrackId = deductions?.find(d => d.id.toString() == TopTrack)
          ?.inventory?.id;
        // top track
        openingMats.push({
          id: topTrackId || 0,
          qty: track / 1000,
          unit: "m",
        });
        // bottom tracks
        let myBottomId: number = Number(BottomTrack);

        if (
          BottomOverrides &&
          // @ts-ignore
          BottomOverrides.find(b => b.position == i).value
        ) {
          // @ts-ignore
          myBottomId = BottomOverrides.find(b => b.position == i).value;
        }

        const bottomTrackId = deductions?.find(d => d.id == myBottomId)
          ?.inventory?.id;
        openingMats.push({
          id: bottomTrackId || 0,
          qty: bottomTrackId == 60 ? includedPanelQty * 2 : track / 1000,
          unit: bottomTrackId == 60 ? "pcs" : "m",
        });
      }
    }
    // Pelmet Mats Calc
    if (Pelmet) {
      if (Facia == "Yes" || Facia == "Both") {
        openingMats.push({
          id: colour.toLowerCase() == "pure" ? 88 : 89,
          qty: ((Facia == "Both" ? 2 : 1) * Pelmet.frontExt) / 1000,
          unit: "m",
        });
      }
      if (Returns) {
        if (Returns.includes("L")) {
          openingMats.push({
            id: colour.toLowerCase() == "pure" ? 88 : 89,
            qty: Pelmet.depthExt / 1000,
            unit: "m",
          });
        }
        if (Returns.includes("R")) {
          openingMats.push({
            id: colour.toLowerCase() == "pure" ? 88 : 89,
            qty: Pelmet.depthExt / 1000,
            unit: "m",
          });
        }
      }
      // Board Mats Calc
      openingMats.push({
        id: 96,
        qty: Pelmet.frontExt / 1000,
        unit: "m",
      });

      if (SideBoard) {
        if (SideBoard.includes("L")) {
          openingMats.push({
            id: 96,
            qty: OH.sideBoardH / 1000,
            unit: "m",
          });
        }
        if (SideBoard.includes("R")) {
          openingMats.push({
            id: 96,
            qty: OH.sideBoardH / 1000,
            unit: "m",
          });
        }
      }
    }
    // Frames Mats Calc
    if (Frames) {
      for (const frame in Frames) {
        const frameItems = deductions?.find(
          d => d.id == Number(FrameId)
        )?.usedItems;

        if (frameItems) {
          for (const invItem of frameItems) {
            const qty = calcQty(invItem.qty, {
              length: Frames[frame]?.outSize,
              Colour: colour,
            });

            const mat: inventory = {
              id: invItem.id,
              qty,
              unit: invItem.unit,
            };
            openingMats.push(mat);
          }
        }
      }
    }
    // TPost Mats Calc
    if (TPosts && OH.TPostHeight) {
      for (const tp of TPosts) {
        openingMats.push({
          id: colour.toLowerCase() == "pure" ? 82 : 83,
          qty: OH.TPostHeight / 1000,
          unit: "m",
        });
      }
    }
    // Tiers Mats Calc
    if (tiers && tiers.length > 0 && OW?.divWidths) {
      for (const dw of OW?.divWidths) {
        openingMats.push({
          id: colour.toLowerCase() == "pure" ? 82 : 83,
          qty: (dw / 1000) * tiers.length,
          unit: "m",
        });
      }
    }

    // const matsReduced = panelsReduced
    //   .map(p => p.materials)
    //   .flat()
    //   .concat(openingMats)
    //   .filter(mat => mat.qty > 0)
    //   .reduce<inventory[]>((acc, cur) => {
    //     let inv = acc;
    //     if (inv.find(i => i.id == cur.id)) {
    //       inv = inv.map(i => {
    //         if (i.id == cur.id) {
    //           return { ...i, qty: i.qty + cur.qty };
    //         } else {
    //           return i;
    //         }
    //       });
    //     } else {
    //       inv.push(cur);
    //     }
    //     return inv;
    //   }, []);

    // setMaterials(matsReduced);
  }, []);

  const groupedPanels = allPanels.reduce<any[]>((acc, cur) => {
    let groups = acc;
    if (
      acc.find(
        a =>
          a.width == cur.width &&
          a.height == cur.height &&
          a.Panel.top == cur.Panel.top
      )
    ) {
      groups = groups.map(g => {
        if (
          g.width == cur.width &&
          g.height == cur.height &&
          g.Panel.top == cur.Panel.top
        ) {
          const combiendMats = g.materials.concat(cur.materials) as inventory[];

          const reducedMats = combiendMats.reduce<inventory[]>((acc, cur) => {
            let inv = acc;
            if (inv.find(i => i.id == cur.id)) {
              inv = inv.map(i => {
                if (i.id == cur.id) {
                  return { ...i, qty: i.qty + cur.qty };
                } else {
                  return i;
                }
              });
            } else {
              inv.push(cur);
            }
            return inv;
          }, []);

          return {
            ...g,
            qty: g.qty + 1,
            deMouldQty: g.deMouldQty + cur.deMouldQty,
            lightBlockQty: g.lightBlockQty + cur.lightBlockQty,
            materials: reducedMats,
          };
        } else {
          return g;
        }
      });
    } else {
      groups.push({ ...cur, qty: 1 });
    }
    return groups;
  }, []);

  // const matsWithInv = materials.map(mat => {
  //   const invItem = inventory?.find(i => i.id == mat.id);

  //   return {
  //     ...mat,
  //     invItem,
  //   };
  // });

  if (isNullish(Width) || isNullish(Height)) {
    return null;
  }

  return (
    <div className="p-2 mt-2 bg-white rounded-md">
      {groupedPanels.map((gp, i) => {
        const isLastIndex = groupedPanels.length - 1 == i;
        return (
          <Group
            key={i}
            opening={opening}
            name={name}
            FrameType={FrameType}
            Frames={Frames}
            TPosts={TPosts}
            layoutDeduction={layoutDeduction}
            openingScopes={openingScopes}
            tiers={tiers}
            group={gp}
            isLastIndex={isLastIndex}
            index={i}
            sectionPanels={sectionPanels}
            Sills={Sills}
            OW={OW}
            jobNum={jobNum}
            multipleLoc={multipleLoc}
            multipleGroup={groupedPanels.length > 1}
            locationIndex={locationIndex}
            Uchannel={Uchannel}
          />
        );
      })}

      {/* Pelmet */}
      {Headboard &&
        Pelmet &&
        (type == "Sliding" || type == "Track Bi-fold") && (
          <PelmetDrawing
            opening={opening}
            TrackSizes={TrackSizes}
            pelmet={Pelmet}
            options={options}
            name={name}
            colour={colour}
            SideBoardH={SideBoardH}
            deductions={deductions}
            jobNum={jobNum}
          />
        )}
    </div>
  );
};

interface groupProps {
  opening: opening;
  name: string;
  Frames: any;
  FrameType: any;
  layoutDeduction: DeductionType | undefined;
  TPosts: number[] | undefined;
  tiers: number[] | undefined;
  openingScopes: any;
  group: any;
  isLastIndex: boolean;
  index: number;
  sectionPanels: any;
  Sills?: multiValue[];
  OW: any;
  jobNum: string;
  multipleLoc: boolean;
  locationIndex: number;
  multipleGroup: boolean;
  Uchannel?: option;
}

const Group = ({
  opening,
  name,
  Frames,
  FrameType,
  layoutDeduction,
  TPosts,
  tiers,
  openingScopes,
  group,
  isLastIndex,
  index,
  Sills,
  OW,
  jobNum,
  sectionPanels,
  locationIndex,
  multipleLoc,
  multipleGroup,
  Uchannel,
}: groupProps) => {
  const {
    width,
    height,
    Blades,
    MidRailLocs,
    TR,
    BR,
    qty,
    deMouldQty,
    lightBlockQty,
    bladeWidth,
    RailWidth,
    tubeLength,
    stileLength,
    // holestrips,
  } = group;

  const bladeSize =
    // @ts-ignore
    opening?.options?.find(o => o.name == "Blade")?.scope?.value;

  const boldNames = ["colour", "hinge type", "hinge colour"];

  const Note = opening.note;

  const indexToDisplay = `${multipleLoc ? `${locationIndex}.` : ""}${
    opening.index
  }${multipleGroup ? `_${index + 1}` : ""}`;

  const PH = height;

  const sections = () => {
    const start = 0;
    const end = Blades ? Blades?.length - 1 : 0;
    const qty = end - start + 1;
    const defaultSections = [
      {
        start,
        end,
        qty,
      },
    ];

    if (!PH || !TR || !BR || !Blades) {
      return [];
    }
    try {
      if (MidRailLocs.length > 0) {
        let sortedBlades = sort(Blades || []).asc() as number[];

        const newSections = [];

        for (let i = 0; i < MidRailLocs.length + 1; i++) {
          if (i == 0) {
            const start = 0;
            const end = sortedBlades.findIndex(
              b =>
                b ==
                sort(sortedBlades.filter(b => b < MidRailLocs[0])).desc()[0]
            );
            const qty = end - start + 1;

            newSections.push({
              start,
              end,
              qty,
            });
            continue;
          }
          if (i == MidRailLocs.length) {
            const start = sortedBlades.findIndex(
              b =>
                b ==
                sort(
                  sortedBlades.filter(
                    b => b > MidRailLocs[MidRailLocs.length - 1]
                  )
                ).asc()[0]
            );
            const end = sortedBlades.findIndex(
              b => b == sort(sortedBlades).desc()[0]
            );
            const qty = end - start + 1;
            newSections.push({
              start,
              end,
              qty,
            });
            continue;
          }
          const start = sortedBlades.findIndex(
            b =>
              b ==
              sort(sortedBlades.filter(b => b > MidRailLocs[i - 1])).asc()[0]
          );
          const end = sortedBlades.findIndex(
            b =>
              b == sort(sortedBlades.filter(b => b < MidRailLocs[i])).desc()[0]
          );
          const qty = end - start + 1;
          newSections.push({
            start,
            end,
            qty,
          });
        }

        return newSections;
      } else {
        return defaultSections;
      }
    } catch (error) {
      console.log(error);
      return defaultSections;
    }
  };

  const defaultQty = (start: number, qty: number) => {
    if (qty > 13) {
      return [
        {
          position: 0,
          qty: Math.ceil(qty / 2),
        },
        {
          position: 1,
          qty: Math.floor(qty / 2),
        },
      ];
    }
    return [
      {
        position: start,
        qty: qty,
      },
    ];
  };

  const mySections = sections()
    .map(section => {
      const mySectionPanel = sectionPanels && sectionPanels[group.id]?.section;
      const tiltrods = (
        mySectionPanel
          ? mySectionPanel[section.start]?.tiltrods
          : defaultQty(section.start || 0, section.qty)
      )
        // @ts-ignore
        ?.filter(t => t.qty !== 0);
      return {
        ...section,
        tiltrods,
      };
    })
    .filter(s => s.qty > 0 && s.tiltrods?.length > 0);

  return (
    <>
      <div
        className="flex flex-col w-full overflow-hidden gap-2"
        style={{ height: `${pageHeight}mm` }}
      >
        {/* Meta Data */}
        <div className="flex flex-row justify-end">
          <table className="border-t-[1px] border-l-[1px] border-gray-300">
            <thead>
              <tr>
                <th className="text-left border-r-[1px] border-b-[1px] border-gray-300 px-2">
                  #{indexToDisplay}
                </th>
                <th className="text-left border-r-[1px] border-b-[1px] border-gray-300 px-2">
                  {jobNum}
                </th>
                <th className="text-center border-r-[1px] border-b-[1px] border-gray-300 px-2">
                  {name}
                </th>
                <th
                  className={`text-center border-r-[1px] border-b-[1px] border-gray-300 px-2`}
                >
                  Opening Summary
                </th>
              </tr>
            </thead>
          </table>
        </div>
        {/* Summary */}
        <div className="flex flex-row w-full border-[2px] border-t-[1px] border-r-[1px] border-gray-300 flex-wrap">
          {opening.options?.map(o => {
            if (o.name == "Note") {
              return null;
            }
            if (o.noCalc) {
              return null;
            }
            if (o.type == "param") {
              return null;
            }
            if (nullishValues.includes(o.value)) {
              return null;
            }

            if (Array.isArray(o.value)) {
              if (o.value.every(val => nullishValues.includes(val))) {
                return null;
              }
            }

            if (o.value)
              if (typeof o.value == "object" && !o.displayValue) {
                return null;
              }
            return (
              <div
                key={o.id}
                className="flex-1 flex flex-col border-r-[1px] border-t-[1px] border-gray-300 min-w-fit"
              >
                <div className="px-[3px] text-center flex-1 border-b-[1px] border-gray-300 font-semibold text-xs">
                  {o.name}
                </div>
                <div
                  className={`px-[3px] flex-1 text-center text-xs  ${
                    boldNames.includes(o.name.toLowerCase()) && "font-semibold"
                  }`}
                >
                  {!o.noCalc &&
                    (o.displayValue ||
                      (typeof o.value == "object" ? "" : o.value))}
                </div>
              </div>
            );
          })}
        </div>
        {/* Panel, Layout Preview */}
        <div className="flex flex-row w-full flex-1 flex-wrap gap-14 select-text overflow-clip">
          <div className="py-5 flex flex-row h-full">
            <Panel
              bladeSize={bladeSize}
              averagePW={width}
              panel={group}
              sections={mySections}
            />
          </div>
          {/* Layout and Frame */}
          <div className="flex flex-1 flex-row py-10 px-10 self-stretch">
            <LayoutAndFrame
              Uchannel={Uchannel}
              FrameType={FrameType}
              Frames={Frames}
              TPosts={TPosts}
              layoutDeduction={layoutDeduction}
              openingScopes={openingScopes}
              tiers={tiers}
              Sills={Sills}
              OW={OW}
              textBG="bg-white"
              noDark={true}
            />
          </div>
        </div>
        {/* Specs */}
        <div className="flex flex-row w-full gap-1 h-28 mt-2">
          {/* Left */}
          <div className="flex flex-row flex-1 h-28 border-[1px] border-t-[1px] border-r-[1px] border-gray-300 flex-wrap">
            {/* Header */}
            <div className="flex-1 flex flex-col border-gray-300 min-w-fit text-center font-semibold">
              <div className="px-[3px] py-[1px] flex flex-col justify-center border-b-[1px] border-gray-300">
                Parts
              </div>
              <div className="px-[3px] flex flex-col justify-center border-b-[1px] border-gray-300 flex-1">
                Cut Length
              </div>
              <div className="px-[3px] flex flex-col justify-center border-b-[1px] border-gray-300 flex-1">
                Qty
              </div>
              <div className="px-[3px] flex flex-col justify-center border-gray-300 flex-1">
                Other
              </div>
            </div>
            {/* Blade */}
            <div className="flex-1 flex flex-col border-l-[1px] border-gray-300 min-w-fit text-center">
              <div className="px-[3px] flex flex-col justify-center py-[1px] border-b-[1px] border-gray-300 font-semibold">
                Blade
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {roundUp(bladeWidth)}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {Blades?.length || 0 * qty}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-gray-300 flex-1">
                {Blades?.length || 0}
              </div>
            </div>
            {/* Square */}
            <div className="flex-1 flex flex-col border-l-[1px] border-gray-300 min-w-fit text-center">
              <div className="px-[3px] flex flex-col justify-center py-[1px] border-b-[1px] border-gray-300 font-semibold">
                Square
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {roundUp(stileLength)}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {qty * 2 - deMouldQty}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-gray-300 flex-1">
                {/* {Blades.length} */}
              </div>
            </div>
            {/* Dmould */}
            <div className="flex-1 flex flex-col border-l-[1px] border-gray-300 min-w-fit text-center">
              <div className="px-[3px] flex flex-col justify-center py-[1px] border-b-[1px] border-gray-300 font-semibold">
                Dmould
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {deMouldQty > 0 && roundUp(stileLength)}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {deMouldQty > 0 && deMouldQty}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-gray-300 flex-1">
                {/* {Blades.length} */}
              </div>
            </div>
            {/* T-Rail */}
            <div className="flex-1 flex flex-col border-l-[1px] border-gray-300 min-w-fit text-center">
              <div className="px-[3px] flex flex-col justify-center py-[1px] border-b-[1px] border-gray-300 font-semibold">
                T-Rail
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {roundUp(RailWidth)}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {qty}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-gray-300 flex-1">
                {roundUp(TR)}
              </div>
            </div>
            {/* B-Rail */}
            <div className="flex-1 flex flex-col border-l-[1px] border-gray-300 min-w-fit text-center">
              <div className="px-[3px] flex flex-col justify-center py-[1px] border-b-[1px] border-gray-300 font-semibold">
                B-Rail
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {roundUp(RailWidth)}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {qty}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-gray-300 flex-1">
                {roundUp(BR)}
              </div>
            </div>
            {/* Mid */}
            <div className="flex-1 flex flex-col border-l-[1px] border-gray-300 min-w-fit text-center">
              <div className="px-[3px] flex flex-col justify-center py-[1px] border-b-[1px] border-gray-300 font-semibold">
                Mid
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {MidRailLocs && MidRailLocs.length > 0 && roundUp(RailWidth)}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {MidRailLocs &&
                  MidRailLocs.length > 0 &&
                  MidRailLocs.length * qty}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-gray-300 flex-1"></div>
            </div>
            {/* LightBlock */}
            {lightBlockQty > 0 && (
              <div className="flex-1 flex flex-col border-l-[1px] border-gray-300 min-w-fit text-center">
                <div className="px-[3px] flex flex-col justify-center py-[1px] border-b-[1px] border-gray-300 font-semibold">
                  LightBlock
                </div>
                <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                  {roundUp(stileLength)}
                </div>
                <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                  {lightBlockQty}
                </div>
                <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-gray-300 flex-1"></div>
              </div>
            )}
            {/* G-Tube */}
            <div className="flex-1 flex flex-col border-l-[1px] border-gray-300 min-w-fit text-center">
              <div className="px-[3px] flex flex-col justify-center py-[1px] border-b-[1px] border-gray-300 font-semibold">
                G-Tube
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {roundUp(tubeLength)}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {qty * 2 - deMouldQty}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-gray-300 flex-1"></div>
            </div>
            {/* S-Tube */}
            <div className="flex-1 flex flex-col border-l-[1px] border-gray-300 min-w-fit text-center">
              <div className="px-[3px] flex flex-col justify-center py-[1px] border-b-[1px] border-gray-300 font-semibold">
                S-Tube
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {deMouldQty > 0 && roundUp(tubeLength)}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                {deMouldQty > 0 && deMouldQty}
              </div>
              <div className="px-[3px] flex flex-col justify-center text-lg font-semibold border-gray-300 flex-1"></div>
            </div>
          </div>

          {/* Right */}
          <div className="flex flex-row h-28 border-[1px] border-t-[1px] border-r-[1px] border-gray-300 flex-wrap">
            {/* Header */}
            <div className="flex-1 flex flex-col border-gray-300 min-w-fit text-center font-semibold">
              <div className="px-[8px] flex flex-col justify-center py-[1px] border-b-[1px] border-gray-300 text-transparent">
                HS
              </div>
              <div className="px-[8px] flex flex-col justify-center border-b-[1px] border-gray-300 flex-1">
                Hole
              </div>
              <div className="px-[8px] flex flex-col justify-center border-b-[1px] border-gray-300 flex-1">
                Tilt Top
              </div>
              <div className="px-[8px] flex flex-col justify-center border-gray-300 flex-1">
                Tilt Bot
              </div>
            </div>
            {/* Holes & Titls */}
            {mySections &&
              sort(mySections)
                .desc("start")
                .map((section, i) => {
                  // @ts-ignore
                  const tiltRods = sort(section.tiltrods).desc("position");
                  return (
                    <div
                      key={i}
                      className="flex-1 flex flex-col border-l-[1px] border-gray-300 min-w-fit text-center"
                    >
                      <div className="px-[8px] flex flex-col justify-center py-[1px] border-b-[1px] border-gray-300 font-semibold">
                        {i == 0 ? (
                          "Top"
                        ) : (
                          <>{i == sections().length - 1 ? "Bot" : "Mid"}</>
                        )}
                      </div>
                      <div className="px-[8px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                        {section?.qty}
                      </div>
                      <div className="px-[8px] flex flex-col justify-center text-lg font-semibold border-b-[1px] border-gray-300 flex-1">
                        {
                          // @ts-ignore
                          tiltRods[0]?.qty
                        }
                      </div>
                      <div className="px-[8px] flex flex-col justify-center text-lg font-semibold border-gray-300 flex-1">
                        {
                          // @ts-ignore
                          tiltRods[1] && tiltRods[1]?.qty
                        }
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        {/* Note */}
        <div className="flex flex-col border-[1px] border-gray-300">
          <textarea
            defaultValue={Note}
            className="border-none text-sm"
            placeholder="Note / Special Request"
          />
        </div>
      </div>
      {!isLastIndex && (
        <>
          <div className="h-10 w-full no-print"></div>
          <div className="page-break hidden" />
        </>
      )}
    </>
  );
};

interface PanelProps {
  panel: any;
  averagePW: number;
  setPanelDPWidth?: React.Dispatch<React.SetStateAction<number>>;
  index?: number;
  sections: any[];
  bladeSize: number | string;
}

const Panel = ({
  panel,
  averagePW,
  // setPanelDPWidth,
  // index,
  sections,
  bladeSize,
}: PanelProps) => {
  if (!panel) {
    return null;
  }

  const { TR, BR } = panel;
  const PH = panel.Panel?.PH;
  const MidRailLocs = panel.MidRailLocs as number[];
  const Blades = (panel.Blades as number[]) || [];

  if (!PH || !TR || !BR || !Blades) {
    return null;
  }

  const [height, setHeight] = useState(0);

  const observedDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!observedDiv.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      const newHeight = observedDiv.current?.offsetHeight;
      if (newHeight && newHeight !== height) {
        setHeight(newHeight || 0);
      }
    });

    resizeObserver.observe(observedDiv.current);

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [observedDiv.current]);

  const scale = PH / height < 2.5 ? 2.5 : PH / height;
  const dpHeight = PH / scale;

  const scaledBladeSize = (Number(bladeSize) * 0.77) / scale;

  // console.log(sections);

  return (
    <div className="flex flex-col justify-center" ref={observedDiv}>
      {scale && (
        <div
          className="flex flex-row gap-2 self-end"
          style={{
            height: `${dpHeight}px`,
          }}
        >
          {/* Panel Height */}
          <div className="py-[1px] flex flex-row self-stretch">
            <div className="flex flex-col justify-center -mr-[10px] z-10">
              <div className="bg-white">{PH}</div>
            </div>
            <div className="w-2 border-t-[1px] border-b-[1px] border-l-[1px] h-full border-black dark:border-gray-400" />
          </div>
          {/* Panel */}
          <div
            className="border-2 relative flex flex-row border-black dark:border-gray-400 self-stretch"
            style={{
              width: `${averagePW / scale}px`,
            }}
          >
            {/* Panel Width */}
            <div
              className="absolute w-full
              flex flex-row justify-center
            h-2 -bottom-2 translate-y-full border-b-[1px] border-x-[1px] border-black
            "
            >
              <div className="bg-white absolute text-xs">
                {roundUp(averagePW)}
              </div>
            </div>
            {/* Rails */}
            <div className="bg-white" style={{ width: `${40 / scale}px` }} />
            <div className="relative flex-1 border-r-2 border-l-2 dark:bg-gray-400 border-black dark:border-gray-400">
              {/* TopRail */}
              <div
                className="z-10 bg-white dark:text-black absolute top-0 border-b-2 flex justify-center items-center w-full border-black dark:border-gray-400"
                style={{ height: `${TR / scale}px` }}
              >
                {Number(TR).toFixed(1).replace(".0", "")}
              </div>
              {/* BottomRail */}
              <div
                className="z-10 bg-white dark:text-black absolute bottom-0 border-t-2 flex justify-center items-center w-full border-black dark:border-gray-400"
                style={{ height: `${BR / scale}px` }}
              >
                {Number(BR).toFixed(1).replace(".0", "")}
              </div>

              {/* MidRails */}
              {MidRailLocs?.map(m => (
                <div
                  key={m}
                  className="z-10 bg-red-500 absolute text-white border-t-2 border-b-2 border-black dark:border-gray-400 flex justify-center items-center w-full translate-y-1/2"
                  style={{
                    bottom: `${m / scale}px`,
                    height: `${82 / scale}px`,
                  }}
                >
                  {Number(m).toFixed(1).replace(".0", "")}
                </div>
              ))}

              {/* Blades */}
              {Blades?.map(blade => (
                <div
                  key={blade}
                  className="bg-white text-gray-300 absolute translate-y-3 text-xs border-t-[1px] flex justify-center items-center w-full border-black dark:border-gray-400"
                  style={{
                    bottom: `${blade / scale}px`,
                    height: `${scaledBladeSize}px`,
                  }}
                >
                  {Number(blade).toFixed(1).replace(".0", "")}
                </div>
              ))}
            </div>
            <div className="bg-white" style={{ width: `${40 / scale}px` }} />
            {/* Sections */}
            {sections?.map((section, i) => (
              <Section
                key={i}
                Blades={Blades}
                section={section}
                scale={scale}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

interface sectionProps {
  section: any;
  Blades: number[];
  scale: number;
}

const Section = ({ section, Blades, scale }: sectionProps) => {
  const tiltRods: any[] = section.tiltrods;
  const sortedBlades = sort(Blades || []).asc();

  if (!tiltRods || !section.end) {
    return null;
  }

  return (
    <>
      {tiltRods?.map((t, i) => {
        let startPosition = 0;
        let endPosition = 0;

        if (t.position == 0) {
          startPosition = sortedBlades[section.start];
          endPosition = sortedBlades[section.start + t.qty - 1];
        } else {
          endPosition = sortedBlades[section.end];
          startPosition = sortedBlades[section.end - t.qty + 1];
        }

        let height = `${(endPosition - startPosition) / scale}px`;
        let bottom = `${startPosition / scale}px`;

        if (endPosition - startPosition == 0) {
          return null;
        }
        return (
          <div
            key={t.position}
            className="absolute w-2 border-y-[1px] border-r-[1px] -right-5 translate-x-full border-black "
            style={{
              bottom,
              height,
            }}
          >
            <div
              className={`absolute flex flex-row items-center group top-1/2 -right-1 translate-x-full -translate-y-1/2`}
            >
              {t.qty.toString()}
            </div>
          </div>
        );
      })}
    </>
  );
};

interface PelmetProps {
  pelmet: any;
  options: option[];
  TrackSizes: number[];
  name: string;
  colour: string;
  opening: opening;
  deductions?: DeductionType[];
  SideBoardH: number;
  jobNum: string;
}

const PelmetDrawing = ({
  opening,
  pelmet,
  options,
  TrackSizes,
  name,
  colour,
  SideBoardH,
  deductions,
  jobNum,
}: PelmetProps) => {
  const {
    defaultDepth,
    back,
    backExt,
    front,
    frontExt,
    depth,
    depthExt,
    leftCorner,
    leftIn,
    rightIn,
    rightCorner,
  } = pelmet;

  const TrackQty = options?.find(o => o.name == "TrackQty")?.value;
  const Pass = options?.find(o => o.name == "Pass")?.value;

  const PassOverride = Number(
    options?.find(o => o.name == "PassOverride")?.value
  );

  const TrackGap =
    !isNaN(PassOverride) && !isNullish(PassOverride, true)
      ? PassOverride
      : Pass == "Open"
      ? 96
      : 36;
  // const BackTrack = options?.find(o => o.name == "PelmetDimension")?.value
  //   ?.tracks;
  const Tracks = Array(Number(TrackQty) || 1).fill(0);

  // const leftExt = Number(
  //   options?.find(o => o.name == "PelmetDimension")?.value?.left?.trackExt
  // );
  // const SBLocation = options?.find(o => o.name == "SBLocation")?.value;
  const Mount = options?.find(o => o.name == "Mount")?.value;
  const isSemiReveal = Number(Mount) === 34;

  const [width, setWidth] = useState(0);

  const observedDiv = useRef<HTMLDivElement>(null);

  const TopTrack = deductions?.find(
    d => d.id == Number(options?.find(o => o.name == "TopTrack")?.value)
  );
  const BottomTrack = deductions?.find(
    d => d.id == Number(options?.find(o => o.name == "BottomTrack")?.value)
  );
  const BottomOverrides = Array.isArray(
    options?.find(o => o.name == "BottomOverride")?.value
  )
    ? options?.find(o => o.name == "BottomOverride")?.value
    : [];
  const SideBoards = options?.find(o => o.name == "SideBoard")?.value;

  useEffect(() => {
    if (!observedDiv.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (observedDiv.current?.offsetWidth !== width) {
        setWidth(observedDiv.current?.offsetWidth || 0);
      }
    });

    resizeObserver.observe(observedDiv.current);

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [observedDiv.current]);

  const scale = width / front;
  const scaled = (number: number) => {
    return Number((number * scale).toFixed(4));
  };

  const Returns = options?.find(o => o.name == "Returns")?.value;
  const Facia = options?.find(o => o.name == "Facia")?.value;

  return (
    <>
      <div className="h-10 w-full -ml-5 no-print"></div>
      <div className="page-break" />
      <div
        className="flex flex-col gap-2 pt-5"
        style={{ height: `${pageHeight}mm` }}
      >
        {/* Header */}
        <div className="flex flex-row justify-end">
          <table className="border-t-[1px] border-l-[1px] border-gray-300">
            <thead>
              <tr>
                <th className="text-left border-r-[1px] border-b-[1px] border-gray-300 px-2">
                  #{opening.index}
                </th>
                <th className="text-left border-r-[1px] border-b-[1px] border-gray-300 px-2">
                  {jobNum}
                </th>
                <th className="text-left border-r-[1px] border-b-[1px] border-gray-300 px-2">
                  {name}
                </th>
                <th className="text-left border-r-[1px] border-b-[1px] border-gray-300 px-2">
                  Pelmet Order
                </th>
              </tr>
            </thead>
          </table>
        </div>
        {/* Specs */}
        <div className="flex flex-row w-full border-[2px] border-t-[1px] border-r-[1px] border-gray-300 flex-wrap">
          {opening.options
            ?.filter(o => {
              const names = [
                "W",
                "H",
                "Type",
                "Colour",
                "Mount",
                "TrackQty",
                "Layout",
                "Pass",
                "PassOverride",
                "SideBoard",
                "Facia",
              ];
              return names.includes(o.name);
            })
            ?.map(o => {
              if (o.noCalc) {
                return null;
              }
              if (nullishValues.includes(o.value)) {
                return null;
              }

              if (Array.isArray(o.value)) {
                if (o.value.every(val => nullishValues.includes(val))) {
                  return null;
                }
              }

              if (o.value)
                if (typeof o.value == "object" && !o.displayValue) {
                  return null;
                }
              return (
                <div
                  key={o.id}
                  className="flex-1 flex flex-col border-r-[1px] border-t-[1px] border-gray-300 min-w-fit"
                >
                  <div className="px-[3px] flex flex-1 border-b-[1px] border-gray-300 font-semibold">
                    {o.name}
                  </div>
                  <div className="px-[3px] flex-1">
                    {!o.noCalc &&
                      (o.displayValue ||
                        (typeof o.value == "object" ? "" : o.value))}
                  </div>
                </div>
              );
            })}
        </div>
        {/* Side View */}
        <div className="relative flex flex-row gap-2 self-center justify-center items-end pt-5 my-10 ml-2">
          <div
            className={`absolute w-4 ${
              Number(Mount) == 13 || isSemiReveal
                ? "h-20 left-0 -translate-x-full"
                : "h-28 -left-2"
            } border-2 border-black  bg-white`}
          />
          <div className="h-20 flex flex-col items-start z-10">
            <div
              className="h-5 border-2 border-black  bg-white"
              style={{
                width: depth,
              }}
            />
            <div className="flex flex-row">
              {Tracks.map((t, i) => {
                const gap = i === 0 ? 20 : TrackGap - 26;
                const trackWidth = 26;

                return (
                  <div key={i} className="flex flex-row">
                    <div className="relative" style={{ width: gap }}></div>
                    <div
                      className="border-x-4 border-t-4 border-double border-grass h-6"
                      style={{ width: trackWidth }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
          {Facia == "Both" && (
            <div
              className={`absolute w-4 ${
                Number(Mount) == 13 || isSemiReveal
                  ? "h-20 right-0 translate-x-full"
                  : "h-28 -right-2"
              } border-2 border-black  bg-white`}
            />
          )}
        </div>
        {/* Drawing */}
        <div className="w-full flex flex-row gap-2 my-10 pr-5">
          <div className="flex flex-row items-center gap-2 self-stretch">
            <div>{depthExt}</div>
            <div className="w-2 h-full border-y-[1px] border-l-[1px] border-black " />
          </div>

          <div className="relative w-full flex flex-col h-20">
            {/* SemiReveal */}
            {isSemiReveal && (
              <div
                className={`${Returns.includes("L") && "pl-2"} ${
                  Returns.includes("R") && "pr-2"
                } flex flex-row justify-center flex-1 -mb-[2px]`}
              >
                <div className="w-10 h-full border-b-2 border-black relative flex flex-row justify-center">
                  <div className="absolute bottom-1">{leftCorner}</div>
                </div>
                <div className="flex-1 h-full border-x-2 border-t-2 border-black flex flex-row justify-between pt-1 px-2">
                  <div>{leftIn}</div>
                  <div>{back}</div>
                  <div>{rightIn}</div>
                </div>
                <div className="w-10 h-full border-b-2 border-black relative flex flex-row justify-center">
                  <div className="absolute bottom-1">{rightCorner}</div>
                </div>
              </div>
            )}
            {/* Default Drawing  */}
            <div
              className={`
            flex-[2] flex flex-row
            `}
            >
              {/* Left Return */}
              <div
                className={`${
                  Returns?.includes("L") ? "block" : "hidden"
                } w-2 h-full border-l-2 border-y-2 border-black bg-white`}
              ></div>
              {/* Board, Facia */}
              <div
                className={`flex flex-col flex-1 h-full border-b-2 border-black border-x-2 ${
                  isSemiReveal ? "border-t-0" : "border-t-2"
                }`}
              >
                {Facia == "Both" && (
                  <div className="h-2 border-b-2 border-black relative bg-white">
                    {Returns?.includes("L") && (
                      <div className="absolute h-full w-2 left-[3px] top-0 -translate-x-full bg-white border-l-2 border-black skew-x-[45deg] z-10" />
                    )}
                    {Returns?.includes("R") && (
                      <div className="absolute h-full w-2 right-[3px] top-0 translate-x-full bg-white border-r-2 border-black skew-x-[-45deg] z-10" />
                    )}
                  </div>
                )}
                {isSemiReveal && (
                  <div className="absolute left-40 h-full flex flex-col justify-center font-semibold border-l-[1px] border-black top-0 pl-2">
                    <div className="border-black border-t-[1px] border-r-[1px] inline-block p-[3px] rotate-[-45deg] absolute top-1 left-0 -translate-x-1/2" />
                    <div
                      className={`border-black border-t-[1px] border-r-[1px] inline-block p-[3px] rotate-[135deg] absolute ${
                        Facia == "Yes" ? "bottom-3" : "bottom-1"
                      } left-0 -translate-x-1/2`}
                    />
                    {depth}
                  </div>
                )}
                <div className="flex-1 flex flex-row justify-center items-end p-1 relative">
                  <div className="absolute left-1 h-full flex flex-col justify-center font-semibold">
                    {!isSemiReveal &&
                      depth - (Facia == "Yes" ? 5 : Facia == "Both" ? 10 : 0)}
                  </div>
                  {/* <div className="flex-1 border-b-[1px] mb-2 border-black"></div> */}
                  <div className="px-1 font-semibold">
                    {!isSemiReveal ? front + Returns.length * -5 : front}
                  </div>
                  {/* <div className="flex-1 border-b-[1px] mb-2 border-black"></div> */}
                </div>
                {(Facia == "Yes" || Facia == "Both") && (
                  <div className="h-2 border-t-2 border-black relative bg-white">
                    {Returns?.includes("L") && (
                      <div className="absolute h-full w-2 left-[3px] top-0 -translate-x-full bg-white border-l-2 border-black skew-x-[-45deg] z-10" />
                    )}
                    {Returns?.includes("R") && (
                      <div className="absolute h-full w-2 right-[3px] top-0 translate-x-full bg-white border-r-2 border-black skew-x-[45deg] z-10" />
                    )}
                  </div>
                )}
              </div>
              {/* Right Return */}
              <div
                className={`${
                  Returns?.includes("R") ? "block" : "hidden"
                } w-2 h-full border-r-2 border-y-2 border-black bg-white`}
              ></div>
            </div>
            <div className="absolute flex flex-row justify-center -bottom-4 w-full h-2 border-x-[1px] border-b-[1px] border-black">
              <div className="absolute -bottom-2 translate-y-full">
                {frontExt}
              </div>
            </div>
          </div>
        </div>
        {defaultDepth > depth && (
          <div className="text-red-500">
            WARNING : inner depth is smaller than minimum : {defaultDepth}
          </div>
        )}

        {/* Parts */}
        <div className="flex flex-col w-full border-l-[1px] border-t-[1px] border-gray-300 mt-10">
          <table>
            <thead>
              <tr className="border-b-[2px] border-gray-300">
                <th className="border-r-[1px] border-gray-300 px-2 py-1">
                  Parts
                </th>
                <th className="border-r-[1px] border-gray-300 px-2 py-1">
                  Kinds
                </th>
                <th className="border-r-[1px] border-gray-300 px-2 py-1">
                  Length
                </th>
                <th className="border-r-[1px] border-gray-300 px-2 py-1">
                  Depth
                </th>
                <th className="border-r-[1px] border-gray-300 px-2 py-1">
                  Qty
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b-[1px] border-gray-300">
                <td className="border-r-[1px] border-gray-300 px-2 py-1 text-lg font-semibold text-center">
                  Top
                </td>
                <td className="border-r-[1px] border-gray-300 px-2 py-1 text-lg font-semibold text-center">
                  {TopTrack?.name}
                </td>
                <td className="border-r-[1px] border-gray-300 px-2 py-1 text-lg font-semibold text-center">
                  {[...new Set(TrackSizes)].join(", ")}
                </td>
                <td className="border-r-[1px] border-gray-300 px-2 py-1 text-lg font-semibold text-center"></td>
                <td className="border-r-[1px] border-gray-300 px-2 py-1 text-lg font-semibold text-center">
                  {TrackSizes.length}
                </td>
              </tr>
              <tr className="border-b-[1px] border-gray-300">
                <td className=" text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                  Bottom
                </td>
                <td className=" text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                  {BottomTrack?.name}
                </td>
                <td className=" text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                  {[...new Set(TrackSizes)].join(", ")}
                </td>
                <td className=" text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1"></td>
                <td className=" text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                  {TrackSizes.length -
                    BottomOverrides.filter(
                      // @ts-ignore
                      o => !nullishValues.includes(o.value)
                    ).length}
                </td>
              </tr>
              {/* Bottom Overrides */}
              {BottomOverrides.map(
                // @ts-ignore
                (bot, i) => {
                  if (nullishValues.includes(bot.value)) {
                    return null;
                  }
                  return (
                    <tr key={i} className="border-b-[1px] border-gray-300">
                      <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                        Bottom Override
                      </td>
                      <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                        {
                          deductions?.find(d => d.id == Number(bot?.value))
                            ?.name
                        }
                      </td>
                      <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                        {TrackSizes[i]}
                      </td>
                      <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1"></td>
                      <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                        1
                      </td>
                    </tr>
                  );
                }
              )}
              {/* HeadBoard */}

              <tr className="border-b-[1px] border-gray-300">
                <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                  Head Board
                </td>
                {isSemiReveal ? (
                  <td
                    colSpan={4}
                    className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1"
                  >
                    Refer Drawing
                  </td>
                ) : (
                  <>
                    <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                      Board
                    </td>
                    <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                      {front}
                    </td>
                    <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                      {depth}
                    </td>
                    <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                      1
                    </td>
                  </>
                )}
              </tr>

              {/* SideBoards */}
              {SideBoards?.length > 0 && (
                <tr className="border-b-[1px] border-gray-300">
                  <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                    Side Board
                  </td>
                  <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                    Board
                  </td>
                  <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                    {SideBoardH}
                  </td>
                  <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                    {depth - 15}
                  </td>
                  <td className="text-lg font-semibold text-center border-r-[1px] border-gray-300 px-2 py-1">
                    {SideBoards?.length}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default OpeningDetail;
