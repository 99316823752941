import { Select, TextInput } from "flowbite-react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useQuoteStore } from "../../../../../../store/quoteStore";
import { ChangeEventHandler, useEffect } from "react";
import { installSalesParams } from "../../../../settings/quote/types";
import CheckboxWithLabel from "../../../../../../comps/CheckboxWithLabel";
import { usePub } from "../../../../../../utils/pubsub/pubsub";
import {
  INSTALL_PARAM_UPDATE_ARGS,
  orderEvent,
  QUOTE_OPTION_UPDATE_ARGS,
} from "../../../../../../utils/pubsub/orderEventArgs";

interface props {
  coord: string;
  productSetId: number;
  showPrice: boolean;
  openingId: string;
  itemId?: string;
  locked?: boolean;
}
export default function QuoteOptions({
  coord,
  productSetId,
  showPrice,
  openingId,
  itemId,
  locked,
}: props) {
  const { register, setValue, control, getValues } = useFormContext();

  const paramsCoord = `${coord}.salesParams`;
  const { quoteParams } = useQuoteStore();

  const dbInstallParam = quoteParams.find(
    p => p.name == "salesParams" && p.productSetId == productSetId
  );

  const installParams: installSalesParams[] = useWatch({
    name: `${paramsCoord}.installParams`,
    control,
  });

  useEffect(() => {
    if (locked) return;
    if (!installParams && dbInstallParam?.value) {
      const installParams = dbInstallParam?.value as installSalesParams[];
      setValue(
        `${paramsCoord}.installParams`,
        installParams.map(param => ({
          ...param,
          value: param.default,
        }))
      );
    }
  }, [installParams, dbInstallParam]);

  const supOnly = useWatch({
    name: `${paramsCoord}.supplyOnly`,
    control,
    defaultValue: false,
  });

  const orderType = getValues("type");

  const publish = usePub<QUOTE_OPTION_UPDATE_ARGS>();

  const myRegister = (coord: string) => {
    const onChange: ChangeEventHandler<
      HTMLInputElement | HTMLSelectElement
    > = e => {
      publish(orderEvent.QUOTE_OPTION_UPDATE, {
        openingId,
        itemId,
        orderEvent: orderEvent.QUOTE_OPTION_UPDATE,
      });

      setValue(coord, e.target.value);
    };

    return { ...register(coord), onChange };
  };

  const isRetail = orderType?.id !== 4;

  if (!showPrice || !isRetail) {
    return null;
  }

  return (
    <>
      <div className={`relative mr-1 group/option w-20  my-[5px] `}>
        <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
          Discount
        </div>
        <TextInput
          addon="$"
          className="w-full"
          {...myRegister(`${paramsCoord}.discount`)}
          type="number"
          placeholder="0"
          sizing="sm"
        />
      </div>
      <div className={`relative mr-1 group/option w-20  my-[5px] `}>
        <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
          Sup-Only
        </div>
        <Select sizing="sm" {...myRegister(`${paramsCoord}.supplyOnly`)}>
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </div>
      {!Number(supOnly) &&
        installParams?.map((param, i) => (
          <ItemInstallParam
            key={param.name}
            index={i}
            coord={paramsCoord}
            openingId={openingId}
            itemId={itemId}
            locked={locked}
          />
        ))}
    </>
  );
}
const ItemInstallParam = ({
  index,
  coord,
  openingId,
  itemId,
  locked,
}: {
  index: number;
  coord: string;
  openingId: string;
  itemId?: string;
  locked?: boolean;
}) => {
  const { control, getValues } = useFormContext();

  const publish = usePub<INSTALL_PARAM_UPDATE_ARGS>();

  const param = getValues(`${coord}.installParams.${index}`);
  return (
    <Controller
      name={`${coord}.installParams.${index}.value`}
      control={control}
      render={({ field }) => (
        <CheckboxWithLabel
          className="mr-1"
          name={param?.name}
          checked={field.value || false}
          disabled={locked}
          onChange={e => {
            field.onChange(e);
            publish(orderEvent.INSTALL_PARAM_UPDATE, {
              orderEvent: orderEvent.INSTALL_PARAM_UPDATE,
              openingId,
              itemId,
            });
          }}
        />
      )}
    />
  );
};
