import { useFormContext } from "react-hook-form";
import { ProductSetType } from "../../../../types";
import { option } from "../../../types";
import { useEffect, useRef } from "react";
import { useSub } from "../../../../../../../utils/pubsub/pubsub";
import {
  OPTION_UPDATE_ARGS,
  orderEvent,
} from "../../../../../../../utils/pubsub/orderEventArgs";
import { useDebouncedCallback } from "../../../../../../../utils/useDebounceCallback";

interface openingCalcs {
  openingCoord: string;
  product?: ProductSetType;
  openingId: string;
}

const OpeningCalcs = ({ openingCoord, product, openingId }: openingCalcs) => {
  const { getValues, setValue } = useFormContext();

  const isMounted = useRef(false);

  const update = () => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    const calcs = product?.calcs;
    if (!calcs || calcs.length < 1) {
      return;
    }

    const formOpeningOptions: option[] = getValues(`${openingCoord}.options`);

    const scopes = formOpeningOptions
      ?.filter(o => !o.noCalc)
      ?.map(o => ({
        name: o.name,
        scope: o.scope,
      }))
      ?.reduce<object>((obj, item) => {
        return {
          ...obj,
          [item.name]: item,
        };
      }, {});

    let openingCalcs = [];

    for (const calc of calcs) {
      // console.log(calc.name);
      try {
        const formula = eval(calc.formula);
        const res = formula(scopes);

        openingCalcs.push({ name: calc.name, value: res });
      } catch (error) {
        // console.log(calc.name, error);
        // console.log(scopes);
      }
    }
    setValue(`${openingCoord}.calcs`, openingCalcs);
  };

  const debouncedUpdate = useDebouncedCallback(update, 300, [openingId]);

  useEffect(() => {
    update();
  }, [product?.calcs]);

  useSub(
    orderEvent.OPTION_UPDATE,
    (args: OPTION_UPDATE_ARGS) => {
      if (args.openingId === openingId) {
        debouncedUpdate();
      }
    },
    [openingId]
  );

  return null;
};

export default OpeningCalcs;
