import { useFieldArray, useFormContext } from "react-hook-form";
import Calc from "./calc";
import { order } from "../../types";

interface props {
  locationIndex: number;
  openingIndex: number;
  itemIndex: number;
}

export default function Calcs({
  itemIndex,
  locationIndex,
  openingIndex,
}: props) {
  const { control } = useFormContext<order>();

  const { fields } = useFieldArray({
    name: `locations.${locationIndex}.openings.${openingIndex}.items.${itemIndex}.calcs`,
    control,
  });

  return (
    <>
      {fields.map((calc, index) => (
        <Calc
          key={calc.id}
          locationIndex={locationIndex}
          openingIndex={openingIndex}
          itemIndex={itemIndex}
          index={index}
          calc={calc}
          calcs={fields}
        />
      ))}
    </>
  );
}
