import { useFormContext, useWatch } from "react-hook-form";
import { TextInput } from "flowbite-react";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/solid";
import { location, option } from "../../../../types";
import { memo, useState } from "react";
import Pelmetover from "../pelmetover";
import Trackover from "../trackover";
import Multiple from "../multiple";
import Midrail from "../midrail";
import Division from "../division";
import SelectComp from "../../options/option/selectComps/select";
import MultiSelect from "../../options/multiSelect";
import MultiNameNumber from "../../options/multiNameNumber";
import { usePub } from "../../../../../../../../utils/pubsub/pubsub";
import { OPTION_UPDATE_ARGS } from "../../../../../../../../utils/pubsub/orderEventArgs";
import OptionsSideEffects from "./optionsSideEffect";
import OptionSideEffects from "./optionSideEffect";
import TextComp from "./TextComp";

interface optProps {
  locationIndex: number;
  openingIndex: number;
  index: number;
  openingId: string;
  option: option;
}

const OpeningOption = ({
  locationIndex,
  openingIndex,
  index,
  openingId,
  option,
}: optProps) => {
  const { register, control, setValue, getValues } = useFormContext();
  const optionsCoord = `locations.${locationIndex}.openings.${openingIndex}.options`;
  const optionCoord = `${optionsCoord}.${index}`;

  const optionValue = useWatch({
    control,
    name: `${optionCoord}.value`,
  });
  const optionType = useWatch({
    control,
    name: `${optionCoord}.type`,
    exact: true,
  });
  const optionDisplayName = useWatch({
    control,
    name: `${optionCoord}.displayName`,
    exact: true,
  });
  const optionName = useWatch({
    control,
    name: `${optionCoord}.name`,
    exact: true,
  });
  const isOptional = useWatch({
    control,
    name: `${optionCoord}.optional`,
    exact: true,
  });

  const optionId = getValues(`${optionCoord}.id`);

  const productIndex = `locations[${locationIndex}].openings[${openingIndex}].product`;

  const applyAll = () => {
    const proceed = confirm("apply option to all items?");
    if (!proceed) {
      return;
    }

    const allLocation: location[] = getValues("locations");
    const product: number = getValues(productIndex);

    setValue(
      "locations",
      allLocation.map(l => ({
        ...l,
        openings: l.openings.map(opening => ({
          ...opening,
          options: opening.options?.map(opt => {
            if (opt.name == optionName && opening.product == product) {
              return { ...opt, value: optionValue };
            } else {
              return opt;
            }
          }),
        })),
      }))
    );
  };

  const [focus, setFocus] = useState(false);
  const parentCheck = !useWatch({
    name: `${optionCoord}.noCalc`,
  });
  const showOptionals = useWatch({ name: "showOptionals", control });

  const publish = usePub<OPTION_UPDATE_ARGS>();
  return (
    <>
      {parentCheck && optionName?.toLowerCase() !== "note" && (
        <div
          className={`relative group/option min-w-[80px]
          ${!showOptionals && isOptional && "hidden"}
      ${optionType == "division" && !optionValue && "hidden"}
       ${optionType == "param" && "hidden"}`}
          onBlur={() => {
            setFocus(false);
          }}
          onFocus={() => {
            setFocus(true);
          }}
        >
          <div
            className={`text-grass w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none ${
              optionValue == undefined && !focus
                ? "left-2 top-2"
                : "left-[50%] -translate-x-[50%] -top-[8px]"
            }`}
          >
            {optionDisplayName || optionName}
          </div>
          {optionType == "multi-select" && (
            <MultiSelect
              optionCoord={optionCoord}
              openingId={openingId}
              optionId={optionId}
            />
          )}
          {optionType == "multi-name-number" && (
            <MultiNameNumber
              optionCoord={optionCoord}
              openingId={openingId}
              optionId={optionId}
            />
          )}
          {optionType == "multiple" && (
            <Multiple
              optionValue={optionValue}
              optionCoord={optionCoord}
              openingId={openingId}
              optionId={optionId}
            />
          )}
          {optionType == "select" && (
            <SelectComp
              optionsCoord={optionsCoord}
              productIndex={productIndex}
              disabled={false}
              openingId={openingId}
              optionId={optionId}
              optionName={optionName}
              optionCoord={optionCoord}
              option={option}
            />
          )}
          {(optionType == "number" || optionType == "text") && (
            <TextComp
              optionValueCoord={`${optionCoord}.value`}
              optionType={optionType}
              optionName={optionName}
              openingId={openingId}
              optionId={optionId}
            />
          )}
          {/* Shutter only options */}
          {optionType == "division" && (
            <Division
              optionValue={optionValue}
              optionCoord={optionCoord}
              optionsCoord={optionsCoord}
              openingId={openingId}
              optionId={optionId}
            />
          )}
          {optionType == "midrail" && (
            <Midrail
              optionValue={optionValue}
              optionCoord={optionCoord}
              optionsCoord={optionsCoord}
              openingId={openingId}
              optionId={optionId}
            />
          )}
          {optionType == "trackover" && (
            <Trackover
              optionType={optionType}
              optionValue={optionValue}
              optionsCoord={optionsCoord}
              optionCoord={optionCoord}
              openingId={openingId}
              optionId={optionId}
            />
          )}
          {optionType == "pelmetover" && (
            <Pelmetover
              optionsCoord={optionsCoord}
              register={register}
              optionCoordChained={optionCoord}
              openingId={openingId}
              optionId={optionId}
            />
          )}

          <div
            className="absolute right-1 top-1 hidden group-hover/option:block cursor-pointer hover:text-grass"
            onClick={applyAll}
          >
            <ClipboardDocumentListIcon className="w-5" />
          </div>
        </div>
      )}
      <OptionSideEffects
        optionsCoord={optionsCoord}
        optionValue={optionValue}
        optionCoordChained={optionCoord}
        openingId={openingId}
        optionId={optionId}
      />
      <OptionsSideEffects
        optionsCoord={optionsCoord}
        optionCoordChained={optionCoord}
        optionValue={optionValue}
        openingId={openingId}
        optionId={optionId}
      />
    </>
  );
};

export default memo(OpeningOption);
